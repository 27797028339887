import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Dropdown, Menu, Space, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import AuthContext from "../../Services/Auth";

const { Text } = Typography;

function UserHeader() {
  let navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const menu = (
    <Menu
      items={[
        {
          label: (
            <Link to="/profile">
              <Text rel="noopener noreferrer">Profile</Text>
            </Link>
          ),
          key: "0",
        },
        {
          label: <Text rel="noopener noreferrer">Logout</Text>,
          onClick: () => handleLogout(),
          key: "1",
        },
      ]}
    />
  );

  return (
    <Dropdown overlay={menu}>
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <Text>{`${localStorage.getItem("name")} | ${localStorage.getItem(
            "role"
          )}`}</Text>
          <Avatar size="large" icon={<UserOutlined />} />
        </Space>
      </a>
    </Dropdown>
  );
}

export default UserHeader;
