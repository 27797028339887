import React, { useContext, useEffect, useState } from "react";
import { Layout, Menu, Image, PageHeader, Divider } from "antd";
import styles from "./AdminLayout.module.css";
import Logo from "../../assets/images/logo.png";
import menu from "../../Config/Menu";
import UserHeader from "../../Components/Layout/UserHeader";
import NotificationHeader from "../../Components/Layout/NotificationHeader";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../Services/Auth";
import MasterContext from "../../Services/Master";

const { Header, Content, Footer, Sider } = Layout;

function AdminLayout(props) {
  const { role } = useContext(AuthContext);
  const { getAllMaster } = useContext(MasterContext);
  const location = useLocation();
  let navigate = useNavigate();
  const [title, setTitle] = useState(menu[0]);
  const [key, setKey] = useState(-1);
  const [subKey, setSubKey] = useState([]);
  const [menuUsed, setMenuUsed] = useState(menu);
  const [disabledMenu, setDisabledMenu] = useState(false);
  const [checkSo, setCheckSo] = useState(false);

  const onSelectMenu = (item) => {
    if (item.keyPath.length > 1) {
      let subMenu = menu[item.keyPath[1]].children.find(
        (key) => key.key === item.keyPath[0]
      );
      navigate(menu[item.keyPath[1]]?.group + subMenu?.path);
    } else {
      navigate(menu[item.key]?.path);
    }
  };

  const checkSO = async () => {
    const newData = await getAllMaster("stock-opname/check");
    if (newData) {
      const path = location.pathname.split("/")[1];
      if (
        newData?.data?.is_so_active &&
        localStorage.getItem("role_id") !== "SUPERADMIN" &&
        path !== "stock-opname"
      ) {
        navigate("/stock-opname/stock-opname");
      } else {
        setCheckSo(true);
      }
      setDisabledMenu(newData.data?.is_so_active);
    }
  };

  useEffect(() => {
    checkSO();
    const path = location.pathname.split("/")[1];
    let index = 2;
    let subIndex = "";
    let subMenu = false;
    const newMenu = menu.map((item) => {
      if (item.children) {
        const subPath = location.pathname.split("/")[2];
        item.children.map((subItem) => {
          if (subItem.path === "/" + subPath) {
            index = subItem.key;
            subIndex = item.key;
            subMenu = true;
          }
        });
      } else {
        if ("/" + path === item.path) {
          index = item.key;
        }
      }
      if (item?.access) {
        item.hidden = !item.access?.includes(
          localStorage.getItem("role_id") || role
        );
      }
      if (item?.expect) {
        item.hidden = item.expect?.includes(localStorage.getItem("role_id"));
      }

      if (
        disabledMenu &&
        item?.key !== 8 &&
        localStorage.getItem("role_id") !== "SUPERADMIN"
      ) {
        item.disabled = true;
      } else {
        item.disabled = false;
      }
      return item;
    });

    setMenuUsed(newMenu);

    setKey(index);
    if (subMenu) {
      setTitle(menu[subIndex].children.find((key) => key.key === index));
      setSubKey([subIndex]);
    } else {
      setTitle(menu[index]);
    }
  }, [location.pathname, disabledMenu]);

  return (
    <Layout className={styles.layoutResponsive} hasSider>
      <Sider
        width={"250px"}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
        }}
        onCollapse={(collapsed, type) => {
        }}
        className={styles.siderLayout}
      >
        <div className={styles.logo}>
          <Image preview={false} src={Logo} className={styles.image} />
        </div>
        <Menu
          theme="light"
          mode="inline"
          className={styles.menu}
          defaultSelectedKeys={disabledMenu ? 8 : 1}
          selectedKeys={key.toString()}
          onClick={(item) => onSelectMenu(item)}
          openKeys={[subKey.toString()]}
          onOpenChange={(item) => {
            let newKey = item.filter((n) => n);
            if (newKey.length > 1) {
              newKey.splice(0, 1);
            }
            setSubKey(newKey);
          }}
          items={menuUsed}
        />
      </Sider>
      <Layout className={styles.siteLayout}>
        <Header
          className={styles.header}
          style={{
            padding: 0,
          }}
        >
          <PageHeader
            className={styles.headerContent}
            title={
              location.pathname.split("/")[1] === "profile"
                ? "Profile"
                : title.label
            }
            extra={[
              <NotificationHeader key="notification"></NotificationHeader>,
              <Divider key="devider" type="vertical" />,
              <UserHeader key="user" />,
            ]}
          />
        </Header>
        {checkSo && (
          <Content className={styles.contentContainer}>
            {props.children}
          </Content>
        )}
        <Footer className={styles.footer}></Footer>
      </Layout>
    </Layout>
  );
}

export default AdminLayout;
