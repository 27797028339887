import {
  GET_ALL_ASSET,
  GET_DETAIL_ASSET,
  GET_STOCK_OPNAME,
  GET_LIST_STOCK_OPNAME,
  GET_DETAIL_STOCK_OPNAME,
  GET_ALL_ASSET_MOVEMENT,
} from "./types";

const reducer = (state, { type, payload }) => {

  switch (type) {
    case GET_ALL_ASSET:
      return {
        ...state,
        loading: false,
        asset: payload,
      };
    case GET_DETAIL_ASSET:
      return {
        ...state,
        loading: false,
        detail: payload,
      };
    case GET_STOCK_OPNAME:
      return {
        ...state,
        loading: false,
        detail: payload,
      };
    case GET_DETAIL_STOCK_OPNAME:
      return {
        ...state,
        loading: false,
        detail: payload,
      };
    case GET_LIST_STOCK_OPNAME:
      return {
        ...state,
        loading: false,
        detail: payload,
      };
    case GET_ALL_ASSET_MOVEMENT:
      return {
        ...state,
        loading: false,
        detail: payload,
      };
    default:
      return {
        ...state,
        loading: false,
      };
  }
};

export default reducer;
