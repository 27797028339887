import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Space,
  Select,
  Input,
} from "antd";
import styles from "../AssetMovement.module.css";
import TypeBorrowingReceiving from "./typeBorrowingReceiving";
import MasterContext from "../../../Services/Master";
import AssetContext from "../../../Services/Asset";
import TableMovement from "./table";
import moment from "moment";

function SecondStep(props) {
  const { getAllMaster } = useContext(MasterContext);
  const { getAllDaftarAsset } = useContext(AssetContext);
  const [dataUser, setDataUser] = useState([]);
  const [dataLocation, setDataLocation] = useState([]);
  const [dataAsset, setDataAsset] = useState([]);

  const getUsers = async () => {
    const newData = await getAllMaster("users", {
      position_id: "3",
      exclude_self: true,
    });
    if (newData) {
      const newDataUser =
        newData.data &&
        newData.data.map((item) => ({
          value: item?.id,
          label: item?.unit?.name,
        }));
      setDataUser(newDataUser);
    }
  };

  const getLocation = async () => {
    const newData = await getAllMaster("rooms");
    if (newData) {
      const newDataLocation =
        newData.data &&
        newData.data.map((item) => ({
          value: item?.id,
          label:
            item?.name +
            " " +
            item?.floor?.name +
            " " +
            item?.floor?.building?.name,
        }));
      setDataLocation(newDataLocation);
    }
  };

  const getAsset = async () => {
    const newData = await getAllDaftarAsset({
      status: "ACTIVE,NON_ACTIVE,IDLE",
      include_borrowed: false,
      unit_id: localStorage.unit_id,
      only_available: true,
      // cross_approval: true,
    });
    if (newData) {
      const newDataAsset =
        newData.data &&
        newData.data.map((item) => {
          return {
            value: item.id,
            label: item.name + " - " + item.serialNumber,
          };
        });
      setDataAsset(newDataAsset);
    }
  };

  useEffect(() => {
    getUsers();
    getLocation();
    getAsset();
  }, [props.type]);

  return (
    <div className={styles.secondStep}>
      <Row gutter={20}>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Form.Item
            name="transaction_date"
            label="Transaction Date"
            rules={[
              {
                required: true,
                message: "Please select transaction date!",
              },
            ]}
            initialValue={moment().format("YYYY-MM-DD")}
          >
            <Input readOnly placeholder="Transaction Date" />
          </Form.Item>
        </Col>

        {props.type?.subType === "USER" ? (
          <Col xs={24} sm={24} md={8} lg={8}>
            <Form.Item
              name="deliveredTo"
              label="Delivered To"
              rules={[
                {
                  required: true,
                  message: "Please select delivered to!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select delivered to"
                options={dataUser}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </Form.Item>
          </Col>
        ) : (
          ""
        )}

        {props.type?.type === "BORROWING" ? (
          <TypeBorrowingReceiving
            dataUser={dataUser}
            dataLocation={dataLocation}
          />
        ) : (
          ""
        )}
        <Col xs={24} sm={24} md={24} lg={24}>
          <div style={{ overflowX: "auto", maxWidth: "100%" }}>
            <TableMovement
              dataSource={props.dataSource}
              setDataSource={props.setDataSource}
              dataAsset={dataAsset}
              dataLocation={dataLocation}
              type={props.type}
            />
          </div>
        </Col>
        <Divider />
        <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "right" }}>
          <Space>
            <Button type="secondary" onClick={() => props.prev()}>
              Previous
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
}

export default SecondStep;
