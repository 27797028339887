import React, { useState } from "react";
import { Button, Space } from "antd";
import { Link } from "react-router-dom";
import styles from "../Global.module.css";
import ModalApprove from "../Action/ModalApprove";
import ModalReject from "../Action/ModalReject";
import ModalDelete from "../Action/ModalDelete";
import ModalCancel from "../Action/ModalCancel";

const ApprovalList = (props) => {
  const [openApprove, setOpenApprove] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  return (
    <>
      {props.data.approvalRole === localStorage.getItem("role_id") &&
      ((props.type === "registered-asset" &&
        props.data.approvalStatus === "WAITING_APPROVAL" &&
        props.data.status === "WAITING_APPROVAL") ||
        ((props.type === "daftar-asset" ||
          props.type === "daftar-asset-perusahaan") &&
          props.data.approvalStatus === "WAITING_APPROVAL" &&
          props.data.status !== "WAITING_APPROVAL")) ? (
        <Space>
          <div className={styles.buttonCardSuccess}>
            <Button
              size="large"
              type="default"
              className={styles.buttonSuccess}
              onClick={() => setOpenApprove(true)}
            >
              Approve
            </Button>
          </div>
          <div className={styles.buttonCardDanger}>
            <Button
              size="large"
              type="default"
              className={styles.buttonDanger}
              onClick={() => setOpenReject(true)}
            >
              Reject
            </Button>
          </div>
        </Space>
      ) : (
        String(props.data.originUser?.id) === localStorage.getItem("user_id") &&
        props.data.approvalStatus === "REJECTED" &&
        ((props.type === "daftar-asset" &&
          props.data.status !== "WAITING_APPROVAL") ||
          (props.type === "registered-asset" &&
            props.data.status === "WAITING_APPROVAL")) && (
          <Link to={`/${props.type}/resubmit/${props.data.id}`}>
            <div className={styles.buttonCardWarning}>
              <Button
                size="large"
                type="default"
                className={styles.buttonWarning}
              >
                Resubmit
              </Button>
            </div>
          </Link>
        )
      )}
      {localStorage.getItem("position_id") === "3" && String(props.data.created_by?.unitId) ===
        localStorage.getItem("unit_id") &&
        props.data.approvalStatus === "REJECTED" &&
        props.data.status === "WAITING_APPROVAL" && (
          <div className={styles.buttonCardDanger}>
            <Button
              size="large"
              type="default"
              className={styles.buttonDanger}
              onClick={() => setOpenDelete(true)}
            >
              Delete
            </Button>
          </div>
        )}
      {String(props.data.originUser?.id) === localStorage.getItem("user_id") &&
        props.data.approvalStatus === "REJECTED" &&
        props.type === "daftar-asset" &&
        props.data.status !== "WAITING_APPROVAL" && (
          <div className={styles.buttonCardDanger}>
            <Button
              size="large"
              type="default"
              className={styles.buttonDanger}
              onClick={() => setOpenCancel(true)}
            >
              Cancel
            </Button>
          </div>
        )}
      <ModalApprove
        id={props.data.id}
        open={openApprove}
        setOpen={setOpenApprove}
        getData={props.getData}
        action={props.action}
        type="Asset"
      />
      <ModalDelete
        id={props.data.id}
        open={openDelete}
        setOpen={setOpenDelete}
        getData={props.getData}
        action={props.actionDelete}
        type="Asset"
      />
      <ModalCancel
        id={props.data.id}
        open={openCancel}
        setOpen={setOpenCancel}
        getData={props.getData}
        action={props.actionCancel}
        type="Asset"
      />
      <ModalReject
        id={props.data.id}
        open={openReject}
        setOpen={setOpenReject}
        getData={props.getData}
        action={props.action}
        type="Asset"
      />
    </>
  );
};

export default ApprovalList;
