import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ allowedRoles }) => {
  const roleId = localStorage.getItem("role_id");

  if (!roleId) {
    return <Navigate to="/login" />;
  }

  if (!allowedRoles.includes(roleId)) {
    return <Navigate to="/forbidden" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
