import { Col, Row } from "antd";
import React, { } from "react";
import styles from './AuthLayout.module.css';

function AuthLayout(props) {
  return (
    <>
      <Row className={styles.authWrapper}>
        <Col xs={2} sm={4} md={10} lg={15} className={styles.leftLayout}></Col>
        <Col xs={20} sm={20} md={14} lg={9} className={styles.rightLayout}>
          {props.children}
        </Col>
      </Row>
    </>
  )
}

export default AuthLayout;