import React, { useEffect, useState } from "react";
import { Form, Modal, notification, Typography } from "antd";
import moment from "moment";
import UpdateBastDate from "../Form/UpdateBastDate";

const { Text } = Typography;

function ModalUpdateBastDate(props) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const submit = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      if (values) {
        const res = await props.action(props?.data?.id, values);
        if (res?.error === false || !res?.error) {
          notification.success({
            message: "Success",
            description: "Input BAST Date Success",
          });
          setLoading(false);
          props.getData();
          setOpen(false);
        }
      }
    } catch (errInfo) {
      setLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      bast_date: props?.data?.bast_date ? moment(props?.data?.bast_date) : "",
    });
  }, []);

  return (
    <>
      <Text onClick={() => setOpen(true)} style={{ display: "block" }}>
        Input Tanggal BAST
      </Text>
      <Modal
        title={"Input Tanggal BAST"}
        open={open}
        onOk={submit}
        confirmLoading={loading}
        onCancel={() => {
          setLoading(false);
          setOpen(false);
        }}
        okText={"Input"}
      >
        <UpdateBastDate id={props.id} form={form} />
      </Modal>
    </>
  );
}

export default ModalUpdateBastDate;
