import { useState } from "react";
import AssetTable from "../../Components/Global/AssetTable";
import AdminLayout from "../../Layouts/Admin/AdminLayout";

function DaftarAssetPerusahaan(props) {
  const [params, setParams] = useState({
    status: "ACTIVE,DISPOSE,NON_ACTIVE,IDLE,TIDAK_DITEMUKAN",
    daftar_asset_perusahaan: true,
    asset_from_another_unit: true,
  });

  return (
    <AdminLayout>
      <AssetTable type={"daftar-asset-perusahaan"} params={params} setParams={setParams} />
    </AdminLayout>
  );
}

export default DaftarAssetPerusahaan;
