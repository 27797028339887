import { Card } from "antd";

const {Meta} = Card;

function CardDashboard(params) {
  return (
    <Card>
      <Meta title={params.title} description={params.total} />
    </Card>
  );
}

export default CardDashboard;
