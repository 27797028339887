import { Card, Col, Row, Table, Tag } from "antd";
import styles from "../Global.module.css";
import StatusApprovalList from "../View/StatusApprovalList";
import moment from "moment";

const CardRiwayatApproval = (props) => {
  const columnsHistory = [
    {
      title: "Tanggal",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 250,
      render: (data) => {
        return data && moment(data).format("LL HH:mm");
      },
    },
    {
      title: "Urutan",
      dataIndex: "sequenceNumber",
      key: "sequenceNumber",
      sorter: (a, b) => a.sequenceNumber - b.sequenceNumber,
      defaultSortOrder: "ascend",
    },
    {
      title: "Role",
      dataIndex: "role_id",
      key: "role_id",
      width: 400,
    },
    {
      title: "Approval Status",
      dataIndex: "approval_st",
      key: "approval_st",
      width: 250,
      render: (data) => (
        <Tag
          style={{
            borderRadius: "20px",
            minWidth: "120px",
            textAlign: "center",
          }}
          color={StatusApprovalList(data)}
        >
          {data}
        </Tag>
      ),
    },
    {
      title: "Catatan",
      dataIndex: "description",
      key: "notes",
      width: 250,
    },
  ];

  return (
    <Card title={"Riwayat Approval"} className={styles.cardTable}>
      <Row gutter={40}>
        <Col className={styles.detailRow} span={24}>
          <Table
            columns={columnsHistory}
            // dataSource={
            //   props?.data?.approvals?.length > 0 &&
            //   props?.data?.approvals[props?.data?.approvals?.length - 1]
            //     ?.asset_approval_details
            // }
            // dataSource = {
            //   props.path === "registered-asset"
            //     ? (
            //         props?.data?.approvals?.find((approval) => approval.type === "REGISTRATION")
            //           ?.asset_approval_details
            //       )
            //     : (
            //         props?.data?.approvals?.length > 0
            //           ? (
            //               props?.data?.approvals[props?.data?.approvals.length - 1]
            //                 ?.asset_approval_details
            //             )
            //           : undefined
            //       )
            // }
            dataSource = {
              props.path === "registered-asset"
                ? (
                    props?.data?.approvals
                      ?.filter((approval) => ["REGISTRATION", "RESUBMIT"].includes(approval.type))
                      ?.slice(-1)[0] // Mengambil elemen terakhir
                      ?.asset_approval_details
                  )
                : (
                    props?.data?.approvals?.length > 0
                      ? (
                          props?.data?.approvals[props?.data?.approvals.length - 1]
                            ?.asset_approval_details
                        )
                      : undefined
                  )}
            rowKey={"id"}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default CardRiwayatApproval;
