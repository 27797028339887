import { Card, Col, Dropdown, Menu, Row, Space, Table, Tag } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import styles from "../Global.module.css";
import StatusApprovalList from "../View/StatusApprovalList";
import moment from "moment";
import { Link } from "react-router-dom";
import { render } from "@testing-library/react";

const CardRiwayatRegistration = (props) => {
  const dataSource = props.data.assetHistory
    ? props.data.assetHistory.map((historyItem) => {
        if (
          historyItem.type === "FULLFILL_DATA" ||
          historyItem.type === "CANCEL_UPDATE_DATA"
        ) {
          if (historyItem?.referenceDataSnapshot) {
            return {
              ...historyItem,
            };
          } else {
            return {
              ...historyItem,
              referenceDataSnapshot: "",
            };
          }
        }
      })
    : null;

  const filteredDatasNull = () => {
    if (dataSource) {
      return dataSource.filter((item) => {
        if (item) {
          return item;
        }
      });
    }
  };
  const renderData = [
    ...(props.data.approvals || []),
    ...(filteredDatasNull() || []),
  ];

  const columns = [
    {
      title: "Tanggal",
      key: "createdAt",
      width: 200,
      render: (data) => {
        if (data?.createdAt) {
          if (
            data?.type === "FULLFILL_DATA" ||
            data?.type === "CANCEL_UPDATE_DATA"
          ) {
            return moment(data?.createdAt).format("LL HH:mm");
          } else {
            return (
              <Link
                to={
                  `/${
                    window.location.pathname.includes("registered-asset")
                      ? "registered-asset"
                      : "daftar-asset"
                  }/detail/approval/` +
                  props.data.id +
                  "/" +
                  data.id
                }
              >
                {moment(data?.createdAt).format("LL HH:mm")}
              </Link>
            );
          }
        }
      },
    },
    {
      title: "Deskripsi",
      width: 400,
      render: (data) => {
        const Basedata = data?.description
          ? data?.description
          : data?.referenceDataSnapshot;

        const isTimestamp = (value) => {
          return (
            typeof value === "number" &&
            new Date(value).getTime() > 0 && // Valid timestamp
            value.toString().length >= 10 // Timestamp length (10 or 13 digits)
          );
        };

        const formatTimestampToDate = (timestamp) => {
          const date = new Date(timestamp);
          return date.toLocaleDateString(); // Format hanya tanggal
        };

        const renderData = (dataObject) => {
          console.log(dataObject);

          const filteredData = Object.keys(dataObject)
            .filter(
              (key) =>
                dataObject[key] !== null &&
                key.toLowerCase() !== "floorname" &&
                key.toLowerCase() !== "floorid" &&
                key.toLowerCase() !== "roomid" &&
                key.toLowerCase() !== "buildingid" &&
                key.toLowerCase() !== "buildingname"
            )
            .sort();

          return (
            <div>
              <table>
                <tbody>
                  {filteredData.map((item, idx) => (
                    <tr key={idx}>
                      <td>{keyToTitle(item)}</td>
                      <td>
                        {": " +
                          (isTimestamp(dataObject[item])
                            ? formatTimestampToDate(dataObject[item])
                            : dataObject[item])}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          );
        };

        if (typeof Basedata === "object") {
          return renderData(Basedata);
        } else if (typeof Basedata === "string") {
          try {
            const parsedData = JSON.parse(Basedata);
            return renderData(parsedData);
          } catch (e) {
            console.error("Invalid JSON string:", data);
            return null;
          }
        }
        return null;
      },
    },
    // {
    //   title: "Deskripsi",
    //   // dataIndex: "referenceDataSnapshot",
    //   // key: "referenceDataSnapshot",
    //   width: 400,
    //   render: (data) => {
    //     const Basedata = data?.description
    //       ? data?.description
    //       : data?.referenceDataSnapshot;

    //     if (typeof Basedata === "object") {
    //       const filteredData = Object.keys(Basedata)
    //         .filter((key) => Basedata[key] !== null)
    //         .sort();
    //       return (
    //         <div>
    //           <table>
    //             <tbody>
    //               {filteredData.map((item, idx) => (
    //                 <tr key={idx}>
    //                   <td>{keyToTitle(item)}</td>
    //                   <td>{": " + Basedata[item]}</td>
    //                 </tr>
    //               ))}
    //             </tbody>
    //           </table>
    //         </div>
    //       );
    //     } else if (typeof Basedata === "string") {
    //       try {
    //         const parsedData = JSON.parse(Basedata);

    //         const filteredData = Object.keys(parsedData)
    //           .filter((key) => parsedData[key] !== null)
    //           .sort();

    //         return (
    //           <div>
    //             <table>
    //               <tbody>
    //                 {filteredData.map((item, idx) => (
    //                   <tr key={idx}>
    //                     <td>{keyToTitle(item)}</td>
    //                     <td>{": " + parsedData[item]}</td>
    //                   </tr>
    //                 ))}
    //               </tbody>
    //             </table>
    //           </div>
    //         );
    //       } catch (e) {
    //         console.error("Invalid JSON string:", data);
    //         return null;
    //       }
    //     }
    //     return null;
    //   },
    // },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 150,
      render: (data) => {
        if (data === "RESUBMIT") {
          return "REGISTRATION - RESUBMIT";
        } else if (data === "FULLFILL_DATA") {
          return "LENGKAPI DATA";
        } else if (data === "MUTATION") {
          return "MUTATION";
        } else if (data === "MUTATION_LOCATION") {
          return "MUTATION LOCATION";
        } else if (data === "BORROWING_RENEWAL") {
          return "BORROWING RENEWAL";
        } else if (data === "UPDATE_ASSET") {
          return "UBAH DATA";
        } else if (data === "BORROWING") {
          return "BORROWING";
        } else if (data === "REGISTERED") {
          return "REGISTERED";
        } else if (data === "CANCEL_UPDATE_DATA") {
          return "CANCEL UBAH DATA";
        } else {
          return data;
        }
      },
    },
    {
      title: "Status",
      width: 150,
      render: (data) => {
        if (
          data?.type === "FULLFILL_DATA" ||
          data?.type === "CANCEL_UPDATE_DATA"
        ) {
          return (
            <Tag
              style={{
                borderRadius: "20px",
                minWidth: "120px",
                textAlign: "center",
              }}
              color={StatusApprovalList("APPROVE")}
            >
              Done
            </Tag>
          );
        } else {
          return (
            <Tag
              style={{
                borderRadius: "20px",
                minWidth: "120px",
                textAlign: "center",
              }}
              color={StatusApprovalList(
                !data?.is_done_flag && !data?.is_rejected_flag
                  ? "WAITING_APPROVAL"
                  : data?.is_done_flag && !data?.is_rejected_flag
                  ? "APPROVE"
                  : "REJECTED"
              )}
            >
              {!data?.is_done_flag && !data?.is_rejected_flag
                ? "Proses"
                : data?.is_done_flag && !data?.is_rejected_flag
                ? "Full Approve"
                : "Reject"}
            </Tag>
          );
        }
      },
    },
    {
      title: "Aksi",
      key: "aksi",
      width: 10,
      render: (data) => actionList(data),
    },
  ];

  const actionList = (data) => {
    return (
      <Dropdown overlay={menuAction2(data)} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <EllipsisOutlined rotate={90} />
        </a>
      </Dropdown>
    );
  };

  const menuAction2 = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link
              to={
                `/${
                  window.location.pathname.includes("registered-asset")
                    ? "registered-asset"
                    : "daftar-asset"
                }/detail/approval/` +
                props.data.id +
                "/" +
                data.id
              }
            >
              Lihat Detail
            </Link>
          ),
          key: "1",
        },
      ]}
    />
  );
  return (
    <Card title={"Riwayat Asset"} className={styles.cardTable}>
      <Row gutter={40}>
        <Col className={styles.detailRow} span={24}>
          <Table columns={columns} dataSource={renderData} rowKey={"id"} />
        </Col>
      </Row>
    </Card>
  );
};

const keyToTitle = (key) => {
  var x = key.replace(/([A-Z])/g, " $1");
  return x.charAt(0).toUpperCase() + x.slice(1);
};

export default CardRiwayatRegistration;
