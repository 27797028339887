import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  notification,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Steps,
  Table,
  Typography,
} from "antd";
import React, { useState, useEffect, useContext } from "react";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "./AssetMovement.module.css";
import FirstStep from "./component/firstStep";
import SecondStep from "./component/secondStep";
import AssetContext from "../../Services/Asset";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;
const { Option } = Select;
const { Step } = Steps;

function AddAssetMovement(props) {  
  let { type, idSOAsset } = useParams();
  const { addAssetMovement } = useContext(AssetContext);
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [dataInput, setDataInput] = useState("");
  const [dataSource, setDataSource] = useState([]);
  let navigate = useNavigate();

  const next = async (type, subType) => {
    setDataInput({ type: type, subType: subType });
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
    setDataInput({ type: "", subType: "" });
  };

  const onFinish = async (values) => {
    try {
      if (dataSource.length > 0) {
        var seen = {};
        var hasDuplicates = dataSource.some(function (currentObject) {
          return (
            seen.hasOwnProperty(currentObject.asset_id) ||
            (seen[currentObject.asset_id] = false)
          );
        });

        if (hasDuplicates) {
          notification.error({
            message: "Error",
            description: "Data Asset Tidak Boleh Duplikat",
          });
          throw new Error("Something went badly wrong!");
        }

        const newDataSource = dataSource.map((item) => {
          return {
            asset_id: item.asset_id,
            location_u: item.location_u,
            rack_id: item.rack_id,
            room_id: item.room_id,
            room_from_id: item.room_from_id,
            description: item.remark,
            old_asset_plan: item.old_asset_plan,
            new_asset_id: item.new_asset_id,
            new_asset_location_u: item.new_location_u,
            new_asset_rack_id: item.new_rack_id,
            new_asset_room_id: item.new_room_id,
          };
        });
        const sendedData = {
          type: dataInput.type,
          transaction_date: values.transaction_date && values.transaction_date,
          return_date:
            (values.return_date && values.return_date.format("YYYY-MM-DD")) ||
            "",
          delivered_type: dataInput.subType || "USER",
          delivered_to: values.deliveredTo,
          asset_movement_details: newDataSource,
          stock_opname_asset_id: idSOAsset || null,
        };
        const res = await addAssetMovement(sendedData);
        if (res?.error === false || !res?.error) {
          notification.success({
            message: "Success",
            description: "Add Asset Movement Success",
          });
          navigate("/asset-movement");
        }
      } else {
        notification.error({
          message: "Error",
          description: "Data Asset is required",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const steps = [
    {
      title: "",
      content: <FirstStep next={next} />,
    },
    {
      title: "",
      content: (
        <SecondStep
          dataSource={dataSource}
          setDataSource={setDataSource}
          prev={prev}
          type={dataInput}
        />
      ),
    },
  ];

  useEffect(() => {
    if (type) {
      let newType = null;
      let newSubType = null;
      if (type === "borrowing") {
        newType = "BORROWING";
      } else if (type === "mutation-user") {
        newType = "MUTATION";
        newSubType = "USER";
      } else if (type === "mutation-location") {
        newType = "MUTATION";
        newSubType = "LOCATION";
      } else if (type === "replace") {
        newType = "REPLACE";
        newSubType = "";
      }

      if (newType) {
        next(newType, newSubType);
      }
    }
  }, [type]);

  return (
    <AdminLayout>
      <Card 
        className={styles.cardTable}
        title={
          `Asset Movement Form ${(dataInput?.type && "-") || ""} ${dataInput?.type || ""} ${
            dataInput?.subType || ""
          }`
        }
        extra={
          <Space>
            <Button
              icon={<ArrowLeftOutlined />}
              type="primary"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </Space>
        }
      >
      </Card>
      <Form
        form={form}
        layout="vertical"
        name="control-hooks"
        autoComplete="off"
        onFinish={onFinish}
      >
        <Card className={styles.cardBottom}>
          <div className={styles.stepGroup}>
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </div>
          <div className={styles.stepContent}>{steps[current].content}</div>
        </Card>
      </Form>
    </AdminLayout>
  );
}

export default AddAssetMovement;
