import { Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";
import { EllipsisOutlined } from "@ant-design/icons";
import AssetContext from "../../../Services/Asset";
import { useContext } from "react";
import ModalUpdateStatus from "../Action/ModalUpdateStatus";
import ModalUpdateBastDate from "../Action/ModalUpdateBastDate";
import ModalUploadDokumen from "../Action/ModalUploadDokumen";
import ModalLengkapiData from "../Action/ModalLengkapiData";
import ModalTakeout from "../Action/ModalTakeout";

const ActionAssetList = (props) => {
  const {
    updateStatusAction,
    updateBastDateAction,
    uploadDokumenAction,
    upload,
    completeDataAction,
    cetakAssetAction,
    deleteAsset,
  } = useContext(AssetContext);

  const cetakAsset = async (id) => {
    await cetakAssetAction(id);
  };

  const propsTypeRegisterAsset = "registered-asset";
  const propsTypeDaftarAsset = "daftar-asset";
  const propsTypeDaftarAssetPerusahaan = "daftar-asset-perusahaan";

  const menuAction = (data) => {
    let items = [
      {
        label: (
          <Link to={`/${props.type}/detail/${data.id}`}>Lihat Detail</Link>
        ),
        key: "1",
      },
    ];
    const status = {
      label: (
        <ModalUpdateStatus
          data={data}
          type={props.type}
          getData={props.getData}
          action={updateStatusAction}
        />
      ),
      key: "2",
    };
    const bastDate = {
      label: (
        <ModalUpdateBastDate
          data={data}
          type={props.type}
          getData={props.getData}
          action={updateBastDateAction}
        />
      ),
      key: "3",
    };
    const dokumen = {
      label: (
        <ModalUploadDokumen
          data={data}
          type={props.type}
          getData={props.getData}
          action={uploadDokumenAction}
          upload={upload}
        />
      ),
      key: "4",
    };
    const lengkapi = {
      label: (
        <ModalLengkapiData
          data={data}
          type={props.type}
          getData={props.getData}
          action={completeDataAction}
        />
      ),
      key: "5",
    };
    const update = {
      label: <Link to={`/${props.type}/update/` + data.id}>Ubah Asset</Link>,
      key: "6",
    };

    const cetak = {
      label: <div onClick={() => cetakAsset(data.id)}>Cetak</div>,
      key: "7",
    };

    const takekout = {
      label: (
        <ModalTakeout
          data={data}
          getData={props.getData}
          action={deleteAsset}
        />
      ),
      key: "2",
    };

    // items.push(status, bastDate, dokumen, lengkapi, update, cetak);
    const unitId = localStorage.getItem("downstream_unit_id").split(",");
    const unitGA = [
      "ADMIN_GA",
      "VP_GA",
      "HEAD_GA",
      "OFFICE_VENDOR_MANAGEMENT",
    ].includes(localStorage.getItem("role_id")); //array
    // const unitRelated = unitId.includes(String(data?.originUser?.unitId));
    const unitRelated = unitId.includes(String(data?.originUser?.unitId));
    const superAdmin = "SUPERADMIN" === localStorage.getItem("role_id");
    const adminGA = "ADMIN_GA" === localStorage.getItem("role_id");
    const approvalFirst =
      data?.approvals &&
      data?.approvals[data?.approvals.length - 1]?.asset_approval_details;
    const checkApprovalFirst =
      approvalFirst?.length > 0 &&
      (approvalFirst[0].approval_st === "WAITING_APPROVAL" ? true : false);

    const newLengkapi = () => {
      if (
        props.type === propsTypeDaftarAsset ||
        props.type === propsTypeDaftarAssetPerusahaan ||
        props.data.approvalStatus !== "WAITING_APPROVAL"
      ) {
        if (
          data.vendor_name == null ||
          (data?.room?.has_rack
            ? data.rack_id == null ||
              data.location_u == null ||
              data.rack_id == 0 ||
              data.location_u == 0
            : false) ||
          data.insurance == null ||
          data.insurance_period_start == null ||
          data.insurance_period_end == null
        ) {
          items.push(lengkapi);
        }
      }
    };

    const newCetak = () => {
      if (props.type === propsTypeRegisterAsset) {
        items.push(cetak);
      }
    };

    const newStatus = () => {
      if (
        props.type === propsTypeDaftarAsset ||
        props.type === propsTypeDaftarAssetPerusahaan
      ) {
        items.push(status);
      }
    };

    const newBastDate = () => {
      if (
        props.type === propsTypeDaftarAsset ||
        props.type === propsTypeDaftarAssetPerusahaan
      ) {
        items.push(bastDate);
      }
    };

    const newDokumen = () => {
      if (
        (props.type === propsTypeRegisterAsset &&
          props.data.approvalStatus !== "WAITING_APPROVAL") ||
        ([
          "VP_GA",
          "VP_DEPT",
          "VP_ACC_TAX",
          "VP_IT_SECURITY",
          "DEPT_ACC_TAX",
          "VP_ACC_TAX",
          "HEAD_DEPT",
        ].includes(localStorage.getItem("role_id")) &&
          props.data.approvalStatus === "WAITING_APPROVAL")
      ) {
        items.push(dokumen);
      } else if (props.type === propsTypeDaftarAsset) {
        if (props.data.approvalStatus !== "WAITING_APPROVAL") {
          items.push(dokumen);
        }
      }
    };

    const newTakeout = () => {
      if (props.type === propsTypeDaftarAsset) {
        items.push(takekout);
      }
    };
    const newUpdate = () => {
      if (
        props.type === propsTypeDaftarAsset ||
        props.data.approvalStatus !== "WAITING_APPROVAL"
      ) {
        items.push(update);
      }
    };
    const tipe1 = unitRelated || adminGA || superAdmin; //tipe1 (Unit terkait + Admin GA + Super Admin)
    const tipe2 = unitRelated || unitGA || superAdmin; //tipe2 (Unit terkait + Unit GA + Super Admin)
    const tipe3 = adminGA || superAdmin; //tipe3 (Super Admin + Admin GA)
    const tipe4 =
      unitRelated ||
      superAdmin ||
      ["VP_GA", "VP_DEPT", "VP_ACC_TAX", "VP_IT_SECURITY"].includes(
        localStorage.getItem("role_id")
      ); //tipe4 (Unit terkait + Super Admin)
    const tipe5 = (checkApprovalFirst && unitRelated) || superAdmin; //Unit terkait ( selama approval pertama belum approved)
    const tipe6 =
      superAdmin &&
      (props.type === propsTypeDaftarAssetPerusahaan ||
        props.type === propsTypeRegisterAsset);
    const tipe7 = [
      "VP_GA",
      "VP_DEPT",
      "VP_ACC_TAX",
      "VP_IT_SECURITY",
      "DEPT_ACC_TAX",
      "VP_ACC_TAX",
    ].includes(localStorage.getItem("role_id"));

    if (tipe2) {
      newCetak()
    }

    if (
      (data?.used_in_movement === null || data?.used_in_movement === false) &&
      !data.borrowing_movement_id
    ) {
      if (
        data?.status === "ACTIVE" ||
        data?.status === "NON_ACTIVE" ||
        data?.status === "IDLE"
      ) {
        if (data?.approvalStatus === "WAITING_APPROVAL") {
          if (tipe2) {
            newCetak();
          }
          if (tipe3) {
            newStatus();
            newStatus();
          }
          if (tipe4) {
            newDokumen();
          }
          if (superAdmin) {
            newLengkapi();
            newUpdate();
          }
          if (tipe6) {
            newTakeout();
          }
        } else if (data?.approvalStatus === "REJECTED") {
          if (tipe2) {
            newCetak();
          }
          if (tipe3) {
            newBastDate();
            newStatus();
          }
          if (superAdmin) {
            newUpdate();
          }
          if (tipe6) {
            newTakeout();
          }
        } else if (
          data?.approvalStatus === "APPROVED" ||
          data?.approvalStatus === "APPROVE"
        ) {
          if (tipe2) {
            newCetak();
          }
          if (tipe3) {
            newBastDate();
            newStatus();
          }
          if (tipe4) {
            if (
              ![
                "VP_GA",
                "HEAD_GA",
                "OFFICE_VENDOR_MANAGEMENT",
                "HEAD_DEPT",
                "VP_DEPT",
                "VP_ACC_TAX",
                "VP_IT_SECURITY",
                "DEPT_ACC_TAX",
                "HEAD_IT_SECURITY",
              ].includes(localStorage.getItem("role_id"))
            ) {
              newUpdate();
            }
            newDokumen();
            if (
              ![
                "VP_GA",
                "HEAD_GA",
                "OFFICE_VENDOR_MANAGEMENT",
                "HEAD_DEPT",
                "VP_DEPT",
                "VP_ACC_TAX",
                "VP_IT_SECURITY",
                "DEPT_ACC_TAX",
                "HEAD_IT_SECURITY",
              ].includes(localStorage.getItem("role_id"))
            ) {
              newLengkapi();
            }
          }
          if (tipe6) {
            newTakeout();
          }
        }
      } else if (data?.status === "DISPOSE") {
        if (data?.approvalStatus === "WAITING_APPROVAL") {
          if (tipe1) {
            newCetak();
          }
          if (tipe3) {
            newBastDate();
          }
          if (tipe4) {
            newDokumen();
          }
          if (superAdmin) {
            newUpdate();
            newStatus();
            newLengkapi();
          }
          if (tipe6) {
            newTakeout();
          }
        } else if (data?.approvalStatus === "REJECTED") {
          if (tipe2) {
            newCetak();
          }
          if (tipe3) {
            newBastDate();
          }
          if (tipe4) {
            newDokumen();
          }
          if (superAdmin) {
            newUpdate();
            newStatus();
            // newLengkapi();
          }
          if (tipe6) {
            newTakeout();
          }
        } else if (
          data?.approvalStatus === "APPROVED" ||
          data?.approvalStatus === "APPROVE"
        ) {
          if (tipe2) {
            newCetak();
          }
          if (tipe3) {
            newBastDate();
          }
          if (tipe4) {
            newDokumen();
          }
          if (superAdmin) {
            newUpdate();
            newStatus();
            newLengkapi();
          }
          if (tipe6) {
            newTakeout();
          }
        }
      } else if (data?.status === "TIDAK_DITEMUKAN") {
        if (data?.approvalStatus === "WAITING_APPROVAL") {
          if (tipe2) {
            newCetak();
          }
          if (tipe3) {
            newBastDate();
            newUpdate();
          }
          if (superAdmin) {
            newStatus();
          }
          if (tipe4) {
            newDokumen();
          }
          if (tipe6) {
            newLengkapi();
            newTakeout();
          }
        } else if (data?.approvalStatus === "REJECTED") {
          if (tipe2) {
            newCetak();
          }
          if (tipe3) {
            newBastDate();
            newUpdate();
          }
          if (superAdmin) {
            newStatus();
          }
          // if (tipe4) {
          //   newDokumen();
          //   if (
          //     ![
          //       "VP_GA",
          //       "HEAD_GA",
          //       "OFFICE_VENDOR_MANAGEMENT",
          //       "HEAD_DEPT",
          //       "VP_DEPT",
          //       "VP_ACC_TAX",
          //       "VP_IT_SECURITY",
          //       "DEPT_ACC_TAX",
          //       "HEAD_IT_SECURITY",
          //     ].includes(localStorage.getItem("role_id"))
          //   ) {
          //     newLengkapi();
          //   }
          // }
          if (tipe6) {
            newTakeout();
          }
        } else if (
          data?.approvalStatus === "APPROVED" ||
          data?.approvalStatus === "APPROVE"
        ) {
          if (tipe2) {
            newCetak();
          }
          if (tipe3) {
            newBastDate();
            newUpdate();
          }
          if (superAdmin) {
            newStatus();
          }
          if (tipe4) {
            newDokumen();
            if (
              ![
                "VP_GA",
                "HEAD_GA",
                "OFFICE_VENDOR_MANAGEMENT",
                "HEAD_DEPT",
                "VP_DEPT",
                "VP_ACC_TAX",
                "VP_IT_SECURITY",
                "DEPT_ACC_TAX",
                "HEAD_IT_SECURITY",
              ].includes(localStorage.getItem("role_id"))
            ) {
              newLengkapi();
            }
          }
          if (tipe6) {
            newTakeout();
          }
        }
      } else if (data?.status === "WAITING_APPROVAL") {
        if (data?.approvalStatus === "WAITING_APPROVAL") {
          if (tipe7) {
            newDokumen();
            newCetak();
          }

          if (tipe4) {
            newDokumen();
          }

          if (tipe2) {
            newCetak();
          }
          // if (tipe5) {
          //   if (localStorage.getItem("role_id")!=="HEAD_DEPT" && localStorage.getItem("role_id")!=="VP_DEPT") {
          //     newUpdate()
          //   }
          // }
          if (superAdmin) {
            newUpdate();
          }
          if (tipe6) {
            newTakeout();
          }
        } else if (data?.approvalStatus === "REJECTED") {
          if (tipe4) {
            newDokumen();
          }
          if (tipe1) {
            newCetak();
          }
          if (tipe6) {
            newTakeout();
          }
        } else if (
          data?.approvalStatus === "APPROVED" ||
          data?.approvalStatus === "APPROVE"
        ) {
          if (tipe6) {
            newTakeout();
          }
        }
      } else if (data?.status === "REJECTED") {
        if (data?.approvalStatus === "WAITING_APPROVAL") {
          if (tipe4) {
            newDokumen();
          }
          if (tipe1) {
            newCetak();
          }
          // if (tipe5) {
          //   if (localStorage.getItem("role_id")!=="HEAD_DEPT" && localStorage.getItem("role_id")!=="VP_DEPT") {
          //     newUpdate()
          //   }
          // }
          if (superAdmin) {
            newUpdate();
          }
          if (tipe6) {
            newTakeout();
          }
        } else if (data?.approvalStatus === "REJECTED") {
          if (tipe4) {
            newDokumen();
          }
          if (tipe1) {
            newCetak();
          }
          if (tipe6) {
            newTakeout();
          }
          // } else if (
          //   data?.approvalStatus === "APPROVED" ||
          //   data?.approvalStatus === "APPROVE"
          // ) {
        }
      }
    } else {
      if (
        data?.status === "ACTIVE" ||
        data?.status === "NON_ACTIVE" ||
        data?.status === "IDLE"
      ) {
        if (data?.approvalStatus === "WAITING_APPROVAL") {
          if (tipe2) {
            newCetak();
          }
          if (tipe3) {
            newStatus();
            newBastDate();
          }
          if (tipe4) {
            newDokumen();
          }
          if (superAdmin) {
            newLengkapi();
            newUpdate();
          }
        } else if (data?.approvalStatus === "REJECTED") {
          if (tipe2) {
            newCetak();
          }
          if (tipe3) {
            newStatus();
            newBastDate();
          }
          if (tipe4) {
            newDokumen();
          }
          if (superAdmin) {
            // newLengkapi();
            newUpdate();
          }
        } else if (
          data?.approvalStatus === "APPROVED" ||
          data?.approvalStatus === "APPROVE"
        ) {
          if (tipe2) {
            newCetak();
          }
          if (tipe3) {
            newStatus();
            newBastDate();
          }
          if (tipe4) {
            newDokumen();
          }
          if (superAdmin) {
            newLengkapi();
            newUpdate();
          }
        }
      } else if (data?.status === "DISPOSE") {
        if (data?.approvalStatus === "WAITING_APPROVAL") {
          if (tipe1) {
            newCetak();
          }
          if (tipe4) {
            newDokumen();
          }
          if (superAdmin) {
            newLengkapi();
            newUpdate();
            newStatus();
            newBastDate();
          }
        } else if (data?.approvalStatus === "REJECTED") {
          if (tipe2) {
            newCetak();
          }
          if (tipe4) {
            newDokumen();
          }
          if (superAdmin) {
            // newLengkapi();
            newUpdate();
            newStatus();
            newBastDate();
          }
        } else if (
          data?.approvalStatus === "APPROVED" ||
          data?.approvalStatus === "APPROVE"
        ) {
          if (tipe2) {
            newCetak();
          }
          if (tipe4) {
            newDokumen();
          }
          if (superAdmin) {
            newLengkapi();
            newUpdate();
            newStatus();
            newBastDate();
          }
        }
      } else if (data?.status === "TIDAK_DITEMUKAN") {
        if (data?.approvalStatus === "WAITING_APPROVAL") {
          if (tipe2) {
            newCetak();
          }
          if (tipe3) {
            newStatus();
            newBastDate();
          }
          if (superAdmin) {
            newLengkapi();
            newUpdate();
          }
        } else if (data?.approvalStatus === "REJECTED") {
          if (tipe2) {
            newCetak();
          }
          if (tipe3) {
            newStatus();
            newBastDate();
          }
          if (superAdmin) {
            // newLengkapi();
            newUpdate();
          }
        } else if (
          data?.approvalStatus === "APPROVED" ||
          data?.approvalStatus === "APPROVE"
        ) {
          if (tipe2) {
            newCetak();
          }
          if (tipe3) {
            newStatus();
            newBastDate();
          }
          if (superAdmin) {
            newLengkapi();
            newUpdate();
          }
        }
        // } else if (data?.status === "WAITING_APPROVAL") {
        //   if (data?.approvalStatus === "WAITING_APPROVAL") {
        //   } else if (data?.approvalStatus === "REJECTED") {
        //   } else if (
        //     data?.approvalStatus === "APPROVED" ||
        //     data?.approvalStatus === "APPROVE"
        //   ) {
        //   }
      }
    }

    const uniqItems = items.reduce(function (a, b) {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);

    return <Menu items={uniqItems} />;
  };

  return (
    <Dropdown overlay={menuAction(props.data)} trigger={["click"]}>
      <a onClick={(e) => e.preventDefault()}>
        <EllipsisOutlined rotate={90} />
      </a>
    </Dropdown>
  );
};

export default ActionAssetList;
