import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Select,
  Typography,
} from "antd";
import React, { useState, useEffect, useContext } from "react";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "./LabelPrinting.module.css";
import AssetContext from "../../Services/Asset";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;

function AddLabelPrinting(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [dataBuilding, setDataBuilding] = useState([]);
  const [cipValue, setCipValue] = useState("");
  const { addLabelPrinting, getAllBuilding } = useContext(AssetContext);
  let navigate = useNavigate();

  const onFinish = async (data) => {
    data.date_arrived = moment(data.date_arrived).format("YYYY/MM/DD");
    const res = await addLabelPrinting(data);
    if (res?.error === false) {
      notification.success({
        message: "Success",
        description: "Request Label Success",
      });
      navigate("/penerimaan-asset");
    }
  };

  function disabledDate(current) {
    if (localStorage.getItem("role_id") !== "SUPERADMIN") {
      return current && current < moment().endOf("day");
    }
  }

  const getData = async () => {
    const newData = await getAllBuilding();
    if (newData) {
      const newDataBuilding =
        newData.data &&
        newData.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      setDataBuilding(newDataBuilding);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(value) && value.length <= 8) {
      setCipValue(value);
    }
  };

  return (
    <AdminLayout>
      <Form
        form={form}
        layout="vertical"
        name="control-hooks"
        autoComplete="off"
        onFinish={onFinish}
      >
        <Card title={"Request Label"} className={styles.cardTable}>
          <Row gutter={[0, 15]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                name="total"
                label="Asset Total"
                rules={[
                  {
                    required: true,
                    message: "Please put the asset total quantity!",
                  },
                ]}
                disabled={loading}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              >
                <Input placeholder="Asset Total" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                name="building_id"
                label="Building Location"
                rules={[
                  {
                    required: true,
                    message: "Please select building location!",
                  },
                ]}
                disabled={loading}
              >
                <Select
                  showSearch
                  placeholder="Select building location"
                  options={dataBuilding}
                  onSearch={(item) => console.log(item)}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                name="date_arrived"
                label="Date Arrived"
                rules={[
                  {
                    required: true,
                    message: "Please select date arrived!",
                  },
                ]}
                disabled={loading}
              >
                <DatePicker
                  className={styles.date_picker}
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                name="cip_number"
                label="CIP"
                rules={[
                  {
                    required: true,
                    message: "Please put the CIP Number!",
                  },
                ]}
                disabled={loading}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              >
                <TextArea
                  autoSize={{
                    minRows: 1,
                    maxRows: 1,
                  }}
                  placeholder="CIP"
                  maxLength={8}
                  value={cipValue}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                name="description"
                label="Description"
                disabled={loading}
              >
                <TextArea
                  autoSize={{
                    minRows: 6,
                    maxRows: 6,
                  }}
                  placeholder="Description"
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{ textAlign: "center" }}
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    minWidth: "200px",
                    minHeight: "40px",
                    borderRadius: "10px",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </AdminLayout>
  );
}

export default AddLabelPrinting;
