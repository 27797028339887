import {
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { ArrowLeftOutlined } from "@ant-design/icons";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "./LabelPrinting.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import AssetContext from "../../Services/Asset";
import PrintLabel from "./print";
import moment from "moment";
import Modal from "../../Components/Global/Modal";
import * as XLSX from "xlsx";
import { excel } from "react-export-table-to-excel/lib/lib";
moment.locale("id");

const { Text } = Typography;

function DetailLabelPrinting(props) {
  let { id } = useParams();
  const { getDetailLabelPrinting, deleteQR } = useContext(AssetContext);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSelected, setDataSelected] = useState([]);
  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const componentRef = useRef();
  const [form] = Form.useForm();
  const [modal, setModal] = useState({
    title: "",
    open: false,
    loading: false,
    onOk: () => {},
    body: "",
  });
  let navigate = useNavigate();

  const columns = [
    {
      title: "ID QRCode",
      dataIndex: "id",
      key: "id",
      width: 400,
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
    {
      title: "Running Number",
      dataIndex: "running_number",
      key: "running_number",
      width: 400,
      sorter: (a, b) => a.running_number_counter.localeCompare(b.running_number_counter, undefined, {'numeric': true}),
    },
    {
      title: "Asset Name",
      dataIndex: "asset",
      key: "asset",
      width: 400,
      sorter: (a, b) => a.asset?.name.localeCompare(b.asset?.name),
      render: (data) => data?.name,
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      sorter: true,
      render: (data) =>
        data.asset?.id ? (
          <Link to={"/registered-asset/detail/" + data.asset?.id}>
            <div className={styles.buttonGroupSuccess}>
              <Button className={styles.buttonSuccess}>
                Lihat Detail Asset
              </Button>
            </div>
          </Link>
        ) : (
          String(dataDetail.user?.id) === localStorage.getItem("user_id") && (
            <div className={styles.buttonGroupDanger}>
              <Button
                className={styles.buttonDanger}
                onClick={() => showModalDelete(data?.id)}
              >
                Hapus QR Code
              </Button>
            </div>
          )
        ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    var dataSel = [];
    newSelectedRowKeys.map((x) => {
      let y = data.find((dat) => {
        return dat.id === x;
      });
      let z = {
        id: y.id,
        nama: y.asset?.name,
        running_number: y.running_number,
      };
      dataSel.push(z);
    });
    setDataSelected(dataSel);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handlePrintSelected = () => {
    try {
      const excelData = dataSelected.map((x) => {
        return {
          id: x.id,
          running_number: x.running_number,
        };
      });
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(excelData);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const wbBlob = new Blob(
        [XLSX.write(wb, { type: "array", bookType: "xlsx" })],
        { type: "application/octet-stream" }
      );
      const url = window.URL.createObjectURL(wbBlob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `asset-${moment().format("YYYYMMDDHHmmss")}.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error printing Excel:", error);
      notification.error({
        message: "Error",
        description: "Failed to generate Excel file. Please try again later.",
      });
    }
  };

  const handlePrintAll = () => {
    try {
      const excelData = data.map((x) => {
        return {
          id: x.id,
          running_number: x.running_number,
        };
      });
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(excelData);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const wbBlob = new Blob(
        [XLSX.write(wb, { type: "array", bookType: "xlsx" })],
        { type: "application/octet-stream" }
      );
      const url = window.URL.createObjectURL(wbBlob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `asset-${moment().format("YYYYMMDDHHmmss")}.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error printing Excel:", error);
      notification.error({
        message: "Error",
        description: "Failed to generate Excel file. Please try again later.",
      });
    }
  };

  const showModalDelete = (id) => {
    setModal({
      title: "Delete QR Code",
      open: true,
      onOk: async () => {
        setModal((prev) => ({
          ...prev,
          loading: true,
        }));
        const res = await deleteQR(id);
        if (res?.error === false || !res?.error) {
          setModal((prev) => ({
            ...prev,
            loading: false,
            open: false,
          }));
          notification.success({
            message: "Success",
            description: "QR Code Deleted",
          });
          getData();
        } else {
          setModal((prev) => ({
            ...prev,
            open: false,
            loading: false,
          }));
        }
      },
      body: "Apakah Anda yakin menghapus QR Code ini?",
    });
  };

  const getData = async () => {
    const newData = await getDetailLabelPrinting(id);
    if (newData) {
      const processedData = newData.data?.qr_codes.map((item) => {
        if (item.running_number) {
          const parts = item.running_number.split("-");
          return {
            ...item,
            running_number_counter: parts[2],
          };
        } else {
          return {
            ...item,
            running_number_counter: 0,
          };
        }
      });
      setData(processedData);
      setDataDetail(newData.data);
      const newField = newData.data;
      newField.location = newField?.building?.name;
      newField.user_name = newField?.user?.name;
      newField.unit_name = newField?.unit?.name;
      newField.date =
        newField?.date_arrived && moment(newField?.date_arrived).format("LL");
      form.setFieldsValue(newField);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <AdminLayout>
      <Card
        className={styles.cardTable}
        extra={
          <Space>
            <Button
              icon={<ArrowLeftOutlined />}
              type="primary"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </Space>
        }
      >
        <Form form={form}>
          <Row gutter={40} align={""}>
            <Col className={styles.detailRow} xs={9} sm={9} md={9} lg={9}>
              <Row className={styles.inRow} gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Nama User</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="user_name">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.inRow} gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Lokasi</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="location">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.inRow} gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Unit</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="unit_name">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col className={styles.detailRow} xs={9} sm={9} md={9} lg={9}>
              <Row className={styles.inRow} gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Tanggal</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="date">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.inRow} gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Total</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="total">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.inRow} gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Deskripsi</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="description">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col
              className={styles.detailRow}
              xs={6}
              sm={6}
              md={6}
              lg={6}
              style={{ alignSelf: "center" }}
            >
              <Space size={"large"} wrap={true}>
                {(localStorage.getItem("role_id") === "ADMIN_GA" ||
                  localStorage.getItem("role_id") === "SUPERADMIN") && (
                  <div className={styles.buttonGroupSuccess}>
                    <Button
                      className={styles.buttonSuccess}
                      disabled={!selectedRowKeys.length > 0}
                      onClick={handlePrintSelected}
                    >
                      Export Selected Label
                    </Button>
                  </div>
                )}
                <Text>
                  {selectedRowKeys.length > 0
                    ? `Selected ${selectedRowKeys.length} items`
                    : ""}
                </Text>
              </Space>
              <Space size={"large"} wrap={true}>
                {(localStorage.getItem("role_id") === "ADMIN_GA" ||
                  localStorage.getItem("role_id") === "SUPERADMIN") && (
                  <div className={styles.buttonGroupSuccess}>
                    <Button
                      className={styles.buttonSuccess}
                      onClick={handlePrintAll}
                    >
                      Export All Label
                    </Button>
                  </div>
                )}
              </Space>
            </Col>
          </Row>
          <Row gutter={40}>
            <Col className={styles.detailRow} span={24}>
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={data}
                rowKey="id"
                pagination
              />
            </Col>
          </Row>
        </Form>
      </Card>
      <div style={{ display: "none" }}>
        <PrintLabel data={dataSelected} innerRef={componentRef} />
      </div>
      <Modal
        title={modal.title}
        open={modal.open}
        onOk={modal.onOk}
        loading={modal.loading}
        onCancel={() => {
          setModal({
            open: false,
            loading: false,
            title: "",
            body: "",
            onOk: () => {},
          });
        }}
        body={modal.body}
      />
    </AdminLayout>
  );
}

export default DetailLabelPrinting;
