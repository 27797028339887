import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  // timeout: 30 * 60 * 1000,
  timeout: 1 * 60 * 1000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

API.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    const { status } = err.response;
    if (status === 401) {
      localStorage.clear();
      window.location = "/login";
    }
    return Promise.reject(err);
  }
);

export default API;
