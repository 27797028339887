import {
  Button,
  Card,
  Col,
  Image,
  notification,
  Popconfirm,
  Row,
  Space,
} from "antd";
import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import styles from "../Global.module.css";

const CardDokumenAsset = (props) => {
  
  const handleDelete = async (asset_id, id) => {
    const newData = await props.action(asset_id, id);
    if (newData?.error === false || !newData.error) {
      notification.success({
        message: "Success",
        description: "Delete Document Asset Success",
      });
      props.getData();
    }
  };

  return (
    <Card title={"Dokumen Asset"} className={styles.cardTable}>
      <Row gutter={40}>
        <Col className={styles.detailRow} span={24}>
          <Image.PreviewGroup>
            <Space size={"large"} wrap={true} direction="vertical">
              {props?.data?.documents &&
                props?.data?.documents.map((item, index) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <a
                        key={index}
                        rel="noreferrer"
                        target="_blank"
                        href={process.env.REACT_APP_IMAGE_URL + "/" + item.name}
                      >
                        <Button icon={<DownloadOutlined />}>Download</Button>
                      </a>
                      <div style={{ marginLeft: "10px" }}>{item.name}</div>
                    </div>
                    {localStorage.getItem("role_id") === "SUPERADMIN" && (
                      <div style={{ marginLeft: "50px" }}>
                        <Popconfirm
                          title="Sure to delete?"
                          onConfirm={() => handleDelete(props.id, item.id)}
                        >
                          <DeleteOutlined
                            style={{ color: "#bd3f39", fontSize: "24px" }}
                          />
                        </Popconfirm>
                      </div>
                    )}
                  </div>
                ))}
            </Space>
          </Image.PreviewGroup>
        </Col>
      </Row>
    </Card>
  );
};

export default CardDokumenAsset;
