import React from "react";
import { Col, DatePicker, Form, Row, Select } from "antd";
import moment from 'moment';

function disabledDate(current) {
  return current && current > moment().endOf('day');
}


function UpdateBastDate(props) {

  return (
    <Form
      form={props.form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      labelAlign={"left"}
    >
      <Row gutter={[0, 15]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="bast_date"
            label="Tanggal BAST"
            rules={[
              {
                required: true,
                message: "Please input date!",
              }
            ]}
          >
            <DatePicker placeholder="Tanggal BAST" format="YYYY-MM-DD" disabledDate={disabledDate} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default UpdateBastDate;
