import React from "react";
import { Col, DatePicker, Form, Row } from "antd";
import moment from "moment";

function RenewalAsset(props) {
  const disabledDate = (current) => {
    return current < moment(props.return_date).add(1, "days");
  };

  return (
    <Form
      form={props.form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      labelAlign={"left"}
    >
      <Row gutter={[0, 15]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="new_return_date"
            label="Renewal Date"
            rules={[
              {
                required: true,
                message: "Please input date!",
              },
            ]}
          >
            <DatePicker
              placeholder="Renewal Date"
              format="YYYY-MM-DD"
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default RenewalAsset;
