import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row, Input, Select } from "antd";
import AssetContext from "../../../Services/Master";

function AddUpdate(props) {
  const {
    getAllMaster,
    getMaster,
  } = useContext(AssetContext);
  const [dataBuilding, setDataBuilding] = useState([]);
  const [dataFloor, setDataFloor] = useState([]);

  const getBuilding = async () => {
    const newData = await getAllMaster("buildings");
    if (newData) { 
      const newDataBuilding =
        newData.data &&
        newData.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      setDataBuilding(newDataBuilding);
    }
  }

  const getFloor = async (id) => {
    const newData = await getMaster("floors/building",id);
    if (newData) { 
      const newDataFloor =
        newData.data &&
        newData.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      setDataFloor(newDataFloor);
    }
  }

  useEffect(() => {
    getBuilding();
  }, [])

  const changeBuilding = (data) => {
    if (data) {
      getFloor(data)
    }
  }

  return (
    <Form
      form={props.form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      labelAlign={"left"}
    >
      <Row gutter={[0, 15]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="building_id"
            label="Gedung"
            rules={[
              {
                required: true,
                message: "Please select building!",
              }
            ]}
          >
            <Select
              showSearch
              placeholder="Select building"
              options={dataBuilding}
              onChange={(data)=>changeBuilding(data)}
              filterOption={(input, option) =>  
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }              
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="floor_id"
            label="Lantai"
            rules={[
              {
                required: true,
                message: "Please select floor!",
              }
            ]}
          >
            <Select
              showSearch
              placeholder="Select floor"
              options={dataFloor}
              filterOption={(input, option) =>  
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }              
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="name"
            label="Nama Ruang"
            rules={[
              {
                required: true,
                message: "Please input name!",
              },
            ]}
          >
            <Input placeholder="Nama Ruang" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="is_active_flag"
            label="Status"
            rules={[
              {
                required: true,
                message: "Please select status!",
              }
            ]}
          >
            <Select
              placeholder="Select status"
            >
              <Select.Option value={true}>Aktif</Select.Option>
              <Select.Option value={false}>Non Aktif</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default AddUpdate;
