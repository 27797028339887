import React, { useState, useContext } from "react";
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Button,
  Upload,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import AssetContext from "../../../Services/Asset";

function LengkapiData(props) {
  const { upload, uploadDokumenAction } = useContext(AssetContext);
  const [fileList, setFileList] = useState([]);

  const disabledDate = (current) => {
    return current < props.form.getFieldValue("insurance_period_start");
  };

  const handleSubmit = async (values) => {
    if (fileList.length > 0) {
      const res = await upload(fileList, "registrasi");
      if (res) {
        const res2 = await uploadDokumenAction(props.data?.id, {
          documents: res.data,
        });
        if (res2?.error === false || !res2?.error) {
          message.success("Upload Berhasil");
        } else {
          message.error(res2?.message || "Terjadi kesalahan saat mengunggah.");
        }
        setFileList([]);
      } else {
        message.error("Tidak dapat mengunggah file. Coba lagi.");
      }
    }
  };

  const uploadProps = {
    multiple: true,
    onRemove: (file) => {
      setFileList((prevList) => {
        const index = prevList.indexOf(file);
        const newFileList = prevList.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    beforeUpload: (file) => {
      const fileTypeAllowed = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      ];
      const isAllowedFileType = fileTypeAllowed.includes(file.type);
      if (!isAllowedFileType) {
        message.error(
          "Anda hanya dapat mengunggah file JPG/PNG/PDF/DOC/DOCX/XLS/XLSX/PPT/PPTX!"
        );
        return false;
      }

      let file_size = 16;
      const isLt2M = file.size / 1024 / 1024 < file_size;
      if (!isLt2M) {
        message.error("Image must smaller than " + file_size + "MB!");
        return isLt2M;
      }

      setFileList((prevList) => [...prevList, file]);
      return false;
    },
    fileList,
  };

  return (
    <Form
      form={props.form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      labelAlign={"left"}
      onFinish={handleSubmit} // Menggunakan handleSubmit pada onFinish
    >
      <Row gutter={[0, 15]}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          style={{
            display:
              props.data.vendor_name == null || props.data.vendor_name === ""
                ? ""
                : "none",
          }}
        >
          <Form.Item name="vendor_name" label="Vendor Name">
            <Input placeholder="Vendor Name" />
          </Form.Item>
        </Col>
        {props.data?.room?.has_rack && (
          <>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{
                display:
                  props.data.rack_id == null || props.data.rack_id == 0
                    ? ""
                    : "none",
              }}
            >
              <Form.Item name="rack_id" label="Rack ID">
                <Input placeholder="Rack ID" />
              </Form.Item>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{
                display:
                  props.data.location_u == null || props.data.location_u == 0
                    ? ""
                    : "none",
              }}
            >
              <Form.Item name="location_u" label="Location U">
                <Input placeholder="Location U" />
              </Form.Item>
            </Col>
          </>
        )}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          style={{
            display:
              props.data.locationNote === null ||
              props.data.locationNote === ""
                ? ""
                : "none",
          }}
        >
          <Form.Item name="location_note" label="Location Note">
            <Input placeholder="Location Note" />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          style={{
            display:
              props.data.insurance == null || props.data.insurance === ""
                ? ""
                : "none",
          }}
        >
          <Form.Item name="insurance" label="Insurance">
            <Input placeholder="Insurance" />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          style={{
            display:
              props.data.insurance_period_start == null ||
              props.data.insurance_period_start === ""
                ? ""
                : "none",
          }}
        >
          <Form.Item
            name="insurance_period_start"
            label="Insurance Period Start"
          >
            <DatePicker
              placeholder="Insurance Period Start"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          style={{
            display:
              props.data.insurance_period_end == null ||
              props.data.insurance_period_end === ""
                ? ""
                : "none",
          }}
        >
          <Form.Item name="insurance_period_end" label="Insurance Period End">
            <DatePicker
              placeholder="Insurance Period End"
              style={{ width: "100%" }}
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>

        {/* Tombol Select File */}
        <Col xs={24} sm={24} md={24} lg={24}>
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>Pilih File</Button>
          </Upload>
        </Col>
      </Row>
    </Form>
  );
}

export default LengkapiData;
