import { Button, Card, Col, Row, Table, Tag, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "../../Components/Global/Global.module.css";
import AssetContext from "../../Services/Asset";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import StatusApprovalList from "../../Components/Global/View/StatusApprovalList";
moment.locale("id");

const { Text } = Typography;

function DetailHistoryDisposal(props) {
  let { id, idIndex } = useParams();
  let navigate = useNavigate();
  const { getAssetMovement } = useContext(AssetContext);
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState({});

  const columnsHistory = [
    {
      title: "Tanggal",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 250,
      render: (data) => {
        return data && moment(data).format("LL HH:mm");
      },
    },
    {
      title: "Urutan",
      dataIndex: "sequenceNumber",
      key: "sequenceNumber",
      sorter: (a, b) => a.sequenceNumber - b.sequenceNumber,
      defaultSortOrder: "ascend",
    },
    {
      title: "Role",
      dataIndex: "role_id",
      key: "role_id",
      width: 400,
    },
    {
      title: "Approval Status",
      dataIndex: "approval_st",
      key: "approval_st",
      width: 250,
      render: (data) => (
        <Tag
          style={{
            borderRadius: "20px",
            minWidth: "120px",
            textAlign: "center",
          }}
          color={StatusApprovalList(data)}
        >
          {data}
        </Tag>
      ),
    },
    {
      title: "Catatan",
      dataIndex: "description",
      key: "notes",
      width: 250,
    },
  ];

  const getData = async () => {
    const newData = await getAssetMovement(id);
    if (newData) {
      if (newData?.data?.assetMovementApprovals?.length > 0) {
        const newIndex = newData?.data?.assetMovementApprovals.findIndex(
          function (data) {
            return String(data.id) === idIndex;
          }
        );

        if (newIndex !== -1) {
          setData(newData?.data?.assetMovementApprovals[newIndex]);
        }
      }
      setDetail(newData?.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <AdminLayout>
      <Card
        title={"Detail Riwayat Approval Disposal"}
        className={styles.cardTable}
        extra={
          <Button
            icon={<ArrowLeftOutlined />}
            type="primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        }
      >
        <Row gutter={40} align={"middle"}>
          <Col span={24} lg={12}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Unit</Text>
              </Col>
              <Col span={16}>: {detail?.created_by?.unit?.name}</Col>
            </Row>
          </Col>
          <Col span={24} lg={12}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Transaction Date</Text>
              </Col>
              <Col span={16}>
                :{" "}
                {(detail?.transactionDate &&
                  moment(detail?.transactionDate).format("LL")) ||
                  "-"}
              </Col>
            </Row>
          </Col>
          <Col span={24} lg={12}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Status</Text>
              </Col>
              <Col span={16}>
                :{" "}
                <Tag
                  style={{
                    borderRadius: "20px",
                    minWidth: "120px",
                    textAlign: "center",
                  }}
                  color={StatusApprovalList(
                    !data?.is_done_flag && !data?.is_rejected_flag
                      ? "WAITING_APPROVAL"
                      : data?.is_done_flag && !data?.is_rejected_flag
                      ? "APPROVE"
                      : "REJECTED"
                  )}
                >
                  {!data?.is_done_flag && !data?.is_rejected_flag
                    ? "Proses"
                    : data?.is_done_flag && !data?.is_rejected_flag
                    ? "Full Approve"
                    : "Reject"}
                </Tag>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card
            title={"Riwayat Approval"}
            className={styles.cardTable}
            style={{ marginTop: "50px" }}
          >
            <Row gutter={40}>
              <Col className={styles.detailRow} span={24}>
                <Table
                  columns={columnsHistory}
                  dataSource={data?.asset_movement_approval_details}
                  rowKey={"id"}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </AdminLayout>
  );
}

export default DetailHistoryDisposal;
