
import React, { useEffect } from "react";
import QRCode from "react-qr-code";

const PrintLabel = ({ data, innerRef }) =>  {
  return (
    <div ref={innerRef}>
      {data && data.map((item) => (
        <div style={{textAlign:"center", pageBreakAfter:"always", paddingTop:"10%"}}>
          <QRCode value={item.id} />
          <div>{item.running_number} - {item.id}</div>
          <hr></hr>
          <div>PT Jalin Pembayaran Nusantara</div>
          <hr></hr>
          <hr></hr>
        </div>
      ))}
    </div>
  )
}
export default PrintLabel;