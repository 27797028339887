import React, { useState } from "react";
import { Form, Modal, notification, Typography } from "antd";
import LengkapiData from "../Form/LengkapiData";

const { Text } = Typography;

function ModalLengkapiData(props) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const submit = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      if (values) {
        form.submit();
        const res = await props.action(props?.data?.id, values);
        if (res?.error === false || !res?.error) {
          notification.success({
            message: "Success",
            description: "Complete data Success",
          });
          setLoading(false);
          props.getData();
          setOpen(false);
        }
      }
    } catch (errInfo) {
      setLoading(false);
    }
  };

  return (
    <>
      <Text onClick={() => setOpen(true)} style={{ display: "block" }}>
        Lengkapi Data
      </Text>
      <Modal
        title={"Lengkapi Data"}
        open={open}
        onOk={submit}
        confirmLoading={loading}
        onCancel={() => {
          setLoading(false);
          setOpen(false);
        }}
        okText={"Update"}
      >
        <LengkapiData id={props.id} data={props.data} form={form} />
      </Modal>
    </>
  );
}

export default ModalLengkapiData;
