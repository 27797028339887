import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  notification,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "./StockOpname.module.css";
import { Link } from "react-router-dom";
import AssetContext from "../../Services/Asset";
import moment from "moment";
import "moment/locale/id";
import Modal from "../../Components/Global/Modal";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
moment.locale("id");

const { Text } = Typography;

const statusStockOpname = (data) => {
  if (data === "PENDING") {
    return (
      <Tag
        style={{ borderRadius: "20px", minWidth: "120px", textAlign: "center" }}
        color="#FEC400"
      >
        PENDING
      </Tag>
    );
  } else if (data === "APPROVED") {
    return (
      <Tag
        style={{ borderRadius: "20px", minWidth: "120px", textAlign: "center" }}
        color="#18B84E"
      >
        APPROVED
      </Tag>
    );
  }
};

function StockOpname(props) {
  const [data, setData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [modalData, setModalData] = useState({
    date: "",
    reason: "",
    type: "START",
  });
  const { getStockOpname, startStockOpname, extendStockOpname } =
    useContext(AssetContext);
  const [modal, setModal] = useState({
    title: "",
    open: false,
    loading: false,
    onOk: () => {},
    body: "",
  });
  const tableRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  const columns = [
    {
      title: "Unit Kerja",
      key: "unit_kerja",
      width: 400,
      sorter: (a, b) => a.unit?.name.localeCompare(b.unit?.name),
      render: (data) => (
        <Link to={`/stock-opname/stock-opname/detail/${data?.unit?.id}`}>
          {data?.unit?.name}
        </Link>
      ),
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(record?.unit?.name)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.current_asset_opname)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.total_assets)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.status)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase())
        );
      },
    },
    {
      title: "Opname Selesai",
      key: "opname_selesai",
      width: 200,
      sorter: false,
      render: (data) => `${data?.current_asset_opname}/${data?.total_assets}`,
    },
    {
      title: "Request Perpanjangan Stock Opname",
      key: "extend_date_request",
      width: 200,
      sorter: (a, b) =>
        (a.extend_date_request || "").localeCompare(
          b.extend_date_request || ""
        ),
      render: (data) =>
        data?.extend_date_request &&
        moment(data?.extend_date_request).format("LL"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (data) => statusStockOpname(data),
    },
  ];

  const showModalOpname = (type) => {
    if (type === "START") {
      setModalData({ ...modalData, type: "START" });
    } else {
      setModalData({ ...modalData, type: "EXTEND" });
    }
    setModal({
      title: "Stock Opname",
      open: true,
      onOk: type === "START" ? handleOk : handleExtend,
      body: (
        <DatePicker
          placeholder="End Date"
          // defaultValue={moment(
          //   data?.stock_opname_data?.closed_at,
          //   "YYYY-MM-DD"
          // )}
          onChange={(date) =>
            setModalData({
              ...modalData,
              date: date.format("YYYY/MM/DD"),
            })
          }
          disabledDate={(current) =>
            current < moment().subtract(0, "days")
          }
          style={{ width: "100%" }}
        />
      ),
    });
  };

  const handleOk = async () => {
    if (modalData.date) {
      setModal((prev) => ({
        ...prev,
        loading: true,
      }));
      const res = await startStockOpname({ closed_date: modalData.date });
      if (res?.data?.error === false || !res?.data?.error) {
        notification.success({
          message: "Success",
          description: "Start Stock Opname Success",
        });
        setModal((prev) => ({
          ...prev,
          open: false,
          loading: false,
        }));
        getData();
      } else {
        setModal((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    } else {
      notification.error({
        message: "Error",
        description: "Date is required",
      });
    }
  };

  const handleExtend = async () => {
    if (modalData.date) {
      setModal((prev) => ({
        ...prev,
        loading: true,
      }));
      const res = await extendStockOpname({ closed_date: modalData.date });
      if (res?.data?.error === false || !res?.data?.error) {
        notification.success({
          message: "Success",
          description: "Extend Stock Opname Success",
        });
        setModal((prev) => ({
          ...prev,
          open: false,
          loading: false,
        }));
        getData();
      } else {
        setModal((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    } else {
      notification.error({
        message: "Error",
        description: "Date is required",
      });
    }
  };

  const title = () => {
    return (
      <Text>
        {data.is_so_active === false
          ? "SAAT INI SEDANG TIDAK DALAM MASA STOCK OPNAME"
          : "SAAT INI SEDANG DALAM MASA STOCK OPNAME"}
      </Text>
    );
  };

  const getData = async () => {
    const newData = await getStockOpname();
    if (newData) {
      setData(newData.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (modalData.date) {
      setModal((prev) => ({
        ...prev,
        onOk: modalData.type === "START" ? handleOk : handleExtend,
      }));
    }
  }, [modalData]);

  return (
    <AdminLayout>
      <Card
        title={title()}
        className={`${styles.cardInfo} ${
          data.is_so_active === false
            ? styles.titleActive
            : styles.titleNotActive
        }`}
      >
        {(localStorage.getItem("role_id") === "ADMIN_GA" ||
          localStorage.getItem("role_id") === "SUPERADMIN" ||
          localStorage.getItem("role_id") === "OFFICE_VENDOR_MANAGEMENT") && (
          <Space className={styles.container} direction="vertical">
            <Space
              align="center"
              // size={"large"}
              style={{ width: "100%", justifyContent: "center" }}
              wrap
            >
              {data.is_so_active === false ? (
                <div className={styles.buttonCardWarning}>
                  <Button
                    size="large"
                    type="default"
                    className={styles.buttonWarning}
                    onClick={() => showModalOpname("START")}
                  >
                    Stock Opname
                  </Button>
                </div>
              ) : (
                <div className={styles.buttonCardSuccess}>
                  <Button
                    size="large"
                    type="default"
                    className={styles.buttonSuccess}
                    onClick={() => showModalOpname("EXTEND")}
                  >
                    Perpanjangan Stock Opname
                  </Button>
                </div>
              )}
            </Space>
          </Space>
        )}
      </Card>
      <Card title={"Stock Opname Summary"} className={styles.cardTable}>
        <Row gutter={10} align={"middle"}>
          <Col xs={24} sm={18} md={18} lg={16}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Mulai</Text>
              </Col>
              <Col span={16}>
                :{" "}
                {data?.stock_opname_data?.opened_at &&
                  moment(data?.stock_opname_data?.opened_at).format("LLL")}
              </Col>
            </Row>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Selesai</Text>
              </Col>
              <Col span={16}>
                :{" "}
                {data?.stock_opname_data?.closed_at &&
                  moment(data?.stock_opname_data?.closed_at).format("LLL")}
              </Col>
            </Row>
            {(localStorage.getItem("role_id") === "ADMIN_GA" ||
              localStorage.getItem("role_id") === "SUPERADMIN") && (
              <Row gutter={10} align={"middle"}>
                <Col span={8}>
                  <Text>Total Asset</Text>
                </Col>
                <Col span={16}>: {data?.stock_opname_data?.asset_total}</Col>
              </Row>
            )}

            {(localStorage.getItem("role_id") === "ADMIN_GA" ||
              localStorage.getItem("role_id") === "SUPERADMIN") && (
              <Row gutter={10} align={"middle"}>
                <Col span={8}>
                  <Text>Total Asset Opname</Text>
                </Col>
                <Col span={16}>
                  : {data?.stock_opname_data?.real_asset_total}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Card>
      <Card className={styles.cardTable}>
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
          span={24}
          xl={18}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "10px" }}>Cari</div>
            <Input
              onChange={(text) => {
                setSearchText(text?.target?.value);
              }}
              placeholder="Cari..."
              style={{ maxWidth: "300px", marginRight: "30px" }}
            />
          </div>
          {/* <DownloadTableExcel
            filename={`Daftar Asset ${moment().unix()}`}
            currentTableRef={tableRef.current}
          >
            <Button type="primary" style={{ marginRight: "10px" }}>
              Export Excel
            </Button>
          </DownloadTableExcel>
          <Button onClick={handlePrint} type="primary" danger>
            Print PDF
          </Button> */}
        </div>
        <Table
          ref={tableRef}
          columns={columns}
          dataSource={data?.stock_opname_unit}
          rowKey="id"
          pagination
        />
      </Card>

      <Modal
        title={modal.title}
        open={modal.open}
        onOk={modal.onOk}
        loading={modal.loading}
        onCancel={() => {
          setModal({
            open: false,
            loading: false,
            title: "",
            body: "",
            onOk: () => {},
          });
          setModalData({
            date: "",
            reason: "",
          });
        }}
        body={modal.body}
      />
    </AdminLayout>
  );
}

export default StockOpname;
