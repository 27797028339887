import React, { useContext, useState } from "react";
import { Button, Col, message, Modal, notification, Row, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import AssetContext from "../../Services/Asset";

function TakeoutStockOpname(props) {
  const { upload, takeoutStockOpname } = useContext(AssetContext);
  const [fileList, setFileList] = useState("");
  const [loading, setLoading] = useState(false);

  const handleUpload = async () => {
    setLoading(true);
    const res = await upload(fileList, "stock-opaname");
    if (res) {
      const res2 = await takeoutStockOpname(props.open?.id, {
        file_url: res.data&&res.data[0],
      });
      if (res2?.error === false || !res2?.error) {
        notification.success({
          message: "Success",
          description: "Takout Success",
        });
        props.setOpen({ open: false, id: "" });
        props?.getData();
      }
      setFileList([]);
    }
    setLoading(false);
  };

  const input = {
    onRemove: (file) => {
      setFileList("");
    },
    beforeUpload: (file) => {
      const fileTypeAllowed = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      const isJpgOrPng = fileTypeAllowed.includes(file.type);
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG/PDF/DOC/DOCX file!");
        return isJpgOrPng;
      }
      let file_size = 16;
      const isLt2M = file.size / 1024 / 1024 < file_size;
      if (!isLt2M) {
        message.error("Image must smaller than " + file_size + "MB!");
        return isLt2M;
      }
      setFileList(file);
      return false;
    },
    multiple: false,
  };

  return (
    <Modal
      title={"Upload Dokumen"}
      open={props?.open?.open}
      onOk={handleUpload}
      confirmLoading={loading}
      onCancel={() => {
        setLoading(false);
        setFileList([]);
        props.setOpen({ open: false, id: "" });
      }}
      okText={"Simpan"}
    >
      <Row gutter={[0, 15]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          Apakah Anda yakin melakukan disposal asset
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Upload {...input}>
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Col>
      </Row>
    </Modal>
  );
}

export default TakeoutStockOpname;
