import {
  Button,
  Card,
  Col,
  Dropdown,
  Image,
  Menu,
  notification,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "../StockOpname/StockOpname.module.css";
import { EllipsisOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import AssetContext from "../../Services/Asset";
import moment from "moment";
import "moment/locale/id";
import Modal from "../../Components/Global/Modal";
import TakeoutStockOpname from "./takout";
moment.locale("id");

const { Text } = Typography;

const assetList = (data) => {
  return (
    <Space>
      <Space size={0} direction="vertical">
        <Text>{data?.asset?.name}</Text>
        <Text type="secondary">{data?.asset?.serialNumber}</Text>
      </Space>
    </Space>
  );
};

const statusList = (data) => {
  if (data === "SUDAH_OPNAME") {
    return "#3AD647";
  } else if (
    data === "LOKASI_TIDAK_SESUAI" ||
    data === "USER_SUDAH_BERPINDAH" ||
    data === "ASSET_TIDAK_DITEMUKAN"
  ) {
    return "#FA3737";
  } else if (
    data === "BELUM_OPNAME" ||
    data === "WAITING_APPROVAL_DISPOSAL" ||
    data === "WAITING_APPROVAL_TAKEOUT"
  ) {
    return "#FFCD27";
  } else {
    return "#DED8D8";
  }
};

function DetailPending(props) {
  let { id } = useParams();
  let navigate = useNavigate();
  const [detail, setDetail] = useState({});
  const {
    getPendingStockOpname,
    approvalDisposeStockOpname,
    disposeStockOpname,
  } = useContext(AssetContext);
  const [modal, setModal] = useState({
    title: "",
    open: false,
    loading: false,
    onOk: () => {},
    body: "",
  });
  const [modalUploadDokumen, setModalUploadDokumen] = useState({
    open: false,
    id: "",
  });

  const columns = [
    {
      title: "Asset",
      key: "asset",
      width: 400,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (data) => assetList(data),
    },
    {
      title: "Approval",
      key: "approval",
      width: 250,
      sorter: false,
      render: (data) => approval(data),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (data) => (
        <Tag
          style={{
            borderRadius: "20px",
            minWidth: "120px",
            textAlign: "center",
          }}
          color={statusList(data)}
        >
          {data}
        </Tag>
      ),
    },
    {
      title: "Catatan Pending",
      dataIndex: "notes_pending",
      key: "notes_pending",
      width: 250,
      sorter: (a, b) => a.notes_pending.localeCompare(b.notes_pending),
    },
    {
      key: "action",
      width: 10,
      render: (data) => actionList(data),
    },
  ];

  const approval = (data) => {
    if (
      (localStorage.getItem("role_id") +
        "_" +
        localStorage.getItem("unit_key") ===
        data?.approval_role ||
        data?.approval_role === localStorage.getItem("role_id")) &&
      data.status === "WAITING_APPROVAL_TAKEOUT"
    ) {
      return (
        <Space>
          <div className={styles.buttonCardSuccess}>
            <Button
              size="large"
              type="default"
              className={styles.buttonSuccess}
              onClick={() => showModalApprove(data.id)}
            >
              Takeout
            </Button>
          </div>
        </Space>
      );
    }
  };

  const actionList = (data) => {
    return (
      <Dropdown overlay={menuAction(data)} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <EllipsisOutlined rotate={90} />
        </a>
      </Dropdown>
    );
  };

  const menuAction = (data) => {
    const menu = [
      // {
      //   label: (
      //     <Link to={"/daftar-asset/detail/" + data.asset_id}>
      //       Lihat Detail Asset
      //     </Link>
      //   ),
      //   key: "1",
      // },
      {
        label: (
          <Link to={"/stock-opname/pending/detail-opname/" + data.id}>
            Lihat Detail Stock Opname Asset
          </Link>
        ),
        key: "2",
      },
    ];

    if (
      localStorage.getItem("role_id") === "ADMIN_GA" ||
      localStorage.getItem("role_id") === "SUPERADMIN"
    ) {
      menu.push({
        label: "Takeout",
        key: "2",
        onClick: () => setModalUploadDokumen({ open: true, id: data.id }),
      });
    }

    if (
      localStorage.getItem("user_id") === String(data?.asset?.created_by?.id)
    ) {
      if (!data.is_location_ok) {
        menu.push({
          label: (
            <Link
              to={
                "/asset-movement/add/mutation-location/" +
                data.asset_id +
                "/" +
                data.id
              }
            >
              Movement Location
            </Link>
          ),
          key: "3",
        });
      } else if (!data.is_unit_ok) {
        menu.push({
          label: (
            <Link
              to={
                "/asset-movement/add/mutation-user/" +
                data.asset_id +
                "/" +
                data.id
              }
            >
              Movement User
            </Link>
          ),
          key: "3",
        });
      } else if (data.status === "ASSET_TIDAK_DITEMUKAN") {
        menu.push({
          label: (
            <Link to={"/disposal/add/" + data.asset_id + "/" + data.id}>
              Dispose
            </Link>
          ),
          key: "3",
        });
      } else if (!data.is_serial_number_ok) {
        menu.push({
          label: (
            <Link
              to={"/registered-asset/update/" + data.asset_id + "/" + data.id}
            >
              Edit Asset
            </Link>
          ),
          key: "4",
        });
      }
    }

    return <Menu items={menu} />;
  };

  const showModalApprove = (id) => {
    setModal({
      title: "Takeout Asset",
      open: true,
      onOk: async () => {
        setModal((prev) => ({
          ...prev,
          loading: true,
        }));
        const res = await approvalDisposeStockOpname(id);
        if (res?.data?.error === false || !res?.data?.error) {
          setModal((prev) => ({
            ...prev,
            open: false,
            loading: false,
          }));
          notification.success({
            message: "Success",
            description: "Asset Takeout",
          });
          getData();
        } else {
          setModal((prev) => ({
            ...prev,
            loading: false,
          }));
        }
      },
      body: "Apakah Anda yakin takeout asset?",
    });
  };

  const showModalDispose = (id) => {
    setModal({
      title: "Disposal Asset",
      open: true,
      onOk: async () => {
        setModal((prev) => ({
          ...prev,
          loading: true,
        }));
        const res = await disposeStockOpname(id);
        if (res?.data?.error === false || !res?.data?.error) {
          setModal((prev) => ({
            ...prev,
            open: false,
            loading: false,
          }));
          notification.success({
            message: "Success",
            description: "Disposal Approved",
          });
          getData();
        } else {
          setModal((prev) => ({
            ...prev,
            loading: false,
          }));
        }
      },
      body: "Apakah Anda yakin melakukan disposal asset?",
    });
  };

  const getData = async () => {
    const newDetail = await getPendingStockOpname(id);
    if (newDetail) {
      setDetail(newDetail.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <AdminLayout>
      <Card
        title={"Data Asset Stock Opname Pending"}
        className={styles.cardTable}
        extra={
          <Button
            icon={<ArrowLeftOutlined />}
            type="primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        }
      >
        <Table
          columns={columns}
          dataSource={detail?.list_assets}
          rowKey="id"
          pagination
          // expandable={{
          //   expandedRowRender: (record) => (
          //     <Row gutter={10} align={"middle"}>
          //       <Col span={24} lg={10}>
          //         <Row gutter={10} align={"middle"}>
          //           <Col span={8}>
          //             <Text>Nama Asset</Text>
          //           </Col>
          //           <Col span={16}>: {record?.asset?.name}</Col>
          //         </Row>
          //         <Row gutter={10} align={"middle"}>
          //           <Col span={8}>
          //             <Text>Tanggal Opname</Text>
          //           </Col>
          //           <Col span={16}>
          //             :{" "}
          //             {(record?.created_at &&
          //               moment(record?.created_at).format("LL")) ||
          //               "-"}
          //           </Col>
          //         </Row>
          //         <Row gutter={10} align={"middle"}>
          //           <Col span={8}>
          //             <Text>Serial Number</Text>
          //           </Col>
          //           <Col span={16}>: {record?.asset?.serialNumber}</Col>
          //         </Row>
          //       </Col>
          //       <Col span={24} lg={10}>
          //         <Row gutter={10} align={"middle"}>
          //           <Col span={8}>
          //             <Text>Fixed Asset Number</Text>
          //           </Col>
          //           <Col span={16}>: {record?.asset?.fixed_asset_number}</Col>
          //         </Row>
          //         <Row gutter={10} align={"middle"}>
          //           <Col span={8}>
          //             <Text>Unit Kerja</Text>
          //           </Col>
          //           <Col span={16}>: {record?.asset?.unit?.name}</Col>
          //         </Row>
          //         <Row gutter={10} align={"middle"}>
          //           <Col span={8}>
          //             <Text>Lokasi</Text>
          //           </Col>
          //           <Col span={16}>
          //             : {record?.asset?.room?.name} -{" "}
          //             {record?.asset?.room?.floor?.name} -{" "}
          //             {record?.asset?.room?.floor?.building?.name}
          //           </Col>
          //         </Row>
          //       </Col>
          //       <Col span={24} lg={24}>
          //         <Row gutter={10} align={"top"}>
          //           <Col span={8}>
          //             <Text>Foto Opname</Text>
          //           </Col>
          //           <Col span={16}>
          //             <Image.PreviewGroup>
          //               <Space size={"large"} wrap={true}>
          //                 {record?.stock_opname_asset_files &&
          //                   record?.stock_opname_asset_files.map((item) => (
          //                     <Image
          //                       width={200}
          //                       src={
          //                         process.env.REACT_APP_IMAGE_URL +
          //                         "/" +
          //                         item.path
          //                       }
          //                       style={{
          //                         marginTop: "20px",
          //                         maxHeight: "200px",
          //                         objectFit: "cover",
          //                       }}
          //                     />
          //                   ))}
          //               </Space>
          //             </Image.PreviewGroup>
          //           </Col>
          //         </Row>
          //       </Col>
          //     </Row>
          //   ),
          // }}
        />
      </Card>
      <Modal
        title={modal.title}
        open={modal.open}
        onOk={modal.onOk}
        loading={modal.loading}
        okText={modal.okText}
        onCancel={() => {
          setModal({
            open: false,
            loading: false,
            title: "",
            body: "",
            okText: "",
            onOk: () => {},
          });
        }}
        body={modal.body}
      />
      <TakeoutStockOpname
        open={modalUploadDokumen}
        setOpen={setModalUploadDokumen}
        getData={getData}
      />
    </AdminLayout>
  );
}

export default DetailPending;
