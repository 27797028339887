import {
  Button,
  Card,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  EllipsisOutlined,
  DownloadOutlined,
  DeleteOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "./BorrowingMonitoring.module.css";
import AssetContext from "../../Services/Asset";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal from "../../Components/Global/Modal";
import RenewalAsset from "../AssetMovement/component/renewalAsset";
moment.locale("id");

const { Text } = Typography;

const statusApproval = (data) => {
  if (data === "APPROVE") {
    return "#3AD647";
  } else if (data === "REJECTED") {
    return "#FA3737";
  } else if (data === "WAITING_APPROVAL") {
    return "#FFCD27";
  } else {
    return "#DED8D8";
  }
};

const statusList = (data) => {
  if (data === "WAITING_APPROVAL") {
    return (
      <Tag
        style={{
          borderRadius: "20px",
          minWidth: "120px",
          textAlign: "center",
        }}
        color="#FEC400"
      >
        WAITING APPROVAL
      </Tag>
    );
  } else if (data === "APPROVE") {
    return (
      <Tag
        style={{
          borderRadius: "20px",
          minWidth: "120px",
          textAlign: "center",
        }}
        color="#18B84E"
      >
        APPROVED
      </Tag>
    );
  } else {
    return (
      <Tag
        style={{
          borderRadius: "20px",
          minWidth: "120px",
          textAlign: "center",
        }}
        color="#EB4034"
      >
        REJECTED
      </Tag>
    );
  }
};

function DetailAssetMovement(props) {
  let { id } = useParams();
  let navigate = useNavigate();
  const {
    getAssetMovement,
    deleteDocumentMovement,
    renewalAction,
    returnAction,
  } = useContext(AssetContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [form] = Form.useForm();
  const [formModal] = Form.useForm();
  const [modal, setModal] = useState({
    title: "",
    open: false,
    loading: false,
    onOk: () => {},
    body: "",
  });

  const onChange = (newSelectedRowKeys) => {
    const newArray = selectedRowKeys;
    if (newSelectedRowKeys?.target?.checked) {
      newArray.push(newSelectedRowKeys?.target?.value);
    } else {
      const index = selectedRowKeys.indexOf(newSelectedRowKeys?.target?.value);
      if (index !== -1) {
        newArray.splice(index, 1);
      }
    }

    setSelectedRowKeys(newArray);
    setTotal(newArray.length);
  };

  const columns = [
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
      width: 800,
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
    },
  ];

  const childrenColumns = [
    {
      title: "",
      key: "checkbox",
      sorter: false,
      width: 10,
      render: (data) => (
        <Checkbox
          value={data?.id}
          onChange={onChange}
          disabled={
            data.isBorrowingReturned ||
            data.status === "RETURNED" ||
            data.status === "WAITING_APPROVAL_RENEWAL" ||
            data.status === "WAITING_APPROVAL_RETURN"
          }
        />
      ),
    },
    {
      title: "Nama Asset",
      dataIndex: "asset",
      key: "name",
      width: 200,
      sorter: (a, b) => a.asset?.name.localeCompare(b.asset?.name),
      render: (data) => (
        <Link to={`/daftar-asset/detail/${data?.id}`}>{data?.name}</Link>
      ),
    },
    // {
    //   title: "Unit From",
    //   dataIndex: "unitFrom",
    //   key: "unit",
    //   width: 150,
    //   sorter: (a, b) => a.unitFrom?.name.localeCompare(b.unitFrom?.name),
    //   render: (data) => data?.name,
    // },
    // {
    //   title: "Unit To",
    //   dataIndex: "unitTo",
    //   key: "unit",
    //   width: 150,
    //   sorter: (a, b) => a.unitTo?.name.localeCompare(b.unitTo?.name),
    //   render: (data) => data?.name,
    // },
    {
      title: "Fixed Asset Number",
      // dataIndex: "asset",
      // key: "fixed_asset_number",
      width: 100,
      sorter: (a, b) =>
        a.asset?.fixed_asset_number.localeCompare(b.asset?.fixed_asset_number),
      render: (data) => data?.asset?.fixed_asset_number,
    },
    {
      title: "Serial Number",
      dataIndex: "asset",
      key: "serial_number",
      width: 100,
      sorter: (a, b) =>
        a.asset?.serialNumber.localeCompare(b.asset?.serialNumber),
      render: (data) => data?.serialNumber,
    },
    {
      title: "Location From",
      dataIndex: "roomFrom",
      key: "location_from",
      width: 200,
      sorter: (a, b) =>
        a.asset?.roomFrom?.name.localeCompare(b.asset?.roomFrom?.name),
      render: (data) =>
        `${data?.name} - ${data?.floor?.name} - ${data?.floor?.building?.name}`,
    },
    {
      title: "Location To",
      dataIndex: "room",
      key: "location_to",
      width: 200,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (data) =>
        `${data?.name} - ${data?.floor?.name} - ${data?.floor?.building?.name}`,
    },
    {
      title: "Rack ID",
      dataIndex: "rackId",
      key: "rack_id",
      width: 100,
      sorter: (a, b) => a.rack_id.localeCompare(b.rack_id),
    },
    {
      title: "Location U",
      dataIndex: "locationU",
      key: "location_u",
      width: 100,
      sorter: (a, b) => a.location_u.localeCompare(b.location_u),
    },
    {
      title: "Remark",
      dataIndex: "description",
      key: "remark",
      width: 150,
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (data) => {
        let newStatus = "";
        if (
          data === "WAITING_APPROVAL_RENEWAL" ||
          data === "WAITING_APPROVAL_RETURN"
        ) {
          newStatus = "WAITING_APPROVAL";
        } else if (data === "RENEWAL") {
          newStatus = "APPROVED";
        }
        return (
          <Tag
            style={{
              borderRadius: "20px",
              minWidth: "120px",
              textAlign: "center",
            }}
            color={statusApproval(newStatus)}
          >
            {data}
          </Tag>
        );
      },
    },
    {
      title: "Borrowing Renewal Date",
      dataIndex: "borrowingRenewalDate",
      key: "borrowingRenewalDate",
      width: 150,
      sorter: (a, b) =>
        a.borrowingRenewalDate.localeCompare(b.borrowingRenewalDate),
      render: (data) => data && moment(data).format("LL"),
    },
    {
      title: "Borrowing Return Date",
      dataIndex: "borrowingReturnDate",
      key: "borrowingReturnDate",
      width: 150,
      sorter: (a, b) =>
        a.borrowingReturnDate.localeCompare(b.borrowingReturnDate),
      render: (data) => data && moment(data).format("LL"),
    },
    {
      key: "action",
      width: 10,
      render: (newData) => actionList(newData),
    },
  ];

  const columnsHistory = [
    {
      title: "Tanggal",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 250,
      render: (data) => {
        return data && moment(data).format("LL HH:mm");
      },
    },
    {
      title: "Urutan",
      dataIndex: "sequenceNumber",
      key: "sequenceNumber",
      sorter: (a, b) => a.sequenceNumber - b.sequenceNumber,
      defaultSortOrder: "ascend",
    },
    {
      title: "Role",
      dataIndex: "role_id",
      key: "role_id",
      width: 400,
    },
    {
      title: "Approval Status",
      dataIndex: "approval_st",
      key: "approval_st",
      width: 250,
      render: (data) => statusList(data),
    },
    {
      title: "Catatan",
      dataIndex: "description",
      key: "notes",
      width: 250,
    },
  ];

  const handleDelete = async (asset_id, id) => {
    const newData = await deleteDocumentMovement(asset_id, id);
    if (newData?.error === false || !newData.error) {
      notification.success({
        message: "Success",
        description: "Delete Document Moevement Success",
      });
      getData();
    }
  };

  const expandedRowRender = (row) => {

    return (
      <>
        <Table
          rowKey="id"
          columns={childrenColumns}
          dataSource={row.details}
          pagination={false}
        />
        <div style={{ marginTop: "20px" }}>
          <div>Dokumen: </div>
          <Space size={"large"} wrap={true} direction="vertical">
            {row?.details &&
              row?.details.length > 0 &&
              row?.details[0]?.documents?.map((item, index) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <a
                      key={index}
                      rel="noreferrer"
                      target="_blank"
                      href={process.env.REACT_APP_IMAGE_URL + "/" + item}
                    >
                      <Button icon={<DownloadOutlined />}>Download</Button>
                    </a>
                    <div style={{ marginLeft: "10px" }}>{item}</div>
                  </div>
                  {localStorage.getItem("role_id") === "SUPERADMIN" && (
                    <div style={{ marginLeft: "50px" }}>
                      <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() =>
                          handleDelete(row?.details[0]?.id, item)
                        }
                      >
                        <DeleteOutlined
                          style={{ color: "#bd3f39", fontSize: "24px" }}
                        />
                      </Popconfirm>
                    </div>
                  )}
                </div>
              ))}
          </Space>
        </div>
      </>
    );
  };

  const actionList = (data) => {
    return (
      <Dropdown overlay={menuAction(data)} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <EllipsisOutlined rotate={90} />
        </a>
      </Dropdown>
    );
  };

  const menuAction = (data) => {
    return (
      <Menu
        items={[
          {
            label: (
              <Link to={"/daftar-asset/detail/" + data?.asset?.id}>
                Lihat Detail
              </Link>
            ),
          },
        ]}
      />
    );
  };

  const renewalAsset = () => {
    setModal({
      open: true,
      loading: false,
      title: "Renewal Asset",
      body: (
        <RenewalAsset form={formModal} return_date={data?.return_date_origin} />
      ),
      onOk: async () => {
        try {
          const values = await formModal.validateFields();
          if (values) {
            const res = await renewalAction(id, {
              asset_movement_details: selectedRowKeys,
              new_return_date: moment(values?.new_return_date).format(
                "YYYY-MM-DD"
              ),
            });
            if (res?.error === false || !res?.error) {
              notification.success({
                message: "Success",
                description: "Renewal Asset Success",
              });
              setModal({
                open: false,
                loading: false,
                title: "",
                body: "",
                onOk: () => {},
              });
              setSelectedRowKeys([]);
              setTotal(0);
              getData();
            }
          }
        } catch (errInfo) {}
      },
    });
  };

  const returnAsset = () => {
    setModal({
      open: true,
      loading: false,
      title: "Pengembalian Asset",
      body: "Apakah Anda yakin asset sudah dikembalikan?",
      onOk: async () => {
        try {
          const values = await formModal.validateFields();
          if (values) {
            const res = await returnAction(id, {
              asset_movement_details: selectedRowKeys,
            });
            if (res?.error === false || !res?.error) {
              notification.success({
                message: "Success",
                description: "Asset Returned Success",
              });
              setModal({
                open: false,
                loading: false,
                title: "",
                body: "",
                onOk: () => {},
              });
              setSelectedRowKeys([]);
              setTotal(0);
              getData();
            }
          }
        } catch (errInfo) {}
      },
    });
  };

  const getData = async () => {
    setLoading(true);
    const newData = await getAssetMovement(id);
    if (newData) {
      newData.data.return_date_origin = newData?.data?.returnDate;
      setData(newData.data);

      const newField = newData.data;
      newField.type = newField?.type + " " + newField?.deliveredType;
      newField.transaction_date =
        (newField?.transactionDate &&
          moment(newField?.transactionDate).format("LL")) ||
        "-";
      newField.return_date =
        (newField?.returnDate && moment(newField?.returnDate).format("LL")) ||
        "-";
      newField.deliveredTo = newField?.deliveredToUser?.name;
      form.setFieldsValue(newField);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <AdminLayout>
      <Card
        title={"Detail Borrowing Monitoring"}
        className={styles.cardTable}
        extra={
          <Button
            icon={<ArrowLeftOutlined />}
            type="primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        }
      >
        <Row gutter={10} align={"middle"}>
          <Col span={24} lg={12}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Movement Type</Text>
              </Col>
              <Col span={16}>: {data?.type}</Col>
            </Row>
          </Col>
          <Col span={24} lg={12}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Unit</Text>
              </Col>
              <Col span={16}>: {data?.created_by?.unit?.name}</Col>
            </Row>
          </Col>
          <Col span={24} lg={12}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Transaction Date</Text>
              </Col>
              <Col span={16}>: {data?.transaction_date}</Col>
            </Row>
          </Col>
          <Col span={24} lg={12}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Status</Text>
              </Col>
              <Col span={16}>
                :{" "}
                <Tag
                  style={{
                    borderRadius: "20px",
                    minWidth: "120px",
                    textAlign: "center",
                  }}
                  color={statusApproval(data?.status)}
                >
                  {data?.status}
                </Tag>
              </Col>
            </Row>
          </Col>
          <Col span={24} lg={12}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Start Date</Text>
              </Col>
              <Col span={16}>
                :{" "}
                {data?.assetMovementApprovals?.length > 0 &&
                  moment(
                    data?.assetMovementApprovals[
                      data?.assetMovementApprovals.length - 1
                    ]?.created_at
                  ).format("LL")}
              </Col>
            </Row>
          </Col>
          {data.deliveredType === "USER" && (
            <Col span={24} lg={12}>
              <Row gutter={10} align={"middle"}>
                <Col span={8}>
                  <Text>Delivered To</Text>
                </Col>
                <Col span={16}>: {data?.deliveredTo}</Col>
              </Row>
            </Col>
          )}
          <Col span={24} lg={12}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Return Date</Text>
              </Col>
              <Col span={16}>: {data?.return_date}</Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Row gutter={20}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card className={styles.cardTable} style={{ marginTop: "20px" }}>
            <Space size={"large"} wrap={true} style={{ marginBottom: "5px" }}>
              {localStorage
                .getItem("downstream_unit_id")
                .split(",")
                .includes(String(data?.deliveredToUser?.unit?.id)) &&
                (localStorage.getItem("role_id") === "USER" ||
                  localStorage.getItem("role_id") === "ADMIN_GA") && (
                  <>
                    <div className={styles.buttonGroupWarning}>
                      <Button
                        className={styles.buttonWarning}
                        disabled={total <= 0}
                        onClick={renewalAsset}
                      >
                        Renewal
                      </Button>
                    </div>

                    <div className={styles.buttonGroupDanger}>
                      <Button
                        className={styles.buttonDanger}
                        disabled={total <= 0}
                        onClick={returnAsset}
                      >
                        Return
                      </Button>
                    </div>
                  </>
                )}

              <Text>{total > 0 ? `Selected ${total} items` : ""}</Text>
            </Space>
            <div
              style={{
                overflowX: "auto",
                maxWidth: "100%",
                minWidth: "1800px",
              }}
            >
              <Table
                columns={columns}
                dataSource={data?.assetMovementDetails}
                rowKey="poNumber"
                loading={loading}
                expandedRowRender={expandedRowRender}
                expandIcon={({ expanded, onExpand, record }) =>
                  expanded ? (
                    <Button
                      type="primary"
                      onClick={(e) => onExpand(record, e)}
                      icon={<EyeInvisibleOutlined />}
                    >
                      Sembunyikan Detail
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      onClick={(e) => onExpand(record, e)}
                      icon={<EyeOutlined />}
                    >
                      Tampilkan Detail
                    </Button>
                  )
                }
                columnWidth={100}
                pagination
              />
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card
            title={"Riwayat Approval Borrowing Monitoring"}
            className={styles.cardTable}
            style={{ marginTop: "50px" }}
          >
            <Row gutter={40}>
              <Col className={styles.detailRow} span={24}>
                <Table
                  columns={columnsHistory}
                  dataSource={
                    data?.assetMovementApprovals?.length > 0 &&
                    data?.assetMovementApprovals[
                      data?.assetMovementApprovals.length - 1
                    ]?.asset_movement_approval_details
                  }
                  rowKey={"id"}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        title={modal.title}
        open={modal.open}
        onOk={modal.onOk}
        loading={modal.loading}
        okText={modal.okText}
        onCancel={() => {
          formModal.resetFields();
          setModal({
            open: false,
            loading: false,
            title: "",
            body: "",
            okText: "",
            onOk: () => {},
          });
        }}
        body={modal.body}
      />
    </AdminLayout>
  );
}

export default DetailAssetMovement;
