//Registered Asset
export const GET_ALL_ASSET = "GET_ALL_ASSET"
export const GET_DETAIL_ASSET = "GET_DETAIL_ASSET"
export const UPDATE_STATUS = "UPDATE_STATUS"
export const APPROVAL = "APPROVAL"
export const DELETE_ASSET = "DELETE_ASSET"
export const CANCEL_UPDATE_ASSET = "CANCEL_UPDATE_ASSET"

//Stock Opname
export const GET_STOCK_OPNAME = "GET_STOCK_OPNAME"
export const GET_DETAIL_STOCK_OPNAME = "GET_DETAIL_STOCK_OPNAME"
export const GET_LIST_STOCK_OPNAME = "GET_LIST_STOCK_OPNAME"
export const START_STOCK_OPNAME = "START_STOCK_OPNAME"
export const EXTEND_STOCK_OPNAME= "EXTEND_STOCK_OPNAME"
export const APPROVE_STOCK_OPNAME = "APPROVE_STOCK_OPNAME"
export const REJECT_STOCK_OPNAME = "REJECT_STOCK_OPNAME"
export const RESUBMIT_STOCK_OPNAME = "RESUBMIT_STOCK_OPNAME"
export const CHECKED_OPNAME = "CHECKED_OPNAME"
export const REJECT_OPNAME = "REJECT_OPNAME"

//Label Printing
export const GET_LABEL_PRINTING = "GET_LABEL_PRINTING"
export const GET_DETAIL_LABEL_PRINTING = "GET_DETAIL_LABEL_PRINTING"
export const ADD_LABEL_PRINTING = "ADD_LABEL_PRINTING"
export const GENERATE_LABEL_PRINTING = "GENERATE_LABEL_PRINTING"

//Master
export const GET_ALL_BUILDING = "GET_ALL_BUILDING"

//Asset Movement
export const GET_ALL_ASSET_MOVEMENT = "GET_ALL_ASSET_MOVEMENT"
export const ADD_ASSET_MOVEMENT = "ADD_ASSET_MOVEMENT"

//UPLOAD
export const UPLOAD = "UPLOAD"

//Notifications
export const NOTIFICATIONS = "NOTIFICATIONS"