import {
  Image,
  Typography,
  Form,
  Input,
  Button,
  notification,
} from "antd";
import React, { useContext } from "react";
import AuthLayout from "../../Layouts/Auth/AuthLayout";
import AuthContext from "../../Services/Auth";
import styles from "./Login.module.css";
import Logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import ApiWithAuth from "../../Config/Api/ApiWithAuth";

const { Title, Text } = Typography;

function Login() {
  let navigate = useNavigate();
  const [form] = Form.useForm();

  const { loading, login } = useContext(AuthContext);

  const onFinish = async (values) => {
    let response = await login(values.username, values.password);
    if (response.error === false) {
      setTimeout(() => {
        ApiWithAuth.setToken(response?.data?.acces_token);
        navigate("/");
      }, 500);
    }
  };

  return (
    <AuthLayout>
      <div className={styles.top}>
        <Image src={Logo} preview={false} />
        <Title level={4} className={styles.title}>
          Tools Asset Management
        </Title>
        <Text type="secondary">Silahkan Masukan Username dan Password</Text>
      </div>
      <div className={styles.bottom}>
        <Form
          form={form}
          layout="vertical"
          name="control-hooks"
          // autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            label="USERNAME"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
            disabled={loading}
          >
            <Input placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            label="PASSWORD"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
            disabled={loading}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button
              className={styles.button}
              type="primary"
              htmlType="submit"
              disabled={loading}
            >
              Log In
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthLayout>
  );
}

export default Login;
