import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Image,
  Input,
  Menu,
  notification,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "./StockOpname.module.css";
import { EllipsisOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import AssetContext from "../../Services/Asset";
import moment from "moment";
import "moment/locale/id";
import Modal from "../../Components/Global/Modal";
// import { DownloadTableExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
moment.locale("id");

const { Text } = Typography;

const assetList = (data) => {
  return (
    <Space>
      {/* <Image height={60} src={SampleImage} /> */}
      <Space size={0} direction="vertical">
        <Text>{data?.asset?.name}</Text>
        <Text type="secondary">{data?.asset?.serialNumber}</Text>
      </Space>
    </Space>
  );
};

const locationList = (data) => {
  return (
    <Space size={0} direction="vertical">
      <Text>
        {data?.asset?.room?.name} - {data?.asset?.room?.floor?.name} -{" "}
        {data?.asset?.room?.floor?.building?.name}
      </Text>
      <Text type="secondary">{data.created_at}</Text>
    </Space>
  );
};

const statusApproval = (data) => {
  if (data === "WAITING_APPROVAL") {
    return (
      <Tag
        style={{ borderRadius: "20px", minWidth: "120px", textAlign: "center" }}
        color="#FEC400"
      >
        WAITING APPROVAL
      </Tag>
    );
  } else if (data === "APPROVED" || data === "APPROVE") {
    return (
      <Tag
        style={{ borderRadius: "20px", minWidth: "120px", textAlign: "center" }}
        color="#18B84E"
      >
        APPROVED
      </Tag>
    );
  } else if (data === "REJECTED" || data === "REJECT") {
    return (
      <Tag
        style={{ borderRadius: "20px", minWidth: "120px", textAlign: "center" }}
        color="#EB4034"
      >
        REJECTED
      </Tag>
    );
  } else {
    return (
      <Tag
        style={{ borderRadius: "20px", minWidth: "120px", textAlign: "center" }}
      >
        -
      </Tag>
    );
  }
};

const statusAsset = (data) => {
  if (data === "BELUM_OPNAME") {
    return "#FEC400";
  } else if (data === "SUDAH_OPNAME") {
    return "#18B84E";
  } else if (data === "ASSET_TIDAK_DITEMUKAN") {
    return "#b81818";
  }
};

const statusDitolak = (data) => {
  if (data) {
    return (
      <Tag
        style={{ borderRadius: "20px", minWidth: "120px", textAlign: "center" }}
        color="#C20808"
      >
        DITOLAK
      </Tag>
    );
  }
};

const actionList = (data) => {
  return (
    <Dropdown overlay={menuAction(data)} trigger={["click"]}>
      <a onClick={(e) => e.preventDefault()}>
        <EllipsisOutlined rotate={90} />
      </a>
    </Dropdown>
  );
};

const menuAction = (data) => {
  const menu_asset = {
    label: (
      <Link to={"/daftar-asset/detail/" + data.id}>Lihat Detail Asset</Link>
    ),
    key: "1",
  };
  const menu_opname_asset = {
    label: (
      <Link to={"/stock-opname/stock-opname/detail-opname/" + data.id}>
        Lihat Detail Stock Opname Asset
      </Link>
    ),
    key: "2",
  };
  // const menu_opname = {
  //   label: (
  //     <Link to={"/stock-opname/detail-opname/" + data.id}>
  //       Lihat Detail Opname
  //     </Link>
  //   ),
  //   key: "1",
  // };

  return <Menu items={[menu_opname_asset]} />;

  // if (data.status === "BELUM OPNAME") {
  //   return <Menu items={[menu_asset]} />;
  // } else {
  //   return <Menu items={[menu_asset, menu_opname]} />;
  // }
};

function DetailStockOpname(props) {
  let { id } = useParams();
  let navigate = useNavigate();
  const [data, setData] = useState({});
  const [detail, setDetail] = useState({});
  const [searchText, setSearchText] = useState("");
  const [viewRequest, setViewRequest] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modalData, setModalData] = useState({
    id: "",
    date: "",
    reason: "",
  });
  const {
    getStockOpname,
    getDetailStockOpname,
    approveStockOpname,
    rejectOpname,
    checkedOpname,
    extendStockOpnameUnit,
  } = useContext(AssetContext);
  const [modal, setModal] = useState({
    title: "",
    open: false,
    loading: false,
    onOk: () => {},
    body: "",
  });
  const tableRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  const columns = [
    {
      title: "Asset",
      key: "asset",
      width: 400,
      sorter: (a, b) => a.asset?.name.localeCompare(b.asset?.name),
      render: (data) => assetList(data),
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(record?.asset?.name)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.asset?.serialNumber)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.asset?.fixed_asset_number)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.asset?.room?.name)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.asset?.room?.floor?.name)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.asset?.room?.floor?.building?.name)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase())
        );
      },
    },
    {
      title: "Fixed Asset Number",
      key: "no_fixed_asset",
      width: 250,
      sorter: (a, b) =>
        (a.asset?.fixed_asset_number ? a.asset?.fixed_asset_number : "").localeCompare(
          b.asset?.fixed_asset_number ? b.asset?.fixed_asset_number : ""
        ),
      render: (data) => data.asset?.fixed_asset_number,
    },
    {
      title: "Reject",
      key: "reject",
      width: 200,
      sorter: false,
      render: (data) => {
        return (
          data.is_rejected_flag === false &&
          detail.should_do_approve === true &&
          detail?.status !== "APPROVED" &&
          data?.status !== "BELUM_OPNAME" && (
            <div className={styles.buttonCardDanger}>
              <Button
                size="large"
                type="default"
                className={styles.buttonDanger}
                style={{ width: "100%", minWidth: "unset" }}
                onClick={() => showModalReject(data.id)}
              >
                Reject
              </Button>
            </div>
          )
        );
      },
    },
    // {
    //   title: "Location",
    //   key: "location",
    //   width: 250,
    //   sorter: (a, b) => a.asset?.room.name.localeCompare(b.asset?.room.name),
    //   render: (data) => locationList(data),
    // },
    // {
    //   title: "Deskripsi",
    //   dataIndex: "description",
    //   key: "deskripsi",
    //   width: 250,
    //   sorter: (a, b) => String(a.description).localeCompare(String(b.description)),
    // },
    {
      title: "Status",
      key: "status",
      width: 200,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (data) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Tag
              style={{
                borderRadius: "20px",
                minWidth: "120px",
                textAlign: "center",
              }}
              color={statusAsset(data?.status)}
            >
              {data?.status}
            </Tag>
            <Tag
              style={{
                borderRadius: "20px",
                minWidth: "120px",
                textAlign: "center",
                marginTop: "5px",
              }}
              color={statusAsset(
                data?.is_checked_flag ? "SUDAH_OPNAME" : "BELUM_OPNAME"
              )}
            >
              {data?.is_checked_flag ? "SUDAH DITANDAI" : "BELUM DITANDAI"}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Status Reject",
      dataIndex: "is_rejected_flag",
      key: "status_reject",
      width: 200,
      sorter: (a, b) => a.is_rejected_flag - b.is_rejected_flag,
      render: (data) => statusDitolak(data),
    },
    {
      title: "Catatan Reject",
      dataIndex: "notes",
      key: "catatan",
      width: 250,
      sorter: (a, b) => String(a.notes).localeCompare(String(b.notes)),
    },
    {
      key: "action",
      width: 10,
      render: (data) => actionList(data),
    },
  ];

  const columnsMonitoring = [
    {
      title: "Role",
      dataIndex: "role_id",
      key: "role_id",
      width: 200,
      sorter: (a, b) => a.role_id.localeCompare(b.role_id),
    },
    // {
    //   title: "Description (Reason)",
    //   dataIndex: "description",
    //   key: "description",
    //   width: 400,
    //   sorter: (a, b) => a.description&&a.description.localeCompare(b.description),
    // },
    {
      title: "Status",
      dataIndex: "approval_st",
      key: "approval_st",
      width: 200,
      sorter: (a, b) => a.approval_st.localeCompare(b.approval_st),
      render: (data) => statusApproval(data),
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    getCheckboxProps: (record) => ({
      disabled: record.is_checked_flag,
      is_checked_flag: record.is_checked_flag,
    }),
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const showModalApprove = () => {
    setModal({
      title: "Approve Stock Opname",
      open: true,
      onOk: async () => {
        setModal((prev) => ({
          ...prev,
          loading: true,
        }));
        const res = await approveStockOpname({
          stock_opname_unit_id: detail?.id,
        });
        if (res?.error === false) {
          setModal((prev) => ({
            ...prev,
            loading: false,
          }));
          notification.success({
            message: "Success",
            description: "Stock Opname Approved",
          });
          getData();
        } else {
          setModal((prev) => ({
            ...prev,
            loading: false,
          }));
        }
      },
      body: "Apakah Anda yakin menyetujui stok opname?",
    });
  };

  const showModalApproveDetail = () => {
    setModal({
      title: "Tandai Sudah Dicek",
      open: true,
      onOk: async () => {
        setModal((prev) => ({
          ...prev,
          loading: true,
        }));
        const res = await checkedOpname(data.id, {
          stock_opname_asset_ids: selectedRowKeys,
        });
        if (res?.data?.error === false || !res?.data?.error) {
          setModal((prev) => ({
            ...prev,
            open: false,
            loading: false,
          }));
          notification.success({
            message: "Success",
            description: "Stock Opname Checked",
          });
          setSelectedRowKeys([]);
          getData();
        } else {
          setModal((prev) => ({
            ...prev,
            loading: false,
          }));
        }
      },
      body: `Apakah Anda yakin menandai ${
        hasSelected ? `${selectedRowKeys.length} asset` : ""
      } sudah dicek?`,
    });
  };

  const showModalReject = (detail_opname_id) => {
    setModal({
      title: "Reject Stock Opname",
      open: true,
      onOk: handleReject,
      body: (
        <>
          <Text>Apakah Anda yakin menolak stok opname?</Text>
          <Input
            onKeyUp={(evt) => {
              setModalData({
                reason: evt.target.value,
                id: detail_opname_id,
              });
            }}
            placeholder="Masukkan alasan"
          />
        </>
      ),
    });
  };

  const handleReject = async () => {
    if (modalData.reason) {
      setModal((prev) => ({
        ...prev,
        loading: true,
      }));
      const res = await rejectOpname(modalData.id, {
        notes: modalData.reason,
      });
      if (res?.error === false) {
        setModal((prev) => ({
          ...prev,
          loading: false,
          open: false,
        }));
        notification.success({
          message: "Success",
          description: "Stock Rejected",
        });
        getData();
      } else {
        setModal((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    } else {
      notification.error({
        message: "Error",
        description: "Reason Description is required",
      });
    }
  };

  const showModalOpname = (type) => {
    setModal({
      title: "Request Stock Opname Extend",
      open: true,
      onOk: handleExtend,
      body: (
        <DatePicker
          placeholder="End Date"
          onChange={(date) =>
            setModalData({
              ...modalData,
              date: date.format("YYYY-MM-DD"),
            })
          }
          disabledDate={(current) => current < moment().subtract(1, "days")}
          style={{ width: "100%" }}
        />
      ),
    });
  };

  const handleExtend = async () => {
    if (modalData.date) {
      setModal((prev) => ({
        ...prev,
        loading: true,
      }));
      const res = await extendStockOpnameUnit(detail?.id, {
        extend_date_request: modalData.date,
      });
      if (res?.data?.error === false || !res?.data?.error) {
        notification.success({
          message: "Success",
          description: "Request Extend Stock Opname Success",
        });
        setModal((prev) => ({
          ...prev,
          open: false,
          loading: false,
        }));
        getData();
      } else {
        setModal((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    } else {
      notification.error({
        message: "Error",
        description: "Date is required",
      });
    }
  };

  useEffect(() => {
    if (modalData.date) {
      setModal((prev) => ({
        ...prev,
        onOk: handleExtend,
      }));
    }
  }, [modalData]);

  const getData = async () => {
    const newData = await getStockOpname();
    if (newData) {
      setData(newData.data);
      const newDetail = await getDetailStockOpname(id);
      if (newDetail) {
        setDetail(newDetail.data);
        if (
          localStorage
            .getItem("downstream_unit_id")
            .split(",")
            .includes(String(id)) &&
          localStorage.getItem("role_id") === "USER" &&
          !newDetail.data?.is_head_approved
        ) {
          setViewRequest(true);
        }
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (modalData.reason && modalData.id) {
      setModal((prev) => ({
        ...prev,
        onOk: handleReject,
      }));
    }
  }, [modalData]);

  return (
    <AdminLayout>
      <Card
        title={"Stock Opname Summary"}
        className={styles.cardTable}
        extra={
          <Button
            icon={<ArrowLeftOutlined />}
            type="primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        }
      >
        <Row gutter={10} align={"middle"}>
          <Col xs={24} sm={18} md={18} lg={16}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Unit</Text>
              </Col>
              <Col span={16}>: {detail?.unit}</Col>
            </Row>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Mulai</Text>
              </Col>
              <Col span={16}>
                :{" "}
                {data?.stock_opname_data?.opened_at &&
                  moment(data?.stock_opname_data?.opened_at).format("LLL")}
              </Col>
            </Row>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Selesai</Text>
              </Col>
              <Col span={16}>
                :{" "}
                {data?.stock_opname_data?.closed_at &&
                  moment(data?.stock_opname_data?.closed_at).format("LLL")}
              </Col>
            </Row>
            {(localStorage.getItem("role_id") === "ADMIN_GA" ||
              localStorage.getItem("role_id") === "SUPERADMIN") && (
              <Row gutter={10} align={"middle"}>
                <Col span={8}>
                  <Text>Total Asset</Text>
                </Col>
                <Col span={16}>: {detail?.total_assets}</Col>
              </Row>
            )}
            {(localStorage.getItem("role_id") === "ADMIN_GA" ||
              localStorage.getItem("role_id") === "SUPERADMIN") && (
              <Row gutter={10} align={"middle"}>
                <Col span={8}>
                  <Text>Total Asset Opname</Text>
                </Col>
                <Col span={16}>: {detail?.current_asset_opname}</Col>
              </Row>
            )}
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Status</Text>
              </Col>
              <Col span={16}>: {statusApproval(detail?.status)}</Col>
            </Row>
          </Col>
          <Col xs={24} sm={6} md={6} lg={8}>
            <Space direction="vertical">
              {detail.should_do_approve === true && (
                <>
                  <div className={styles.buttonCardSuccess}>
                    <Button
                      size="large"
                      type="default"
                      className={styles.buttonSuccess}
                      onClick={showModalApprove}
                    >
                      Approve
                    </Button>
                  </div>
                  {/* <div className={styles.buttonCardDanger}>
                    <Button
                      size="large"
                      type="default"
                      
                      className={styles.buttonDanger}
                      onClick={showModalReject}
                    >
                      Reject
                    </Button>
                  </div> */}
                </>
              )}
              {viewRequest && (
                <div className={styles.buttonCardWarning}>
                  <Button
                    size="large"
                    type="default"
                    className={styles.buttonWarning}
                    onClick={() => showModalOpname()}
                  >
                    Request Extend Stock Opname
                  </Button>
                </div>
              )}
            </Space>
          </Col>
        </Row>
      </Card>
      <Card
        title={"Data Asset"}
        className={styles.cardTable}
        extra={
          <Button
            type="primary"
            onClick={showModalApproveDetail}
            disabled={!hasSelected}
          >
            Tandai Sudah Dicek
          </Button>
        }
      >
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
          span={24}
          xl={18}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "10px" }}>Cari</div>
            <Input
              onChange={(text) => {
                setSearchText(text?.target?.value);
              }}
              placeholder="Cari..."
              style={{ maxWidth: "300px", marginRight: "30px" }}
            />
          </div>
          {/* <DownloadTableExcel
            filename={`Daftar Asset ${moment().unix()}`}
            currentTableRef={tableRef.current}
          >
            <Button type="primary" style={{ marginRight: "10px" }}>
              Export Excel
            </Button>
          </DownloadTableExcel>
          <Button onClick={handlePrint} type="primary" danger>
            Print PDF
          </Button> */}
        </div>
        <Table
          ref={tableRef}
          columns={columns}
          dataSource={detail?.list_assets}
          rowKey="id"
          pagination
          rowSelection={rowSelection}
          // expandable={{
          //   expandedRowRender: (record) => (
          //     <Row gutter={10} align={"middle"}>
          //       <Col span={24} lg={10}>
          //         <Row gutter={10} align={"middle"}>
          //           <Col span={8}>
          //             <Text>Nama Asset</Text>
          //           </Col>
          //           <Col span={16}>: {record?.asset?.name}</Col>
          //         </Row>
          //         <Row gutter={10} align={"middle"}>
          //           <Col span={8}>
          //             <Text>Tanggal Opname</Text>
          //           </Col>
          //           <Col span={16}>
          //             :{" "}
          //             {(record?.created_at &&
          //               moment(record?.created_at).format("LL")) ||
          //               "-"}
          //           </Col>
          //         </Row>
          //         <Row gutter={10} align={"middle"}>
          //           <Col span={8}>
          //             <Text>Serial Number</Text>
          //           </Col>
          //           <Col span={16}>: {record?.asset?.serialNumber}</Col>
          //         </Row>
          //       </Col>
          //       <Col span={24} lg={10}>
          //         <Row gutter={10} align={"middle"}>
          //           <Col span={8}>
          //             <Text>Fixed Asset Number</Text>
          //           </Col>
          //           <Col span={16}>: {record?.asset?.fixed_asset_number}</Col>
          //         </Row>
          //         <Row gutter={10} align={"middle"}>
          //           <Col span={8}>
          //             <Text>Unit Kerja</Text>
          //           </Col>
          //           <Col span={16}>: {record?.asset?.unit?.name}</Col>
          //         </Row>
          //         <Row gutter={10} align={"middle"}>
          //           <Col span={8}>
          //             <Text>Lokasi</Text>
          //           </Col>
          //           <Col span={16}>
          //             : {record?.asset?.room?.name} -{" "}
          //             {record?.asset?.room?.floor?.name} -{" "}
          //             {record?.asset?.room?.floor?.building?.name}
          //           </Col>
          //         </Row>
          //       </Col>
          //       <Col span={24} lg={4}>
          //         {record.is_rejected_flag === false &&
          //           detail.should_do_approve === true &&
          //           detail?.status !== "APPROVED" &&
          //           record?.status !== "BELUM_OPNAME" && (
          //             <div className={styles.buttonCardDanger}>
          //               <Button
          //                 size="large"
          //                 type="default"
          //                 className={styles.buttonDanger}
          //                 style={{ width: "100%", minWidth: "unset" }}
          //                 onClick={() => showModalReject(record.id)}
          //               >
          //                 Reject
          //               </Button>
          //             </div>
          //           )}
          //       </Col>
          //       <Col span={24} lg={24}>
          //         <Row gutter={10} align={"top"}>
          //           <Col span={8}>
          //             <Text>Foto Opname</Text>
          //           </Col>
          //           <Col span={16}>
          //             <Image.PreviewGroup>
          //               <Space size={"large"} wrap={true}>
          //                 {record?.stock_opname_asset_files &&
          //                   record?.stock_opname_asset_files.map((item) => (
          //                     <Image
          //                       width={200}
          //                       src={
          //                         process.env.REACT_APP_IMAGE_URL +
          //                         "/" +
          //                         item.path
          //                       }
          //                       style={{
          //                         marginTop: "20px",
          //                         maxHeight: "200px",
          //                         objectFit: "cover",
          //                       }}
          //                     />
          //                   ))}
          //               </Space>
          //             </Image.PreviewGroup>
          //           </Col>
          //         </Row>
          //       </Col>
          //     </Row>
          //   ),
          // }}
        />
      </Card>
      <Card title={"Approval Monitoring"} className={styles.cardTable}>
        <Table
          columns={columnsMonitoring}
          dataSource={detail?.list_approvals}
          rowKey="id"
          pagination
        />
      </Card>

      <Modal
        title={modal.title}
        open={modal.open}
        onOk={modal.onOk}
        loading={modal.loading}
        onCancel={() => {
          setModal({
            open: false,
            loading: false,
            title: "",
            body: "",
            onOk: () => {},
          });
          setModalData({
            id: "",
            date: "",
            reason: "",
          });
        }}
        body={modal.body}
      />
    </AdminLayout>
  );
}

export default DetailStockOpname;
