import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  message,
  notification,
  Row,
  Space,
  Typography,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "../../Components/Global/Global.module.css";
import AssetContext from "../../Services/Asset";

const Text = Typography;

function Profile() {
  const { getProfile, updateSignature, upload } = useContext(AssetContext);
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState("false");

  const getData = async () => {
    const id = localStorage.getItem("user_id");
    const newData = await getProfile(id);
    if (newData) {
      setData(newData.data);
      const newField = newData.data;
      newField.position = newField?.position?.name;
      newField.role = newField?.role?.name + " - " + newField?.role?.level;
      newField.unit = newField?.unit?.name;
      form.setFieldsValue(newField);
    }
  };

  const input = {
    beforeUpload: async (file) => {
      setLoading(true);
      const fileTypeAllowed = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      const isJpgOrPng = fileTypeAllowed.includes(file.type);
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
        return isJpgOrPng;
      }
      let file_size = 16;
      const isLt2M = file.size / 1024 / 1024 < file_size;
      if (!isLt2M) {
        message.error("Image must smaller than " + file_size + "MB!");
        return isLt2M;
      }

      const res = await upload(file, "profile");
      if (res) {
        const id = localStorage.getItem("user_id");
        const res2 = await updateSignature(id, {
          signature: res.data && res.data[0],
        });
        if (res2?.error === false || !res2?.error) {
          notification.success({
            message: "Success",
            description: "Update Signature Success",
          });
          getData();
        }
      }
      setLoading(false);

      return false;
    },
    multiple: false,
    showUploadList: false,
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <AdminLayout>
      <Card className={styles.cardTable}>
        <Form form={form}>
          <Row gutter={40}>
            <Col className={styles.detailRow} span={24}>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Name</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="name">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col className={styles.detailRow} span={24}>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Username / Email</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="username">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col className={styles.detailRow} span={24}>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>NIP</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="nip">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col className={styles.detailRow} span={24}>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Position</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="position">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col className={styles.detailRow} span={24}>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Role User</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="role">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col className={styles.detailRow} span={24}>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Unit</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="unit">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col className={styles.detailRow} span={24}>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Signature</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Space>
                    {data?.signature && (
                      <Image
                        width={200}
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/" +
                          data?.signature
                        }
                        loading={loading}
                      />
                    )}
                    <Upload {...input}>
                      <Button icon={<UploadOutlined />}>
                        {data?.signature
                          ? "Re-Upload Signature"
                          : "Upload Signature"}
                      </Button>
                    </Upload>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
    </AdminLayout>
  );
}

export default Profile;
