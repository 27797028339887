import { Button, Space } from "antd";
import { Link } from "react-router-dom";
import styles from "../Global.module.css";

const ButtonMovementDisposal = (props) => {

  return (
    <Space>
      <Link
        to={`/asset-movement/add/${props.id}`}
        className={styles.buttonCardWarning}
      >
        <Button className={styles.buttonWarning}>Add to Movement</Button>
      </Link>
      <Link
        to={`/disposal/add/${props.id}`}
        className={styles.buttonCardDanger}
      >
        <Button className={styles.buttonDanger}>Add to Disposal</Button>
      </Link>
    </Space>
  );
};

export default ButtonMovementDisposal;
