import { notification } from "antd";
import React, { useReducer } from "react";
import ApiWithAuth from "../../Config/Api/ApiWithAuth";
import AssetContext from "./index";
import AssetReducer from "./reducer";
import { GET_ALL,
  GET,
  ADD,
  UPDATE,
  DELETE } from "./types";

const MasterState = ({ children }) => {

  const initialState = {
    data: [],
    loading: true,
  };

  const [state, dispatch] = useReducer(AssetReducer, initialState);

  const getAllMaster = async (path,parameter) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth(`/${path}`, parameter)
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_ALL, payload: data?.data?.data});
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getMaster = async (path,id) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth(`/${path}/${id}`)
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET, payload: data?.data?.data});
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const addMaster = async (path,body) => {
    try {
        const data = await ApiWithAuth.postDataWithAuth(`/${path}`, JSON.stringify(body))
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: ADD });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const updateMaster = async (path,id, body) => {
    try {
        const data = await ApiWithAuth.putDataWithAuth(`/${path}/${id}`, JSON.stringify(body))
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: UPDATE });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const deleteMaster = async (path,id) => {
    try {
        const data = await ApiWithAuth.deleteDataWithAuth(`/${path}/${id}`)
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: DELETE });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const { data, loading } = state;

  return (
    <AssetContext.Provider
      value={{
        data,
        loading,
        getAllMaster,
        getMaster,
        addMaster,
        updateMaster,
        deleteMaster,
      }}
    >
      {children}
    </AssetContext.Provider>
  );
};

export default MasterState;
