import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  notification,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
  Upload,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "./Disposal.module.css";
import { EllipsisOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import AssetContext from "../../Services/Asset";
import MasterContext from "../../Services/Master";
import moment from "moment";
import Modal from "../../Components/Global/Modal";
import { UploadOutlined } from "@ant-design/icons";
import UploadDokumen from "./component/uploadDokumen";
moment.locale("id");

const { Text } = Typography;

const statusList = (data) => {
  if (data === "APPROVE") {
    return "#3AD647";
  } else if (data === "REJECTED") {
    return "#FA3737";
  } else if (data === "WAITING_APPROVAL") {
    return "#FFCD27";
  } else {
    return "#DED8D8";
  }
};

function Disposal(props) {
  const [dataUnit, setDataUnit] = useState([]);
  const { getAllDisposal, approvalMovement, downloadReportAssetMovement } =
    useContext(AssetContext);
  const { getAllMaster } = useContext(MasterContext);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [params, setParams] = useState({
    status: "",
    unit_id: "",
  });
  const [modalData, setModalData] = useState({
    reason: "",
    id: "",
    disposal_type: "",
  });
  const [modal, setModal] = useState({
    title: "",
    open: false,
    loading: false,
    onOk: () => {},
    body: "",
  });
  const [modalUploadDokumen, setModalUploadDokumen] = useState({
    open: false,
    id: "",
  });
  let navigate = useNavigate();
  const [form] = Form.useForm();

  const getApprovalText = (data) => {
    let approvalText = "";
    let checkApproval = true;

    if (
      data.status === "REJECTED" &&
      localStorage.getItem("user_id") === String(data?.created_by?.id)
    ) {
      approvalText = "Resubmit available";
      return approvalText;
    }

    const roleId = localStorage.getItem("role_id");
    const unitKey = localStorage.getItem("unit_key");

    if (roleId === "HEAD_DEPT" || roleId === "VP_DEPT") {
      if (data.statusApproval !== `${roleId}_${unitKey}`) {
        checkApproval = false;
      }
    } else {
      if (data.statusApproval !== roleId) {
        checkApproval = false;
      }
    }

    if (checkApproval) {
      approvalText = "Approve and Reject available";
    }
    return approvalText;
  };

  const getDataUnit = async () => {
    const newData = await getAllMaster("units", { migrated_asset: false });
    if (newData) {
      const newDataUnit = [
        {
          value: "",
          label: "SEMUA UNIT",
        },
      ];
      if (newData.data) {
        const downstreamUnitIds = localStorage.downstream_unit_id
          .split(",")
          .map((id) => parseInt(id));
        // if (
        // localStorage.getItem("role_id") === "ADMIN_GA" ||
        // (localStorage.getItem("role_id") === "VP_GA" &&
        //   (props.type === "daftar-asset-perusahaan" ||
        //     props.type === "registered-asset")) ||
        // localStorage.getItem("role_id") === "HEAD_GA" ||
        // localStorage.getItem("role_id") === "OFFICE_VENDOR_MANAGEMENT" ||
        // localStorage.getItem("role_id") === "DEPT_ACC_TAX" ||
        // (localStorage.getItem("role_id") === "VP_ACC_TAX" &&
        //   (props.type === "daftar-asset-perusahaan" ||
        //     props.type === "registered-asset")) ||
        // localStorage.getItem("role_id") === "SUPERADMIN"
        // ) {
        const newDataUnits = newData.data.map((unit) => ({
          value: unit.id,
          label: unit.name,
        }));
        setDataUnit(newDataUnit.concat(newDataUnits));
        // } else {
        //   const newDataUnits = newData.data
        //     .filter((unit) => downstreamUnitIds.includes(unit.id))
        //     .map((unit) => ({ value: unit.id, label: unit.name }));
        //   setDataUnit(newDataUnit.concat(newDataUnits));
        // }
      }
    }
  };
  const columns = [
    {
      title: "Transaction Date",
      key: "transaction_date",
      width: 200,
      sorter: (a, b) =>
        moment(a.transactionDate).unix() - moment(b.transactionDate).unix(),
      render: (data) =>
        (
          <Link to={`/disposal/detail/${data.id}`}>
            {data?.transactionDate &&
              moment(data?.transactionDate).format("LL")}
          </Link>
        ) || "-",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(moment(record?.transactionDate).format("LL"))
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(moment(record?.returnDate).format("LL"))
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.created_by?.unit?.name)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.approval)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.statusDisposal)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(getApprovalText(record))
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.type)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.status)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase())
        );
      },
    },
    {
      title: "Unit",
      dataIndex: "created_by",
      key: "unit",
      width: 100,
      sorter: (a, b) =>
        a.created_by?.unit?.name.localeCompare(b.created_by?.unit?.name),
      render: (data) => data?.unit?.name,
    },
    {
      title: "Approval",
      key: "approval",
      width: 250,
      sorter: (a, b) => {
        const getOrderValue = (val) => {
          if (
            val.status === "REJECTED" &&
            localStorage.getItem("user_id") === String(val?.created_by?.id)
          ) {
            return 2;
          } else if (
            (localStorage.getItem("role_id") === "HEAD_DEPT" ||
              localStorage.getItem("role_id") === "DEPT_ACC_TAX" ||
              localStorage.getItem("role_id") === "HEAD_IT_SECURITY" ||
              localStorage.getItem("role_id") === "OFFICE_VENDOR_MANAGEMENT" ||
              localStorage.getItem("role_id") === "VP_ACC_TAX" ||
              localStorage.getItem("role_id") === "VP_IT_SECURITY" ||
              localStorage.getItem("role_id") === "VP_GA" ||
              localStorage.getItem("role_id") === "VP_DEPT") &&
            (val.statusApproval ===
              localStorage.getItem("role_id") +
                "_" +
                localStorage.getItem("unit_key") ||
              val.statusApproval === localStorage.getItem("role_id"))
          ) {
            return 1;
          } else {
            return 3;
          }
        };

        return getOrderValue(b) - getOrderValue(a);
      },
      align: "center",
      render: (data) => approval(data),
    },
    {
      title: "Dispose",
      key: "dispose",
      width: 250,
      sorter: (a, b) => {
        if (localStorage.getItem("role_id") === "ADMIN_GA") {
          const getOrderValue = (item) => {
            if (item.statusDisposal === "DISPOSE") {
              return 1;
            } else {
              return 2;
            }
          };
          return getOrderValue(b) - getOrderValue(a);
        }
      },
      align: "center",
      render: (data) => dispose(data),
    },
    {
      title: "Status Disposal",
      key: "statusDisposal",
      width: 100,
      sorter: (a, b) => {
        const getOrderValue = (item) => {
          if (item.statusDisposal === "DISPOSE") {
            return 2;
          } else if (item.statusDisposal === "IDLE") {
            return 3;
          } else if (item.statusDisposal === null) {
            return 1;
          }
        };
        return getOrderValue(a) - getOrderValue(b);
      },

      render: (data) => (
        <Tag
          style={{
            borderRadius: "20px",
            minWidth: "120px",
            textAlign: "center",
          }}
          color={statusList(
            data.statusDisposal
              ? data.statusDisposal === "DISPOSE"
                ? "REJECTED"
                : data.statusDisposal === "IDLE"
                ? "WAITING_APPROVAL"
                : "DISPOSE"
              : ""
          )}
        >
          {data.statusDisposal ? data.statusDisposal : "-"}
        </Tag>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      sorter: (a, b) => {
        const getOrderValue = (item) => {
          if (item.status === "APPROVE") {
            return 2;
          } else if (item.status === "DISPOSE") {
            return 3;
          } else if (item.status === "REJECTED") {
            return 4;
          } else if (item.status === "WAITING_APPROVAL") {
            return 5;
          } else if (item.status === null) {
            return 1;
          }
        };
        return getOrderValue(a) - getOrderValue(b);
      },
      render: (data) => (
        <Tag
          style={{
            borderRadius: "20px",
            minWidth: "120px",
            textAlign: "center",
          }}
          color={statusList(data)}
        >
          {data}
        </Tag>
      ),
    },
    {
      key: "action",
      width: 10,
      render: (data) => actionList(data),
    },
  ];

  const actionList = (data) => {
    return (
      <Dropdown overlay={menuAction(data)} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <EllipsisOutlined rotate={90} />
        </a>
      </Dropdown>
    );
  };

  const menuAction = (data) => {
    let newItem = [
      {
        label: <Link to={"/disposal/detail/" + data.id}>Lihat Detail</Link>,
        key: "1",
      },
    ];

    const cetak = {
      label: <div onClick={() => downloadReport(data.id)}>Cetak</div>,
      key: "2",
    };

    const unitId = localStorage.getItem("downstream_unit_id").split(",");
    const unitRelated = unitId.includes(String(data?.created_by?.unitId));
    const headUnit =
      "HEAD_DEPT" === localStorage.getItem("role_id") &&
      unitId.includes(String(data?.created_by?.unitId));
    const superAdmin = "SUPERADMIN" === localStorage.getItem("role_id");
    const adminGA = "ADMIN_GA" === localStorage.getItem("role_id");
    const approvalFirst =
      data?.assetMovementApprovals &&
      data?.assetMovementApprovals[data?.assetMovementApprovals.length - 1]
        ?.asset_movement_approval_details;
    const checkApprovalFirst =
      approvalFirst?.length > 0 &&
      (approvalFirst[0].approval_st === "APPROVE" ? false : true);

    newItem.push(cetak);
    
    if (
      superAdmin ||
      ((headUnit ||
        localStorage.getItem("role_id") === "DEPT_ACC_TAX" ||
        localStorage.getItem("role_id") === "HEAD_IT_SECURITY" ||
        localStorage.getItem("role_id") === "OFFICE_VENDOR_MANAGEMENT") &&
        data.statusApproval !== "REJECTED")
    ) {
      newItem.push({
        label: <Link to={"/disposal/edit/" + data.id}>Edit</Link>,
        key: "2",
      });
    }

    return <Menu items={newItem} />;
  };

  const downloadReport = async (id) => {
    const newData = await downloadReportAssetMovement(id);
  };

  const approval = (data) => {
    let checkApproval = true;
    if (
      data.status === "REJECTED" &&
      localStorage.getItem("user_id") === String(data?.created_by?.id)
    ) {
      return (
        <Link to={`/disposal/edit/${data.id}/resubmit`}>
          <div className={styles.buttonCardWarning}>
            <Button
              size="large"
              type="default"
              className={styles.buttonWarning}
              onClick={() => showModalReject(data.id)}
            >
              Resubmit
            </Button>
          </div>
        </Link>
      );
    }

    if (
      localStorage.getItem("role_id") === "HEAD_DEPT" ||
      localStorage.getItem("role_id") === "VP_DEPT"
    ) {
      if (
        data.statusApproval !==
        localStorage.getItem("role_id") + "_" + localStorage.getItem("unit_key")
      ) {
        checkApproval = false;
      }
    } else {
      if (data.statusApproval !== localStorage.getItem("role_id")) {
        checkApproval = false;
      }
    }

    if (checkApproval) {
      return (
        <Space>
          <div className={styles.buttonCardSuccess}>
            <Button
              size="large"
              type="default"
              className={styles.buttonSuccess}
              onClick={() => showModalApprove(data.id)}
            >
              Approve
            </Button>
          </div>
          <div className={styles.buttonCardDanger}>
            <Button
              size="large"
              type="default"
              className={styles.buttonDanger}
              onClick={() => showModalReject(data.id)}
            >
              Reject
            </Button>
          </div>
        </Space>
      );
    }
  };

  const dispose = (data) => {
    if (
      data?.statusDisposal === "DISPOSE" &&
      (localStorage.getItem("role_id") === "ADMIN_GA" ||
        localStorage.getItem("role_id") === "SUPERADMIN") &&
      !data?.disposalType
    ) {
      return (
        <div className={styles.buttonCardDanger}>
          <Button
            size="large"
            type="default"
            className={styles.buttonDanger}
            onClick={() =>
              setModalUploadDokumen({
                open: true,
                id: data.id,
              })
            }
          >
            Dispose
          </Button>
        </div>
      );
    }
  };

  const showModalApprove = (id) => {
    setModal({
      title: "Approve Asset",
      open: true,
      onOk: async () => {
        setModal((prev) => ({
          ...prev,
          loading: true,
        }));
        const res = await approvalMovement(
          id,
          {
            type: "APPROVE",
          },
          "disposal"
        );
        if (res?.data?.error === false || !res?.data?.error) {
          setModal((prev) => ({
            ...prev,
            open: false,
            loading: false,
          }));
          notification.success({
            message: "Success",
            description: "Asset Approved",
          });
          getData();
        } else {
          setModal((prev) => ({
            ...prev,
            loading: false,
          }));
        }
      },
      body: "Apakah Anda yakin menyetujui disposal asset?",
    });
  };

  const showModalReject = (id) => {
    setModalData({ ...modalData, id: id });
    setModal({
      title: "Reject Asset",
      open: true,
      onOk: handleReject,
      body: (
        <>
          <Text>Apakah Anda yakin menolak disposal asset?</Text>
          <Input
            onKeyUp={(evt) => {
              setModalData((prev) => ({
                ...prev,
                reason: evt.target.value,
              }));
            }}
            placeholder="Masukkan alasan"
          />
        </>
      ),
    });
  };

  const handleReject = async () => {
    if (modalData.reason) {
      setModal((prev) => ({
        ...prev,
        loading: true,
      }));
      const res = await approvalMovement(
        modalData.id,
        {
          type: "REJECT",
          remarks: modalData.reason,
        },
        "disposal"
      );
      if (res?.data?.error === false || !res?.data?.error) {
        setModal((prev) => ({
          ...prev,
          open: false,
          loading: false,
        }));
        notification.success({
          message: "Success",
          description: "Asset Rejected",
        });
        getData();
      } else {
        setModal((prev) => ({
          ...prev,
          open: false,
          loading: false,
        }));
      }
    } else {
      notification.error({
        message: "Error",
        description: "Reason Description is required",
      });
    }
  };

  const getData = async () => {
    const newData = await getAllDisposal(params);
    if (newData) {
      setData(newData.data);
    }
  };

  useEffect(() => {
    getData();
    getDataUnit();
  }, []);

  useEffect(() => {
    getData();
  }, [params]);

  useEffect(() => {
    setModal((prev) => ({
      ...prev,
      onOk: handleReject,
    }));
  }, [modalData]);

  return (
    <AdminLayout>
      <Card className={styles.cardTable}>
        <Row gutter={16}>
          <Col
            style={{ margin: "auto 0", display: "flex", alignItems: "center" }}
            span={24}
            xl={12}
          >
            <div
              style={{
                margin: "auto 0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ marginRight: "10px" }}>Cari</div>
              <Input
                onChange={(text) => {
                  setSearchText(text?.target?.value);
                }}
                placeholder="Cari..."
                style={{ maxWidth: "300px", marginRight: "30px" }}
              />
            </div>
          </Col>
          <Col span={24} xl={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              {localStorage.getItem("position_id") === "3" && (
                <div className={styles.buttonCardTable}>
                  <Button
                    size="large"
                    type="default"
                    className={styles.buttonSuccess}
                    onClick={() => navigate("add")}
                  >
                    Disposal
                  </Button>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "10px" }}>Filter:</div>
            <Select
              showSearch
              placeholder="Select Unit"
              style={{ marginRight: "10px", minWidth: "200px" }}
              options={dataUnit}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(evt) =>
                setParams((params) => ({ ...params, unit_id: evt }))
              }
            />
            <Select
              showSearch
              placeholder="Select Status Disposal"
              style={{ marginRight: "10px", minWidth: "200px" }}
              options={[
                {
                  value: "",
                  label: "SEMUA STATUS",
                },
                {
                  value: "DISPOSE",
                  label: "DISPOSE",
                },
                {
                  value: "IDLE",
                  label: "IDLE",
                },

                {
                  value: "null",
                  label: "BLANK",
                },
              ]}
              onChange={(evt) =>
                setParams((params) => ({ ...params, status_disposal: evt }))
              }
            />
            <Select
              showSearch
              placeholder="Select Status"
              style={{ marginRight: "10px", minWidth: "200px" }}
              options={[
                {
                  value: "",
                  label: "SEMUA STATUS",
                },
                {
                  value: "WAITING_APPROVAL",
                  label: "WAITING APPROVAL",
                },
                {
                  value: "APPROVE",
                  label: "APPROVE",
                },
                {
                  value: "REJECTED",
                  label: "REJECTED",
                },
              ]}
              onChange={(evt) =>
                setParams((params) => ({ ...params, status: evt }))
              }
            />
          </div>
        </Row>
        <Table columns={columns} dataSource={data} rowKey="id" pagination />
      </Card>

      <Modal
        title={modal.title}
        open={modal.open}
        onOk={modal.onOk}
        loading={modal.loading}
        okText={modal.okText}
        onCancel={() => {
          form.resetFields();
          setModal({
            open: false,
            loading: false,
            title: "",
            body: "",
            okText: "",
            onOk: () => {},
          });
        }}
        body={modal.body}
      />
      <UploadDokumen
        open={modalUploadDokumen}
        setOpen={setModalUploadDokumen}
        getData={getData}
      />
    </AdminLayout>
  );
}

export default Disposal;
