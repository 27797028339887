import React, { useState } from "react";
import { Col, Modal, notification, Row } from "antd";

function ModalApprove(props) {
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    setLoading(true);
    const res = await props.action(props.id, {
      type: "APPROVE",
    });
    if (res?.data?.error === false || !res?.data?.error) {
      setLoading(false);
      notification.success({
        message: "Success",
        description: `${props.type} Approved`,
      });
      props.getData();
      props.setOpen(false);
    } else {
      setLoading(false);
    }
  }

  return (
    <Modal
      title={`Approve ${props.type}`}
      open={props?.open}
      onOk={submit}
      confirmLoading={loading}
      onCancel={() => {
        setLoading(false);
        props.setOpen(false);
      }}
      okText={"Approve"}
    >
      <Row gutter={[0, 15]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          Apakah Anda yakin menyetujui {props.type}?
        </Col>
      </Row>
    </Modal>
  );
}

export default ModalApprove;
