import { notification } from "antd";
import React, { useReducer } from "react";
import ApiWithoutAuth from "../../Config/Api/ApiWithoutAuth";
import AuthContext from "./index";
import AuthReducer from "./reducer";
import { LOGIN, LOGOUT, GET_USER_DATA } from "./types";

const AuthState = ({ children }) => {
  const initialState = {
    loading: false,
    role: "",
    unit: "",
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const login = async (email, password) => {
    try {
      const body = {
        email: email,
        password: password,
      };
      const data = await ApiWithoutAuth.postDataWithoutAuth(
        "/authenticate",
        JSON.stringify(body)
      );
      if (data?.data?.error === false) {
        localStorage.setItem("token", data?.data?.data?.acces_token);
        localStorage.setItem("user_id", data?.data?.data?.user?.id);
        localStorage.setItem("name", data?.data?.data?.user?.name);
        localStorage.setItem("role", data?.data?.data?.user?.role?.name);
        localStorage.setItem("role_id", data?.data?.data?.user?.role?.id);
        localStorage.setItem("unit_id", data?.data?.data?.user?.unit?.id);
        localStorage.setItem("unit_key", data?.data?.data?.user?.unit?.key);
        localStorage.setItem("downstream_unit_id", data?.data?.data?.user?.downstream_unit_id);
        localStorage.setItem(
          "position_id",
          data?.data?.data?.user?.position_id
        );
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      dispatch({ type: LOGIN, payload: data });
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const logout = async () => {
    try {
      dispatch({ type: LOGOUT });
      localStorage.clear();
    } catch (err) {
      console.error(err.message);
    }
  };

  const getUser = async () => {
    try {
      const data = await ApiWithoutAuth.getDataWithoutAuth(
        "/tokens/user/" + localStorage.getItem("user_id")
      );
      localStorage.setItem("user", data?.data?.data);
      dispatch({ type: GET_USER_DATA });
    } catch (err) {
      console.error(err.message);
    }
  };

  const { loading, role, unit } = state;

  return (
    <AuthContext.Provider
      value={{
        loading,
        role,
        unit,
        login,
        logout,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
