import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useState, useEffect, useContext } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "./Disposal.module.css";
import AssetContext from "../../Services/Asset";
import MasterContext from "../../Services/Master";
import TableMovement from "./component/editTable";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

const { Title } = Typography;

function EditDisposal(props) {
  let { id, type } = useParams();
  const {
    getAllDaftarAsset,
    updateAssetMovement,
    resubmitAssetMovement,
    getAssetMovement,
  } = useContext(AssetContext);
  const { getAllMaster } = useContext(MasterContext);
  // const [dataAsset, setDataAsset] = useState([]);
  const [dataLocation, setDataLocation] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [dataRoom, setDataRoom] = useState([]);
  const [form] = Form.useForm();
  let navigate = useNavigate();

  const onFinish = async (values) => {
    const isDataIncomplete = dataSource.some((data) => {
      const room = dataRoom.find((room) => room.roomName === data.location_to);
      if (room && room.hasRack && (!data.location_u || !data.rack_id)) {
        notification.warning({
          message: "Peringatan",
          description:
            "Asset " + data.name + " harus memiliki location U dan rack ID.",
          duration: 3,
        });
        return true;
      }
      return false;
    });

    if (isDataIncomplete) {
      return;
    }
    try {
      if (dataSource.length > 0) {
        const newDataSource = dataSource.map((item) => ({
          asset_id: item.asset_id,
          location_u: item.location_u,
          rack_id: item.rack_id,
          room_id: item.room_id,
          description: item?.remark,
        }));
        const sendedData = {
          transaction_date: values.transaction_date && values.transaction_date,
          asset_movement_details: newDataSource,
        };
        let res =
          type === "resubmit"
            ? await resubmitAssetMovement(id, sendedData)
            : updateAssetMovement(id, sendedData);
        if (res?.error === false || !res?.error) {
          notification.success({
            message: "Success",
            description: "Add Disposal Success",
          });
          navigate("/disposal");
        }
      } else {
        notification.error({
          message: "Error",
          description: "Data Asset is required",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const getAsset = async () => {
  //   const newData = await getAllDaftarAsset({
  //     status: "ACTIVE, IDLE, NON_ACTIVE, TIDAK_DITEMUKAN",
  //   });
  //   if (newData) {
  //     const newDataAsset =
  //       newData.data &&
  //       newData.data.map((item) => ({
  //         value: item.id,
  //         label: item.name + " - " + item.serialNumber,
  //       }));
  //     setDataAsset(newDataAsset);
  //   }
  // };

  const getLocation = async () => {
    const newData = await getAllMaster("rooms");
    if (newData) {
      const newDataLocation =
        newData.data &&
        newData.data.map((item) => ({
          value: item?.id,
          label:
            item?.name +
            " " +
            item?.floor?.name +
            " " +
            item?.floor?.building?.name,
        }));
      setDataLocation(newDataLocation);

      setDataRoom(
        newData.data &&
          newData.data.map((item) => ({
            roomName:
              item?.name +
              " " +
              item?.floor?.name +
              " " +
              item?.floor?.building?.name,
            hasRack: item.has_rack,
          }))
      );
    }
  };

  const getDetailMovement = async () => {
    const newData = await getAssetMovement(id);
    if (newData) {
      var newArr = [];
      for (var i = 0; i < newData.data?.assetMovementDetails.length; i++) {
        newArr = newArr.concat(newData.data?.assetMovementDetails[i].details);
      }
      const newArray =
        newArr &&
        newArr.map((item, index) => {
          return {
            key: index,
            asset_id: item?.assetId,
            fixed_asset_number: item?.asset?.fixed_asset_number,
            name: item?.asset?.name,
            serial_number: item?.asset?.serialNumber,
            location_from:
              (item?.asset?.room?.name || "") +
              " " +
              (item?.asset?.room?.floor?.name || "") +
              " " +
              (item?.asset?.room?.floor?.building?.name || ""),
            rack_id_from: item?.asset?.rack_id,
            location_u_from: item?.asset?.location_u,
            room_id: item?.roomId,
            location_to:
              (item?.room?.name || "") +
              " " +
              (item?.room?.floor?.name || "") +
              " " +
              (item?.room?.floor?.building?.name || ""),
            rack_id: item?.rackId,
            location_u: item?.locationU,
            remark: item?.description,
          };
        });
      setDataSource(newArray);
      const newField = newData.data;
      newField.transaction_date =
        (newField?.transactionDate &&
          moment(newField?.transactionDate).format("YYYY-MM-DD")) ||
        "-";
      form.setFieldsValue(newField);
    }
  };

  useEffect(() => {
    getDetailMovement();
    // getAsset();
    getLocation();
  }, []);

  return (
    <AdminLayout>
      <Card className={styles.cardTable}>
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          <Title level={5}>Asset Disposal Form</Title>
          <Button
            icon={<ArrowLeftOutlined />}
            type="primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Space>
      </Card>
      <Form
        form={form}
        // layout="vertical"
        name="control-hooks"
        autoComplete="off"
        onFinish={onFinish}
      >
        <Card className={styles.cardBottom}>
          <Row gutter={20} className={styles.secondStep}>
            <Col xs={24} sm={24} md={8} lg={8}>
              <Form.Item
                name="transaction_date"
                label="Transaction Date"
                rules={[
                  {
                    required: true,
                    message: "Please select transaction date!",
                  },
                ]}
                initialValue={moment().format("YYYY-MM-DD")}
              >
                <Input readOnly placeholder="Transaction Date" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24}>
              <TableMovement
                dataSource={dataSource}
                setDataSource={setDataSource}
                // dataAsset={dataAsset}
                dataLocation={dataLocation}
                type={"disposal"}
              />
            </Col>
            <Divider />
            <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </AdminLayout>
  );
}

export default EditDisposal;
