import React, { useContext, useEffect, useState } from "react";
import { Button, Col, message, Modal, notification, Row, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import AssetContext from "../../Services/Asset";

function KonfirmasiDiterima(props) {
  const { upload, confirmDelivered } = useContext(AssetContext);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleUpload = async () => {
    setLoading(true);
    if (fileList.length <= 0) {
      notification.error({
        message: "Error",
        description: "Tidak ada data yg diupload!",
      });
    } else {
      const res = await upload(fileList, "movement");
      if (res.data?.length > 0) {
        const res2 = await confirmDelivered(props.open?.id, {
          documents: res.data,
        });
        if (res2?.error === false || !res2?.error) {
          notification.success({
            message: "Success",
            description: "Upload Success",
          });
          props.setOpen({ open: false, id: "" });
          props?.getData();
        }
        setFileList([]);
      }
    }
    setLoading(false);
  };

  const input = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const fileTypeAllowed = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      // const isJpgOrPng = fileTypeAllowed.includes(file.type);
      // if (!isJpgOrPng) {
      //   message.error("You can only upload JPG/PNG/PDF/DOC/DOCX file!");
      //   return isJpgOrPng;
      // }
      let file_size = 16;
      const isLt2M = file.size / 1024 / 1024 < file_size;
      if (!isLt2M) {
        message.error("Image must smaller than " + file_size + "MB!");
        return isLt2M;
      }
      setFileList((data) => [...data, file]);
      return false;
    },
    fileList,
    multiple: true,
  };

  return (
    <Modal
      title={"Upload Dokumen"}
      open={props?.open?.open}
      onOk={handleUpload}
      confirmLoading={loading}
      onCancel={() => {
        setLoading(false);
        setFileList([]);
        props.setOpen({ open: false, id: "" });
      }}
      okText={"Simpan"}
    >
      <Row gutter={[0, 15]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Upload {...input}>
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Col>
      </Row>
    </Modal>
  );
}

export default KonfirmasiDiterima;
