import { Button, Card, Col, Row, Table, Tag, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "../../Components/Global/Global.module.css";
import AssetContext from "../../Services/Asset";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import StatusApprovalList from "../../Components/Global/View/StatusApprovalList";
moment.locale("id");

const { Text } = Typography;

function DetailHistoryAsset(props) {
  let { id, idIndex } = useParams();
  let navigate = useNavigate();
  const { getDetailAsset } = useContext(AssetContext);
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState({});

  const columnsHistory = [
    {
      title: "Tanggal",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 250,
      render: (data) => {
        return data && moment(data).format("LL HH:mm");
      },
    },
    {
      title: "Urutan",
      dataIndex: "sequenceNumber",
      key: "sequenceNumber",
      sorter: (a, b) => a.sequenceNumber - b.sequenceNumber,
      defaultSortOrder: "ascend",
    },
    {
      title: "Role",
      dataIndex: "role_id",
      key: "role_id",
      width: 400,
    },
    {
      title: "Approval Status",
      dataIndex: "approval_st",
      key: "approval_st",
      width: 250,
      render: (data) => (
        <Tag
          style={{
            borderRadius: "20px",
            minWidth: "120px",
            textAlign: "center",
          }}
          color={StatusApprovalList(data)}
        >
          {data}
        </Tag>
      ),
    },
    {
      title: "Catatan",
      dataIndex: "description",
      key: "notes",
      width: 250,
    },
  ];

  const getData = async () => {
    const newData = await getDetailAsset(id);
    if (newData) {
      if (newData?.data?.approvals?.length > 0) {
        const newIndex = newData?.data?.approvals.findIndex(function (data) {
          return String(data.id) === idIndex;
        });

        if (newIndex !== -1) {
          setData(newData?.data?.approvals[newIndex]);
        }
      }
      setDetail(newData?.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  console.log(detail);

  return (
    <AdminLayout>
      <Card
        title={"Detail Riwayat Asset"}
        className={styles.cardTable}
        extra={
          <Button
            icon={<ArrowLeftOutlined />}
            type="primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        }
      >
        <Row gutter={40} align={"middle"}>
          <Col span={24} lg={8}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Asset Name</Text>
              </Col>
              <Col span={16}>: {detail?.name}</Col>
            </Row>
          </Col>
          <Col span={24} lg={8}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Type</Text>
              </Col>
              <Col span={16}>: {data?.type}</Col>
            </Row>
          </Col>
          <Col span={24} lg={8}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Status</Text>
              </Col>
              <Col span={16}>
                :{" "}
                <Tag
                  style={{
                    borderRadius: "20px",
                    minWidth: "120px",
                    textAlign: "center",
                  }}
                  color={StatusApprovalList(
                    !data?.is_done_flag && !data?.is_rejected_flag
                      ? "WAITING_APPROVAL"
                      : data?.is_done_flag && !data?.is_rejected_flag
                      ? "APPROVE"
                      : "REJECTED"
                  )}
                >
                  {!data?.is_done_flag && !data?.is_rejected_flag
                    ? "Proses"
                    : data?.is_done_flag && !data?.is_rejected_flag
                    ? "Full Approve"
                    : "Reject"}
                </Tag>
              </Col>
            </Row>
          </Col>
          <Col span={24} lg={8}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Description</Text>
              </Col>
              <Col span={16}>:</Col>
            </Row>
          </Col>
          <Col span={24} lg={24}>
            <Row gutter={10} align={"middle"}>
              <Col span={24}>
                <div>
                  <table style={{ width: "95%", marginLeft: "5%" }}>
                    <tbody>
                      {data?.description &&
                        Object.keys(JSON.parse(data?.description))
                          .filter(
                            (key) =>
                              key.toLowerCase() !== "floorname" &&
                              key.toLowerCase() !== "floorid" &&
                              key.toLowerCase() !== "roomid" &&
                              key.toLowerCase() !== "buildingid" &&
                              key.toLowerCase() !== "buildingname"
                          )
                          .sort()
                          .map((item, idx) => {
                            const value = JSON.parse(data?.description)[item];

                            const isTimestamp = (value) => {
                              return (
                                typeof value === "number" &&
                                new Date(value).getTime() > 0 &&
                                value.toString().length >= 10
                              );
                            };

                            const formatTimestampToDate = (timestamp) => {
                              const date = new Date(timestamp);
                              const year = date.getFullYear();
                              const month = String(
                                date.getMonth() + 1
                              ).padStart(2, "0");
                              const day = String(date.getDate()).padStart(
                                2,
                                "0"
                              );
                              return `${year}-${month}-${day}`;
                            };

                            return (
                              <tr key={idx}>
                                <th style={{ textAlign: "left", width: "25%" }}>
                                  {keyToTitle(item)}
                                </th>
                                <td style={{ width: "75%" }}>
                                  {": " +
                                    (isTimestamp(value)
                                      ? formatTimestampToDate(value)
                                      : value)}
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card
            title={"Riwayat Approval"}
            className={styles.cardTable}
            style={{ marginTop: "50px" }}
          >
            <Row gutter={40}>
              <Col className={styles.detailRow} span={24}>
                <Table
                  columns={columnsHistory}
                  dataSource={data?.asset_approval_details}
                  rowKey={"id"}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </AdminLayout>
  );
}

const keyToTitle = (key) => {
  var x = key.replace(/([A-Z])/g, " $1");
  return x.charAt(0).toUpperCase() + x.slice(1);
};

export default DetailHistoryAsset;
