import React from "react";
import { Space, Typography } from "antd";
import { Link } from "react-router-dom";

const { Text } = Typography;

const AssetList = (data, type) => {
  return (
    <Link to={`/${type}/detail/${data.id}`}>
      <Space>
        <Space size={0} direction="vertical">
          <Text>{data.name}</Text>
          <Text type="secondary">{data.serialNumber}</Text>
        </Space>
      </Space>
    </Link>
  );
};

export default AssetList;
