import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  notification,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "./AssetMovement.module.css";
import { EllipsisOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import AssetContext from "../../Services/Asset";
import MasterContext from "../../Services/Master";
import moment from "moment";
import Modal from "../../Components/Global/Modal";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import ExcelJS from "exceljs";
import { type } from "@testing-library/user-event/dist/type";
import KonfirmasiDiterima from "./KonfirmasiDiterima";
import ModalDelete from "./ModalDelete";
import jsPDF from "jspdf";
import "jspdf-autotable";
moment.locale("id");

const { Text } = Typography;

const statusList = (data) => {
  if (data === "APPROVE") {
    return "#3AD647";
  } else if (data === "REJECTED") {
    return "#FA3737";
  } else if (data === "WAITING_APPROVAL") {
    return "#FFCD27";
  } else {
    return "#DED8D8";
  }
};

function AssetMovement(props) {
  const {
    getAllAssetMovement,
    approvalMovement,
    downloadReportAssetMovement,
    deleteAssetMovement,
    rejectConfirmDelivered,
  } = useContext(AssetContext);
  const { getAllMaster } = useContext(MasterContext);
  const [data, setData] = useState([]);
  const [dataRoom, setDataRoom] = useState([]);
  const [dataUnit, setDataUnit] = useState([]);
  const [rejectType, setRejectType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [params, setParams] = useState({
    status: "",
    room_id: "",
    unit_id: "",
  });
  const [searchText, setSearchText] = useState("");
  const [modalUploadDokumen, setModalUploadDokumen] = useState({
    open: false,
    id: "",
  });
  const [modalData, setModalData] = useState({
    reason: "",
    id: "",
  });
  const [modal, setModal] = useState({
    title: "",
    open: false,
    loading: false,
    onOk: () => {},
    body: "",
  });
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const tableRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  const columns = [
    {
      title: "Transaction Date",
      key: "transaction_date",
      width: 200,
      sorter: (a, b) =>
        moment(a.transactionDate).unix() - moment(b.transactionDate).unix(),
      render: (data) =>
        (
          <Link to={`/asset-movement/detail/${data.id}`}>
            {data?.transactionDate &&
              moment(data?.transactionDate).format("LL")}
          </Link>
        ) || "-",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(moment(record?.transactionDate).format("LL"))
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.created_by?.unit?.name)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.deliveredToUser?.unit?.name)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.deliveredType)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(
            record?.type +
              " " +
              (record?.type === "MUTATION"
                ? record?.deliveredType
                : record?.type === "BORROWING" &&
                  record?.deliveredType !== "USER"
                ? record?.deliveredType
                : "")
          )
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(approvalStatus(record))
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.status)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(
            data?.type === "MUTATION" && data?.deliveredType === "LOCATION"
              ? record?.status
              : record?.alreadyDelivered
              ? "SUDAH DITERIMA"
              : "BELUM DITERIMA"
          )
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase())
        );
      },
    },
    {
      title: "Unit From",
      dataIndex: "created_by",
      key: "unit",
      width: 100,
      sorter: (a, b) =>
        a.created_by?.unit?.name.localeCompare(b.created_by?.unit?.name),
      render: (data) => data?.unit?.name,
    },
    {
      title: "Unit To",
      dataIndex: "deliveredToUser",
      key: "to",
      width: 100,
      sorter: (a, b) =>
        a.deliveredToUser?.unit?.name.localeCompare(
          b.deliveredToUser?.unit?.name
        ),
      render: (data) => data?.unit?.name,
    },
    {
      title: "Movement Type",
      key: "type",
      width: 100,
      sorter: (a, b) => {
        const getOrderValue = (val) => {
          const rett =
            val.type === "MUTATION"
              ? val.deliveredType
              : val.type === "BORROWING" && val.deliveredType !== "USER"
              ? val.deliveredType
              : "";
          const output_data = val.type + " " + rett;
          if (output_data === "BORROWING RENEWAL") {
            return 2;
          } else if (output_data === "BORROWING RETURN") {
            return 3;
          } else if (output_data === "BORROWING ") {
            return 1;
          } else if (output_data === "MUTATION LOCATION") {
            return 5;
          } else if (output_data === "MUTATION USER") {
            return 6;
          } else if (output_data === "REPLACE ") {
            return 7;
          }
        };
        return getOrderValue(a) - getOrderValue(b);
      },
      render: (data) => {
        const rett =
          data.type === "MUTATION"
            ? data.deliveredType
            : data.type === "BORROWING" && data.deliveredType !== "USER"
            ? data.deliveredType
            : "";
        return data.type + " " + rett;
      },
    },
    {
      title: "Approval",
      key: "approval",
      width: 250,
      sorter: (a, b) => {
        const getOrderValue = (val) => {
          if (approvalStatus(val) === "Resubmit") {
            return 4;
          } else if (approvalStatus(val) === "Konfirmasi Diterima") {
            return 3;
          } else if (approvalStatus(val) === "Approve/Reject") {
            return 2;
          } else {
            return 1;
          }
        };
        return getOrderValue(a) - getOrderValue(b);
      },
      align: "center",
      render: (data) => approval(data),
    },
    {
      title: "Status",
      width: 100,
      sorter: (a, b) => {
        const getOrderValue = (val) => {
          if (val.status === "WAITING_APPROVAL") {
            if (val.type === "MUTATION") {
              if (val.deliveredType === "LOCATION") {
                if (val.alreadyDelivered) {
                  return 10;
                } else {
                  return 9;
                }
              }
            }
            return 8;
          } else if (val.status === "REJECTED") {
            if (val.type === "MUTATION") {
              if (val.deliveredType === "LOCATION") {
                if (val.alreadyDelivered) {
                  return 7;
                } else {
                  return 6;
                }
              }
            }
            return 5;
          } else if (val.status === "APPROVE") {
            if (val.type === "MUTATION") {
              if (val.deliveredType === "LOCATION") {
                if (val.alreadyDelivered) {
                  return 3;
                } else {
                  return 2;
                }
              }
            }
            return 1;
          } else {
            return 99;
          }
        };
        return getOrderValue(a) - getOrderValue(b);
      },
      render: (data) => (
        <Space direction="vertical">
          <Tag
            style={{
              borderRadius: "20px",
              width: "150px",
              textAlign: "center",
            }}
            color={statusList(data?.status)}
          >
            {data?.status}
          </Tag>
          {data?.type === "MUTATION" && data?.deliveredType === "LOCATION" && (
            // data?.status !== "REJECTED" && (
            // dibagian ini harus di cek dulu karena akan lebih baik kalau di
            // hilangkan sudah terimanya kalau rejectnya sudah tidak ada
            <Tag
              style={{
                borderRadius: "20px",
                minWidth: "150px",
                textAlign: "center",
              }}
              color={statusList(
                data?.alreadyDelivered ? "APPROVE" : "REJECTED"
              )}
            >
              {data?.alreadyDelivered ? "SUDAH DITERIMA" : "BELUM DITERIMA"}
            </Tag>
          )}
        </Space>
      ),
    },
    {
      key: "action",
      width: 10,
      render: (data) => actionList(data),
    },
  ];

  const actionList = (data) => {
    return (
      <Dropdown overlay={menuAction(data)} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <EllipsisOutlined rotate={90} />
        </a>
      </Dropdown>
    );
  };

  const menuAction = (data) => {
    let newItem = [
      {
        label: (
          <Link to={"/asset-movement/detail/" + data.id}>Lihat Detail</Link>
        ),
        key: "1",
      },
    ];

    const cetak = {
      label: <div onClick={() => downloadReport(data.id)}>Cetak</div>,
      key: "3",
    };

    const deleteMovement = {
      label: (
        <ModalDelete
          data={data}
          getData={getData}
          action={deleteAssetMovement}
        />
      ),
      key: "4",
    };

    const unitId = localStorage.getItem("downstream_unit_id").split(",");
    const unitRelated = unitId.includes(String(data?.created_by?.unitId));
    const headUnit =
      "HEAD_DEPT" === localStorage.getItem("role_id") &&
      unitId.includes(String(data?.created_by?.unitId));
    const superAdmin = "SUPERADMIN" === localStorage.getItem("role_id");
    const adminGA = "ADMIN_GA" === localStorage.getItem("role_id");
    const approvalFirst =
      data?.assetMovementApprovals &&
      data?.assetMovementApprovals[data?.assetMovementApprovals.length - 1]
        ?.asset_movement_approval_details;
    const checkApprovalFirst =
      approvalFirst?.length > 0 &&
      (approvalFirst[0].approval_st === "WAITING_APPROVAL" ? true : false);

    newItem.push(cetak);

    if (data?.status === "REJECTED") {
      if (superAdmin || (checkApprovalFirst && headUnit)) {
        newItem.push({
          label: <Link to={"/asset-movement/edit/" + data.id}>Edit</Link>,
          key: "2",
        });
      }
    }
    if (superAdmin && data?.status === "WAITING_APPROVAL") {
      newItem.push(deleteMovement);
    }

    return <Menu items={newItem} />;
  };

  const downloadReport = async (id) => {
    await downloadReportAssetMovement(id);
  };

  const approval = (data) => {
    let checkApproval = true;
    if (
      data.type === "BORROWING" ||
      data.type === "MUTATION" ||
      data.type === "REPLACE"
    ) {
      if (
        localStorage.getItem("role_id") === "HEAD_DEPT" ||
        localStorage.getItem("role_id") === "VP_DEPT"
      ) {
        if (
          data.statusApproval !==
          localStorage.getItem("role_id") +
            "_" +
            localStorage.getItem("unit_key")
        ) {
          checkApproval = false;
        }
      } else {
        if (data.statusApproval !== localStorage.getItem("role_id")) {
          checkApproval = false;
        }
      }

      if (data.type === "MUTATION" && data.deliveredType === "LOCATION") {
        if (
          data.statusApproval === "OFFICE_VENDOR_MANAGEMENT" &&
          !data?.alreadyDelivered &&
          data.created_by.role_id !== "ADMIN_GA"
        ) {
          checkApproval = false;
        }
      }
    } else {
      if (data.statusApproval !== localStorage.getItem("role_id")) {
        checkApproval = false;
      }
    }
    if (
      (data.status === "REJECTED" &&
        data.deliveredType !== "RENEWAL" &&
        localStorage.getItem("user_id") === String(data?.created_by?.id)) ||
      (data.status === "REJECTED" &&
        data.deliveredType === "RENEWAL" &&
        localStorage.getItem("user_id") === String(data?.deliveredToUser?.id))
    ) {
      return (
        <div>
          <Link to={`/asset-movement/edit/${data.id}/resubmit`}>
            <div className={styles.buttonCardWarning}>
              <Button
                size="large"
                type="default"
                className={styles.buttonWarning}
              >
                Resubmit
              </Button>
            </div>
          </Link>
          <div className={styles.buttonCardDanger}>
            <Button
              size="large"
              type="default"
              onClick={() => {
                setModal({
                  title: "Konfirmasi Penghapusan",
                  open: true,
                  loading: false,
                  okText: "Hapus",
                  onOk: async () => {
                    setModal((prev) => ({ ...prev, loading: true }));
                    await deleteAssetMovement(data.id);
                    setModal((prev) => ({
                      ...prev,
                      open: false,
                      loading: false,
                    }));
                    notification.success({
                      message: "Success",
                      description: "Asset telah dihapus.",
                    });
                    getData();
                  },
                  body: "Apakah Anda yakin ingin menghapus asset ini?",
                });
              }}
              className={styles.buttonDanger}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }

    if (
      data.statusApproval === "ADMIN_KONFIRMASI_PENERIMAAN" &&
      !data.alreadyDelivered &&
      localStorage.getItem("user_id") === String(data?.created_by?.id) &&
      data.type === "MUTATION" &&
      data.deliveredType === "LOCATION"
    ) {
      return (
        <Space>
          <div className={styles.buttonCardWarning}>
            <Button
              size="large"
              type="default"
              className={styles.buttonWarning}
              onClick={() => setModalUploadDokumen({ open: true, id: data.id })}
            >
              Konfirmasi Diterima
            </Button>
          </div>
          <div className={styles.buttonCardDanger}>
            <Button
              size="large"
              type="default"
              className={styles.buttonDanger}
              onClick={() => showModalRejectKonfirmasiPenerimaan(data.id)}
            >
              Batalkan Penerimaan
            </Button>
          </div>
        </Space>
      );
    }
    if (checkApproval) {
      return (
        <Space>
          <div className={styles.buttonCardSuccess}>
            <Button
              size="large"
              type="default"
              className={styles.buttonSuccess}
              onClick={() => showModalApprove(data.id)}
            >
              Approve
            </Button>
          </div>
          <div className={styles.buttonCardDanger}>
            <Button
              size="large"
              type="default"
              className={styles.buttonDanger}
              onClick={() => showModalReject(data.id)}
            >
              Reject
            </Button>
          </div>
        </Space>
      );
    }
  };

  const approvalStatus = (data) => {
    let checkApproval = true;
    if (
      data.type === "BORROWING" ||
      data.type === "MUTATION" ||
      data.type === "REPLACE"
    ) {
      if (
        localStorage.getItem("role_id") === "HEAD_DEPT" ||
        localStorage.getItem("role_id") === "VP_DEPT"
      ) {
        if (
          data.statusApproval !==
          localStorage.getItem("role_id") +
            "_" +
            localStorage.getItem("unit_key")
        ) {
          checkApproval = false;
        }
      } else {
        if (data.statusApproval !== localStorage.getItem("role_id")) {
          checkApproval = false;
        }
      }

      if (data.type === "MUTATION" && data.deliveredType === "LOCATION") {
        if (
          data.statusApproval === "OFFICE_VENDOR_MANAGEMENT" &&
          !data?.alreadyDelivered
        ) {
          checkApproval = false;
        }
      }
    } else {
      if (data.statusApproval !== localStorage.getItem("role_id")) {
        checkApproval = false;
      }
    }

    let status = "";
    if (
      (data.status === "REJECTED" &&
        data.deliveredType !== "RENEWAL" &&
        localStorage.getItem("user_id") === String(data?.created_by?.id)) ||
      (data.status === "REJECTED" &&
        data.deliveredType === "RENEWAL" &&
        localStorage.getItem("user_id") === String(data?.deliveredToUser?.id))
    ) {
      status = "Resubmit";
    }

    if (
      data.statusApproval === "ADMIN_KONFIRMASI_PENERIMAAN" &&
      !data.alreadyDelivered &&
      localStorage.getItem("user_id") === String(data?.created_by?.id) &&
      data.type === "MUTATION" &&
      data.deliveredType === "LOCATION"
    ) {
      status = "Konfirmasi Diterima/Batalkan Penerimaan";
    }
    if (checkApproval) {
      status = "Approve/Reject";
    }

    return status;
  };

  const showModalApprove = (id) => {
    setModal({
      title: "Approve Asset",
      open: true,
      onOk: async () => {
        setModal((prev) => ({
          ...prev,
          loading: true,
        }));
        const res = await approvalMovement(id, {
          type: "APPROVE",
        });
        if (res?.data?.error === false || !res?.data?.error) {
          setModal((prev) => ({
            ...prev,
            open: false,
            loading: false,
          }));
          notification.success({
            message: "Success",
            description: "Asset Approved",
          });
          getData();
        } else {
          setModal((prev) => ({
            ...prev,
            loading: false,
          }));
        }
      },
      body: "Apakah Anda yakin menyetujui movement asset?",
    });
  };

  const showModalReject = (id) => {
    setRejectType("APPROVAL");
    setModalData({ ...modalData, id: id });
    setModal({
      title: "Reject Asset",
      open: true,
      onOk: handleReject,
      body: (
        <>
          <Text>Apakah Anda yakin menolak movement asset?</Text>
          <Input
            onKeyUp={(evt) => {
              setModalData((prev) => ({
                ...prev,
                reason: evt.target.value,
              }));
            }}
            placeholder="Masukkan alasan"
          />
        </>
      ),
    });
  };

  const showModalRejectKonfirmasiPenerimaan = (id) => {
    setRejectType("APPROVAL-PENERIMAAN");
    setModalData({ ...modalData, id: id });
    setModal({
      title: "Reject Asset",
      open: true,
      onOk: handleReject,
      body: (
        <>
          <Text>Apakah Anda yakin menolak konfirmasi diterima?</Text>
          <Input
            onKeyUp={(evt) => {
              setModalData((prev) => ({
                ...prev,
                reason: evt.target.value,
              }));
            }}
            placeholder="Masukkan alasan"
          />
        </>
      ),
    });
  };

  const handleReject = async () => {
    if (modalData.reason) {
      setModal((prev) => ({
        ...prev,
        loading: true,
      }));

      const res =
        rejectType === "APPROVAL"
          ? await approvalMovement(modalData.id, {
              type: "REJECT",
              remarks: modalData.reason,
            })
          : await rejectConfirmDelivered(modalData.id, {
              type: "REJECT",
              remarks: modalData.reason,
            });

      if (res?.data?.error === false || !res?.data?.error) {
        setModal((prev) => ({
          ...prev,
          open: false,
          loading: false,
        }));
        notification.success({
          message: "Success",
          description: "Asset Rejected",
        });
        getData();
      } else {
        setModal((prev) => ({
          ...prev,
          open: false,
          loading: false,
        }));
      }
    } else {
      notification.error({
        message: "Error",
        description: "Reason Description is required",
      });
    }
  };

  const getData = async () => {
    setLoading(true);
    const newData = await getAllAssetMovement(params);
    if (newData) {
      setData(newData.data);
      setLoading(false);
    }
  };

  const getDataRoom = async () => {
    const newData = await getAllMaster("rooms");
    if (newData) {
      const newDataRoom = [
        {
          value: "",
          label: "Semua Ruang",
        },
      ];
      const newDataRooms =
        newData.data &&
        newData.data.map((item) => ({
          value: item.id,
          label:
            item.name +
            " " +
            item?.floor?.name +
            " " +
            item?.floor?.building?.name,
        }));
      setDataRoom(newDataRoom.concat(newDataRooms));
    }
  };

  const getDataUnit = async () => {
    const newData = await getAllMaster("units");
    if (newData) {
      const newDataUnit = [
        {
          value: "",
          label: "SEMUA UNIT",
        },
      ];

      if (newData.data) {
        const downstreamUnitIds = localStorage.downstream_unit_id
          .split(",")
          .map((id) => parseInt(id));

        const newDataUnits = newData.data.map((unit) => ({
          value: unit.id,
          label: unit.name,
        }));
        setDataUnit(newDataUnit.concat(newDataUnits));
      }
    }
  };

  const printPDF = async (url) => {
    try {
      const pdfData = data.map((x) => {
        const toPdfData = {
          "Transaction Date": x.transactionDate
            ? moment(x.transactionDate).format("DD-MM-YYYY")
            : "",
          "Unit From": x.created_by.unit.name ? x.created_by.unit.name : "",
          "Unit To": x.deliveredToUser.unit.name
            ? x.deliveredToUser.unit.name
            : "",
          "Movement Type":
            x.type +
            " " +
            (x.type === "MUTATION"
              ? x.deliveredType
              : x.type === "BORROWING" && x.deliveredType !== "USER"
              ? x.deliveredType
              : ""),
          Approval: approvalStatus({
            type: x.type,
            statusApproval: x.statusApproval,
            status: x.status,
            deliveredType: x.deliveredType,
            alreadyDelivered: x.alreadyDelivered,
            created_by: x.created_by,
            deliveredToUser: x.deliveredToUser,
          }),
          Status: x.status
            ? x.status.concat(
                x.type === "MUTATION" && x.deliveredType === "LOCATION"
                  ? x.alreadyDelivered
                    ? "/SUDAH DITERIMA"
                    : "/BELUM DITERIMA"
                  : ""
              )
            : "",
        };
        return toPdfData;
      });

      const doc = new jsPDF({
        orientation: "landscape",
      });

      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("PT Jalin Pembayaran Nusantara", 10, 10);

      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      const pageWidth = doc.internal.pageSize.getWidth();
      const companyName = "Asset Movement Report";
      const textWidth = doc.getTextWidth(companyName);
      const textX = (pageWidth - textWidth) / 2;
      doc.text(companyName, textX, 20);

      const columns = [
        { header: "Transaction Date", dataKey: "Transaction Date" },
        { header: "Unit From", dataKey: "Unit From" },
        { header: "Unit To", dataKey: "Unit To" },
        { header: "Movement Type", dataKey: "Movement Type" },
        { header: "Approval", dataKey: "Approval" },
        { header: "Status", dataKey: "Status" },
      ];

      doc.autoTable({
        startY: 30,
        head: [columns.map((col) => col.header)],
        body: pdfData.map((item) => columns.map((col) => item[col.dataKey])),
        styles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
          fontStyle: "normal",
        },
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontStyle: "bold",
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
        },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.1,
        theme: "grid",
      });

      doc.save(`asset-${moment().format("YYYYMMDDHHmmss")}.pdf`);
    } catch (error) {
      console.error("Error printing PDF:", error);
      notification.error({
        message: "Error",
        description: "Failed to generate PDF file. Please try again later.",
      });
    }
  };

  const printExcel = async (url) => {
    try {
      try {
        const excelData = data.map((x) => {
          const toExcelData = {
            "Transaction Date": x.transactionDate
              ? moment(x.transactionDate).format("DD-MM-YYYY")
              : "",
            "Unit From": x.created_by.unit.name ? x.created_by.unit.name : "",
            "Unit To": x.deliveredToUser.unit.name
              ? x.deliveredToUser.unit.name
              : "",
            "Movement Type":
              x.type +
              " " +
              (x.type === "MUTATION"
                ? x.deliveredType
                : x.type === "BORROWING" && x.deliveredType !== "USER"
                ? x.deliveredType
                : ""),
            Approval: approvalStatus({
              type: x.type,
              statusApproval: x.statusApproval,
              status: x.status,
              deliveredType: x.deliveredType,
              alreadyDelivered: x.alreadyDelivered,
              created_by: x.created_by,
              deliveredToUser: x.deliveredToUser,
            }),
            Status: x.status
              ? x.status.concat(
                  x.type === "MUTATION" && x.deliveredType === "LOCATION"
                    ? x.alreadyDelivered
                      ? "/SUDAH DITERIMA"
                      : "/BELUM DITERIMA"
                    : ""
                )
              : "",
          };
          return toExcelData;
        });

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet1");

        const columns = [
          { header: "Transaction Date", key: "Transaction Date", width: 20 },
          { header: "Unit From", key: "Unit From", width: 15 },
          { header: "Unit To", key: "Unit To", width: 15 },
          { header: "Movement Type", key: "Movement Type", width: 15 },
          { header: "Approval", key: "Approval", width: 15 },
          { header: "Status", key: "Status", width: 15 },
        ];

        worksheet.columns = columns;

        excelData.forEach((item) => {
          worksheet.addRow(item);
        });

        worksheet.getRow(1).eachCell((cell) => {
          cell.font = { bold: true };
        });

        worksheet.getRow(1).eachCell((cell) => {
          cell.font = { bold: true };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFFFF" },
          };
          cell.border = {
            top: { style: "thin", color: { argb: "FF000000" } },
            left: { style: "thin", color: { argb: "FF000000" } },
            bottom: { style: "thin", color: { argb: "FF000000" } },
            right: { style: "thin", color: { argb: "FF000000" } },
          };
        });

        worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
          row.eachCell({ includeEmpty: false }, (cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFFFFF" },
            };
            cell.border = {
              top: { style: "medium", color: { argb: "FF000000" } },
              left: { style: "medium", color: { argb: "FF000000" } },
              bottom: { style: "medium", color: { argb: "FF000000" } },
              right: { style: "medium", color: { argb: "FF000000" } },
            };
          });
        });

        workbook.xlsx
          .writeBuffer()
          .then((buffer) => {
            const blob = new Blob([buffer], {
              type: "application/octet-stream",
            });
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;
            a.download = `asset-${moment().format("YYYYMMDDHHmmss")}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error("Error printing Excel:", error);
            notification.error({
              message: "Error",
              description:
                "Failed to generate Excel file. Please try again later.",
            });
          });
      } catch (error) {
        console.error("Error printing Excel:", error);
        notification.error({
          message: "Error",
          description: "Failed to generate Excel file. Please try again later.",
        });
      }
      if (data?.data?.error === false || !data?.data?.error) {
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getDataRoom();
    getDataUnit();
  }, []);

  useEffect(() => {
    getData();
  }, [params]);

  useEffect(() => {
    if (modalData.reason) {
      setModal((prev) => ({
        ...prev,
        onOk: handleReject,
      }));
    }
  }, [modalData]);

  return (
    <AdminLayout>
      <Card className={styles.cardTable}>
        <Row gutter={[16, 16]}>
          <Col
            style={{ margin: "auto 0", display: "flex", alignItems: "center" }}
            span={24}
            xl={12}
          >
            <div
              style={{
                margin: "auto 0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ marginRight: "10px" }}>Cari</div>
              <Input
                onChange={(text) => {
                  setSearchText(text?.target?.value);
                }}
                placeholder="Cari..."
                style={{ maxWidth: "300px", marginRight: "30px" }}
              />
            </div>
            <Button
              type="primary"
              style={{ marginRight: "10px" }}
              onClick={printExcel.bind(null, window.location.href)}
            >
              Export Excel
            </Button>
            <Button onClick={printPDF} type="primary" danger>
              Print PDF
            </Button>
          </Col>

          <Col span={24} xl={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              {localStorage.getItem("position_id") === "3" && (
                <div className={styles.buttonCardTable}>
                  <Button
                    size="large"
                    type="default"
                    className={styles.buttonSuccess}
                    onClick={() => navigate("add")}
                    style={{ minWidth: "100px" }}
                  >
                    Movement
                  </Button>
                </div>
              )}
            </div>
          </Col>
          <Col span={24} xl={24}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ marginRight: "10px" }}>Filter:</div>
              {
                <Select
                  showSearch
                  placeholder="Select Unit From"
                  style={{ marginRight: "10px", minWidth: "200px" }}
                  options={dataUnit}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(evt) =>
                    setParams((params) => ({ ...params, unit_id_from: evt }))
                  }
                />
              }
              {
                <Select
                  showSearch
                  placeholder="Select Unit To"
                  style={{ marginRight: "10px", minWidth: "200px" }}
                  options={dataUnit}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(evt) =>
                    setParams((params) => ({ ...params, unit_id_to: evt }))
                  }
                />
              }
              <Select
                showSearch
                placeholder="Select Status"
                style={{ marginRight: "10px", minWidth: "200px" }}
                options={[
                  {
                    value: "",
                    label: "SEMUA STATUS",
                  },
                  {
                    value: "WAITING_APPROVAL",
                    label: "WAITING APPROVAL",
                  },
                  {
                    value: "APPROVE",
                    label: "APPROVE",
                  },
                  {
                    value: "REJECTED",
                    label: "REJECTED",
                  },
                ]}
                onChange={(evt) =>
                  setParams((params) => ({ ...params, status: evt }))
                }
              />
              <Select
                showSearch
                placeholder="Select Movement Type"
                style={{ marginRight: "10px", minWidth: "200px" }}
                options={[
                  {
                    value: "",
                    label: "SEMUA STATUS",
                  },
                  {
                    value: "BORROWING-RENEWAL",
                    label: "BORROWING RENEWAL",
                  },
                  {
                    value: "BORROWING-RETURN",
                    label: "BORROWING RETURN",
                  },
                  {
                    value: "BORROWING",
                    label: "BORROWING",
                  },
                  {
                    value: "MUTATION-LOCATION",
                    label: "MUTATION LOCATION",
                  },
                  {
                    value: "MUTATION-USER",
                    label: "MUTATION USER",
                  },
                  {
                    value: "REPLACE",
                    label: "REPLACE",
                  },
                ]}
                onChange={(evt) => {
                  const values = evt.split("-");
                  if (values.length > 1) {
                    setParams((params) => ({
                      ...params,
                      type: values[0],
                      delivered_type: values[1],
                    }));
                  } else {
                    setParams((params) => {
                      const { delivered_type, ...rest } = params;
                      if (values[0] === "BORROWING") {
                        return {
                          ...rest,
                          type: values[0],
                          delivered_type: "USER",
                        };
                      } else {
                        return {
                          ...rest,
                          type: values[0],
                        };
                      }
                    });
                  }
                }}
              />
              {/* <Select
                showSearch
                placeholder="Select Room"
                style={{ marginRight: "10px", minWidth: "200px" }}
                options={dataRoom}
                onChange={(evt) =>
                  setParams((params) => ({ ...params, room_id: evt }))
                }
              /> */}
            </div>
          </Col>
        </Row>
        <Table
          ref={tableRef}
          columns={columns}
          dataSource={data}
          rowKey="id"
          loading={loading}
          pagination
          style={{ marginTop: "20px" }}
        />
      </Card>

      <Modal
        title={modal.title}
        open={modal.open}
        onOk={modal.onOk}
        loading={modal.loading}
        okText={modal.okText}
        onCancel={() => {
          form.resetFields();
          setModal({
            open: false,
            loading: false,
            title: "",
            body: "",
            okText: "",
            onOk: () => {},
          });
        }}
        body={modal.body}
      />

      <KonfirmasiDiterima
        open={modalUploadDokumen}
        setOpen={setModalUploadDokumen}
        getData={getData}
      />
    </AdminLayout>
  );
}

export default AssetMovement;
