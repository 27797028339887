import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Card, Col, Dropdown, Menu, Row, Table, Tag } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import moment from "moment";
import styles from "../Global.module.css";
import StatusApprovalList from "../View/StatusApprovalList";
import AssetContext from "../../../Services/Asset";

const CardRiwayatMovement = (props) => {
  const { getAllAssetMovement, downloadReportAssetMovement } =
    useContext(AssetContext);
  const [data, setData] = useState([]);

  const columns = [
    {
      title: "Transaction Date",
      key: "createdAt",
      width: 200,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (data) =>
        (
          <Link to={`/asset-movement/detail/${data.id}`}>
            {data?.createdAt &&
              moment(data?.createdAt).format("LL HH:mm")}
          </Link>
        ) || "-",
    },
    {
      title: "Unit",
      dataIndex: "created_by",
      key: "unit",
      width: 100,
      sorter: (a, b) => a.created_by?.name.localeCompare(b.created_by?.name),
      render: (data) => data?.name,
    },
    {
      title: "Return Date",
      dataIndex: "returnDate",
      key: "return_date",
      width: 100,
      sorter: (a, b) => (a.returnDate || "").localeCompare(b.returnDate || ""),
      render: (data) => (data && moment(data).format("LL")) || "-",
    },
    {
      title: "To",
      dataIndex: "deliveredToUser",
      key: "to",
      width: 100,
      sorter: (a, b) =>
        a.deliveredToUser?.name.localeCompare(b.deliveredToUser?.name),
      render: (data) => data?.name,
    },
    {
      title: "Delivered Type",
      dataIndex: "deliveredType",
      key: "delivered_type",
      width: 100,
      sorter: (a, b) =>
        String(a.deliveredType).localeCompare(String(b.deliveredType)),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 100,
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (data) => (
        <Tag
          style={{
            borderRadius: "20px",
            minWidth: "120px",
            textAlign: "center",
          }}
          color={StatusApprovalList(data)}
        >
          {data}
        </Tag>
      ),
    },
    {
      title: "Aksi",
      key: "aksi",
      width: 10,
      render: (data) => actionList(data, "asset-movement"),
    },
  ];

  const actionList = (data) => {
    return (
      <Dropdown overlay={menuAction(data)} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <EllipsisOutlined rotate={90} />
        </a>
      </Dropdown>
    );
  };

  const menuAction = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link to={`/asset-movement/detail/` + data.id}>Lihat Detail</Link>
          ),
          key: "1",
        },
        {
          label: <div onClick={() => downloadReport(data.id)}>Cetak</div>,
          key: "2",
        },
      ]}
    />
  );

  const downloadReport = async (id) => {
    const newData = await downloadReportAssetMovement(id);
  };

  const getData = async () => {
    const newData = await getAllAssetMovement({
      asset_id: props.id,
      only_related: true,
    });
    if (newData) {
      setData(newData.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Card title={"Riwayat Movement"} className={styles.cardTable}>
      <Row gutter={40}>
        <Col className={styles.detailRow} span={24}>
          <Table columns={columns} dataSource={data} rowKey={"id"} />
        </Col>
      </Row>
    </Card>
  );
};

export default CardRiwayatMovement;
