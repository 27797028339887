import { notification } from "antd";
import moment from "moment";
import React, { useReducer } from "react";
import ApiWithAuth from "../../Config/Api/ApiWithAuth";
import AssetContext from "./index";
import AssetReducer from "./reducer";
import {
  GET_ALL_ASSET,
  GET_DETAIL_ASSET,
  GET_STOCK_OPNAME,
  GET_LIST_STOCK_OPNAME,
  START_STOCK_OPNAME,
  APPROVE_STOCK_OPNAME,
  RESUBMIT_STOCK_OPNAME,
  GET_LABEL_PRINTING,
  GET_DETAIL_LABEL_PRINTING,
  ADD_LABEL_PRINTING,
  GENERATE_LABEL_PRINTING,
  GET_ALL_BUILDING,
  GET_DETAIL_STOCK_OPNAME,
  CHECKED_OPNAME,
  REJECT_OPNAME,
  UPDATE_STATUS,
  GET_ALL_ASSET_MOVEMENT,
  ADD_ASSET_MOVEMENT,
  UPLOAD,
  EXTEND_STOCK_OPNAME,
  APPROVAL,
  DELETE_ASSET,
  CANCEL_UPDATE_ASSET,
  NOTIFICATIONS,
} from "./types";

const AssetState = ({ children }) => {
  const initialState = {
    asset: [],
    detail: "",
    status: "",
    loading: true,
  };

  const [state, dispatch] = useReducer(AssetReducer, initialState);

  const getAllAsset = async (parameter) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth("/assets", parameter);
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_ALL_ASSET, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getAllDaftarAsset = async (parameter) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth(
        "/daftar-asset",
        parameter
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_ALL_ASSET, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getDetailAsset = async (id) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth("/assets/" + id);
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_DETAIL_ASSET, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const checkPermission = async (id) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth(
        "/assets/" + id + "/permission"
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_DETAIL_ASSET, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getStockOpname = async () => {
    try {
      const data = await ApiWithAuth.getDataWithAuth("/stock-opname");
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_STOCK_OPNAME, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getDetailStockOpname = async (id) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth(
        `/stock-opname/asset?unit_id=${id}`
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_DETAIL_STOCK_OPNAME, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getPendingStockOpname = async (id) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth(
        `/stock-opname/${id}/pending`
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_DETAIL_STOCK_OPNAME, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getListStockOpname = async (id) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth(
        `/stock-opname/asset/${id}`
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_LIST_STOCK_OPNAME, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const startStockOpname = async (body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        "/stock-opname",
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: START_STOCK_OPNAME });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const extendStockOpname = async (body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        "/stock-opname/extend",
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: EXTEND_STOCK_OPNAME });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const extendStockOpnameUnit = async (id, body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        `/stock-opname/unit/${id}/extend-request`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: EXTEND_STOCK_OPNAME });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const approveStockOpname = async (body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        "/stock-opname/approve",
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: APPROVE_STOCK_OPNAME });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const rejectStockOpname = async (body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        "/stock-opname/reject",
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: APPROVE_STOCK_OPNAME });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const resubmitStockOpname = async (body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        "/stock-opname/resubmit",
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: RESUBMIT_STOCK_OPNAME });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const checkedOpname = async (id, body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        `/stock-opname/asset/checked`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: CHECKED_OPNAME });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const rejectOpname = async (id, body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        `/stock-opname/asset/${id}/reject`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: REJECT_OPNAME });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const takeoutStockOpname = async (id, body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        `/stock-opname/asset/${id}/dispose`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: REJECT_OPNAME });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const approvalDisposeStockOpname = async (id, body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        `/stock-opname/asset/${id}/dispose/approve`
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: REJECT_OPNAME });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getLabelPrinting = async (parameter) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth("/label", parameter);
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_LABEL_PRINTING });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getDetailLabelPrinting = async (id) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth(`/label/${id}`);
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_DETAIL_LABEL_PRINTING });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const addLabelPrinting = async (body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        "/label",
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: ADD_LABEL_PRINTING });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const generateLabelPrinting = async (id) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(`/label/${id}/generate`);
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GENERATE_LABEL_PRINTING });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const deleteQR = async (id) => {
    try {
      const data = await ApiWithAuth.deleteDataWithAuth(`/label/qr-code/${id}`);
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: UPDATE_STATUS });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getAllBuilding = async () => {
    try {
      const data = await ApiWithAuth.getDataWithAuth("/buildings");
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_ALL_BUILDING });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const updateStatusAction = async (id, body) => {
    try {
      const data = await ApiWithAuth.putDataWithAuth(
        `/assets/${id}/update-status`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: UPDATE_STATUS });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const uploadBastrAction = async (id, body) => {
    try {
      const data = await ApiWithAuth.putDataWithAuth(
        `/assets/${id}/upload-bast`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: UPDATE_STATUS });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const uploadDokumenAction = async (id, body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        `/assets/${id}/upload-document`,
        body
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: UPDATE_STATUS });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const deleteDocumentAsset = async (asset_id, id) => {
    try {
      const data = await ApiWithAuth.deleteDataWithAuth(
        `/assets/${asset_id}/file/${id}`
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: UPDATE_STATUS });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const updateBastDateAction = async (id, body) => {
    try {
      const data = await ApiWithAuth.putDataWithAuth(
        `/assets/${id}/bast-date`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: UPDATE_STATUS });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const completeDataAction = async (id, body) => {
    try {
      const data = await ApiWithAuth.putDataWithAuth(
        `/assets/${id}/fill-additional-data`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: UPDATE_STATUS });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const updateAsset = async (id, body) => {
    try {
      const data = await ApiWithAuth.putDataWithAuth(
        `/assets/${id}`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: UPDATE_STATUS });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const deleteAsset = async (id) => {
    try {
      const data = await ApiWithAuth.deleteDataWithAuth(`/assets/${id}`);
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: UPDATE_STATUS });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const cancelUpdateAsset = async (id) => {
    try {
      const data = await ApiWithAuth.putDataWithAuth(`/assets/${id}/cancel`);
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: CANCEL_UPDATE_ASSET });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const deleteAssetMovement = async (id) => {
    try {
      const data = await ApiWithAuth.deleteDataWithAuth(`/movements/${id}`);
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: UPDATE_STATUS });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const approvalRegisteredAsset = async (id, body) => {
    try {
      const data = await ApiWithAuth.putDataWithAuth(
        `/assets/${id}/approval`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: APPROVAL });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const deleteRegisteredAsset = async (id, body) => {
    try {
      const data = await ApiWithAuth.deleteDataWithAuth(
        `/assets/${id}`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: DELETE_ASSET });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getAllAssetMovement = async (parameter) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth("/movements", parameter);
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_ALL_ASSET_MOVEMENT, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getAssetMovement = async (id, parameter) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth(
        `/movements/${id}`,
        parameter
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_ALL_ASSET_MOVEMENT, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const downloadReportAssetMovement = async (id) => {
    try {
      const data = await ApiWithAuth.downloadDataWithAuth(
        `/movements/${id}/report`,
        null,
        `Report-${id}-${moment().unix()}`,
        ".pdf"
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_ALL_ASSET_MOVEMENT, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const approvalMovement = async (id, body, type = null) => {
    try {
      const data = await ApiWithAuth.putDataWithAuth(
        `/movements${type === "disposal" ? "/disposal" : ""}/${id}/approval`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: APPROVAL });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getBorrowingMonitoring = async () => {
    try {
      const data = await ApiWithAuth.getDataWithAuth("/assets/borrowed");
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_ALL_ASSET_MOVEMENT, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getDetailBorrowing = async (id, parameter) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth(
        `/assets/borrowed/${id}`,
        parameter
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_ALL_ASSET_MOVEMENT, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const addAssetMovement = async (body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        `/movements${
          body.type === "MUTATION"
            ? "/mutation"
            : body.type === "REPLACE"
            ? "/replace"
            : ""
        }`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: ADD_ASSET_MOVEMENT });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const updateAssetMovement = async (id, body) => {
    try {
      const data = await ApiWithAuth.putDataWithAuth(
        `/movements${
          body.type === "MUTATION"
            ? "/mutation"
            : body.type === "REPLACE"
            ? "/replace"
            : `/${id}`
        }`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: ADD_ASSET_MOVEMENT });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const resubmitAssetMovement = async (id, body, type) => {
    try {
      const data = await ApiWithAuth.putDataWithAuth(
        type === "MUTATION"
          ? `/movements/mutation/${id}/resubmit`
          : `/movements/${id}/resubmit`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: ADD_ASSET_MOVEMENT });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const resubmitAsset = async (id, body) => {
    try {
      const data = await ApiWithAuth.putDataWithAuth(
        `/assets/${id}/resubmit`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: ADD_ASSET_MOVEMENT });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const renewalAction = async (id, body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        `/movements/${id}/borrowing-renewal`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: ADD_ASSET_MOVEMENT });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const returnAction = async (id, body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        `/movements/${id}/return`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: ADD_ASSET_MOVEMENT });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getAllDisposal = async (parameter) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth(
        "/movements/disposal",
        parameter
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_ALL_ASSET_MOVEMENT, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const addAssetDisposal = async (body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        `/movements/disposal`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: ADD_ASSET_MOVEMENT });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const disposeMovement = async (id, body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        `/movements/disposal/${id}/dispose`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: ADD_ASSET_MOVEMENT });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const uploadDokumenMovement = async (id, body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        `/movements/${id}/upload-documents`,
        body
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: UPDATE_STATUS });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const confirmDelivered = async (id, body) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        `/movements/${id}/confirm-delivered`,
        body
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: UPDATE_STATUS });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const rejectConfirmDelivered = async (id, body) => {
    try {
      const data = await ApiWithAuth.putDataWithAuth(
        `/movements/${id}/reject-confirm-delivered`,
        body
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: UPDATE_STATUS });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const downloadReportStockOpname = async (name, parameter) => {
    try {
      const data = await ApiWithAuth.downloadDataWithAuth(
        `/stock-opname/report`,
        parameter,
        `Report-${name}-${moment().unix()}`,
        ".pdf"
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_ALL_ASSET_MOVEMENT, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const downloadReportAsset = async (name, parameter, type) => {
    try {
      const data = await ApiWithAuth.downloadDataWithAuth(
        `/assets/export/${type}`,
        parameter,
        `Report-${name}-${moment().unix()}`,
        "." + (type === "excel" ? "xlsx" : "pdf")
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_ALL_ASSET_MOVEMENT, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const cetakAssetAction = async (id) => {
    try {
      const data = await ApiWithAuth.downloadDataWithAuth(
        `/assets/${id}/print`,
        null,
        `Asset-${moment().unix()}`,
        ".pdf"
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_ALL_ASSET_MOVEMENT, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const upload = async (body, directory) => {
    try {
      let newData = new FormData();
      if (Array.isArray(body)) {
        body.forEach((file) => {
          newData.append("file", file, file.name);
        });
      } else {
        newData.append("file", body, body.name);
      }
      if (directory) {
        newData.append("directory", directory);
      }

      const data = await ApiWithAuth.postDataWithCustomHeaders(
        "/upload",
        newData,
        {
          "Content-Type": "multipart/form-data",
        }
      );

      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: UPLOAD });
      } else {
        notification.error({
          message: `Error`,
          description:
            data?.data?.message ||
            "An error occurred during the upload process.",
          placement: "top",
        });
      }

      return data?.data; // Mengembalikan data dari API jika diperlukan
    } catch (err) {
      console.error("Error during file upload:", err.message);
      notification.error({
        message: `Upload Failed`,
        description:
          "An error occurred during the file upload process. Please try again.",
        placement: "top",
      });
    }
  };

  const getProfile = async (id) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth(`/users/${id}`);
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: GET_STOCK_OPNAME, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getNotification = async (id) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth(`/notifications`);
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: NOTIFICATIONS, payload: data?.data?.data });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const updateSignature = async (id, body) => {
    try {
      const data = await ApiWithAuth.putDataWithAuth(
        `/users/${id}/update-signature`,
        JSON.stringify(body)
      );
      if (data?.data?.error === false || !data?.data?.error) {
        dispatch({ type: UPDATE_STATUS });
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const { asset, detail, status, loading } = state;

  return (
    <AssetContext.Provider
      value={{
        asset,
        detail,
        status,
        loading,
        getAllAsset,
        getAllDaftarAsset,
        getDetailAsset,
        getStockOpname,
        getDetailStockOpname,
        getPendingStockOpname,
        getListStockOpname,
        startStockOpname,
        extendStockOpname,
        extendStockOpnameUnit,
        approveStockOpname,
        rejectStockOpname,
        resubmitStockOpname,
        checkedOpname,
        rejectOpname,
        takeoutStockOpname,
        approvalDisposeStockOpname,
        getLabelPrinting,
        getDetailLabelPrinting,
        addLabelPrinting,
        generateLabelPrinting,
        deleteQR,
        getAllBuilding,
        updateAsset,
        updateStatusAction,
        uploadBastrAction,
        getNotification,
        uploadDokumenAction,
        deleteDocumentAsset,
        updateBastDateAction,
        completeDataAction,
        deleteAsset,
        deleteAssetMovement,
        approvalRegisteredAsset,
        deleteRegisteredAsset,
        getAllAssetMovement,
        getAssetMovement,
        approvalMovement,
        addAssetMovement,
        updateAssetMovement,
        resubmitAssetMovement,
        resubmitAsset,
        downloadReportAssetMovement,
        getBorrowingMonitoring,
        getDetailBorrowing,
        renewalAction,
        returnAction,
        getAllDisposal,
        addAssetDisposal,
        disposeMovement,
        uploadDokumenMovement,
        confirmDelivered,
        rejectConfirmDelivered,
        downloadReportStockOpname,
        downloadReportAsset,
        cetakAssetAction,
        upload,
        getProfile,
        updateSignature,
        checkPermission,
        cancelUpdateAsset,
      }}
    >
      {children}
    </AssetContext.Provider>
  );
};

export default AssetState;
