import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Menu,
  Row,
  Space,
  Table,
  Tag,
} from "antd";
import { EllipsisOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import styles from "../Global.module.css";
import StatusApprovalList from "../View/StatusApprovalList";
import AssetContext from "../../../Services/Asset";

const CardRiwayatDisposal = (props) => {
  const { getAllDisposal, downloadReportAssetMovement } =
    useContext(AssetContext);
  const [data, setData] = useState([]);

  const columns = [
    {
      title: "Transaction Date",
      key: "createdAt",
      width: 200,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (data) =>
        (
          <Link to={`/disposal/detail/${data.id}`}>
            {data?.createdAt &&
              moment(data?.createdAt).format("LL HH:mm")}
          </Link>
        ) || "-",
    },
    {
      title: "Unit",
      dataIndex: "created_by",
      key: "unit",
      width: 200,
      sorter: (a, b) => a.created_by?.name.localeCompare(b.created_by?.name),
      render: (data) => data?.name,
    },
    {
      title: "Disposal Type",
      dataIndex: "disposalType",
      key: "disposal_type",
      width: 200,
      sorter: (a, b) => a.disposalType.localeCompare(b.disposalType),
    },
    {
      title: "Disposal Remark",
      dataIndex: "disposalRemarks",
      key: "disposal_Remark",
      width: 300,
      sorter: (a, b) => a.disposalRemarks.localeCompare(b.disposalRemarks),
    },
    {
      title: "Disposal Document",
      dataIndex: "assetMovementFiles",
      key: "assetMovementFiles",
      width: 200,
      render: (data) => {
        return (
          <Space direction="vertical">
            {data &&
              data.map((item, index) => (
                <a
                  key={index}
                  rel="noreferrer"
                  target="_blank"
                  href={process.env.REACT_APP_IMAGE_URL + "/" + item.name}
                >
                  <Button icon={<DownloadOutlined />}>{item.name}</Button>
                </a>
              ))}
          </Space>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (data) => (
        <Tag
          style={{
            borderRadius: "20px",
            minWidth: "120px",
            textAlign: "center",
          }}
          color={StatusApprovalList(data)}
        >
          {data}
        </Tag>
      ),
    },
    {
      title: "Aksi",
      key: "aksi",
      width: 10,
      render: (data) => actionList(data, "disposal"),
    },
  ];

  const actionList = (data) => {
    return (
      <Dropdown overlay={menuAction(data)} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <EllipsisOutlined rotate={90} />
        </a>
      </Dropdown>
    );
  };

  const menuAction = (data) => (
    <Menu
      items={[
        {
          label: <Link to={`/disposal/detail/` + data.id}>Lihat Detail</Link>,
          key: "1",
        },
        {
          label: <div onClick={() => downloadReport(data.id)}>Cetak</div>,
          key: "2",
        },
      ]}
    />
  );

  const downloadReport = async (id) => {
    const newData = await downloadReportAssetMovement(id);
  };

  const getData = async () => {
    const newData = await getAllDisposal({
      asset_id: props.id,
      only_related: true,
    });
    if (newData) {
      setData(newData.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Card title={"Riwayat Disposal"} className={styles.cardTable}>
      <Row gutter={40}>
        <Col className={styles.detailRow} span={24}>
          <Table columns={columns} dataSource={data} rowKey={"id"} />
        </Col>
      </Row>
    </Card>
  );
};

export default CardRiwayatDisposal;
