import React from "react";
import { Col, Form, Row, Select } from "antd";

function UpdateStatus(props) {

  return (
    <Form
      form={props.form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      labelAlign={"left"}
    >
      <Row gutter={[0, 15]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="status"
            label="Status"
            rules={[
              {
                required: true,
                message: "Please select status!",
              }
            ]}
          >
            <Select
              placeholder="Select status"
            >
              <Select.Option value={"ACTIVE"}>ACTIVE</Select.Option>
              <Select.Option value={"IDLE"}>IDLE</Select.Option>
              <Select.Option value={"NON_ACTIVE"}>NON ACTIVE</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default UpdateStatus;
