import { Button, Card, Col, DatePicker, Row } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import AssetContext from "../../Services/Asset";
import styles from "./Report.module.css";

function StockOpnameReport(props) {
  const { downloadReportStockOpname } = useContext(AssetContext);
  const [data, setData] = useState({
    month: "",
    year: "",
  });

  const downloadReport = async () => {
    await downloadReportStockOpname(
      "Stock Opname " + data.month + data.year,
      data
    );
  };

  return (
    <AdminLayout>
      <Card className={styles.cardTable}>
        <Row
          gutter={16}
          align={"middle"}
          justify={"center"}
          className={styles.report}
        >
          <Col span={24}>
            <div className={styles.reportGroup}>
              <div className={styles.reportLabel}>Unit: </div>
              <DatePicker
                picker="month"
                style={{ width: "400px" }}
                placeholder={"Select Month & Year"}
                onChange={(evt) =>
                  setData({
                    month: moment(evt).month(),
                    year: moment(evt).year(),
                  })
                }
              />
            </div>
          </Col>
          <Col span={24}>
            <div className={styles.buttonCardTable}>
              <Button
                size="large"
                type="default"
                className={styles.buttonSuccess}
                onClick={downloadReport}
              >
                Cetak
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </AdminLayout>
  );
}

export default StockOpnameReport;
