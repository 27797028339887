import React from "react";
import { Modal as NewModal } from "antd";

function Modal(props) {
  return (
    <NewModal
      title={props.title}
      open={props.open}
      onOk={props.onOk}
      confirmLoading={props.loading}
      onCancel={props.onCancel}
      okText={props.okText || "Ok"}
    >
      {props.body}
    </NewModal>
  );
}

export default Modal;
