import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import styles from "../../Components/Global/Global.module.css";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AssetContext from "../../Services/Asset";
import StatusList from "../../Components/Global/View/StatusList";
import StatusApprovalList from "../../Components/Global/View/StatusApprovalList";
import ButtonMovementDisposal from "../../Components/Global/Action/ButtonMovementDisposal";
import moment from "moment";
import CardDokumenAsset from "../../Components/Global/Card/CardDokumenAsset";
import CardRiwayatApproval from "../../Components/Global/Card/CardRiwayatApproval";
import CardRiwayatRegistration from "../../Components/Global/Card/CardRiwayatRegistration";
import CardRiwayatMovement from "../../Components/Global/Card/CardRiwayatMovement";
import CardRiwayatDisposal from "../../Components/Global/Card/CardRiwayatDisposal";

const { Text } = Typography;

function DetailAsset(props) {
  let { id } = useParams();
  const location = useLocation();
  const { getDetailAsset, deleteDocumentAsset, checkPermission } =
    useContext(AssetContext);
  const [data, setData] = useState({});
  const [viewDokumen, setViewDokumen] = useState(false);
  const [viewHistoryAsset, setViewHistoryAsset] = useState(false);
  const [viewHistoryMovement, setViewHistoryMovement] = useState(false);
  const [viewHistoryDisposal, setViewHistoryDisposal] = useState(false);
  const [viewHistoryApproval, setViewHistoryApproval] = useState(false);
  const [viewShortcut, setViewShortcut] = useState(false);
  const [form] = Form.useForm();
  let navigate = useNavigate();

  const getData = async () => {
    const newData = await getDetailAsset(id);
    if (newData) {
      setData(newData.data);
      const newField = newData.data;
      newField.location =
        newField?.room?.name +
        " - " +
        newField?.room?.floor?.name +
        " - " +
        newField?.room?.floor?.building?.name;
      newField.asset_fixed_number = newField?.fixed_asset_number;
      newField.cip_number = newField?.assetHeader?.cip_number;
      newField.po_number = newField?.assetHeader?.po_number;
      newField.insurance_period_start =
        newField?.insurance_period_start &&
        moment(newField?.insurance_period_start).format("LL");
      newField.insurance_period_end =
        newField?.insurance_period_end &&
        moment(newField?.insurance_period_end).format("LL");
      newField.bast_date =
        newField?.bast_date && moment(newField?.bast_date).format("LL");
      newField.unit_name = newField?.originUser?.unit?.name;
      newField.unit_to = newField?.borrowing_movement_id
        ? newField?.unit?.name
        : "-";
      form.setFieldsValue(newField);

      const lastApproval =
        newField?.approvals?.length > 0
          ? newField?.approvals[newField?.approvals?.length - 1]
          : [];
      const allApproval =
        lastApproval?.asset_approval_details?.length > 0 &&
        lastApproval?.asset_approval_details
          .map(({ role_id }) => role_id)
          .includes(localStorage.getItem("role_id")); //array
      const unitGA = [
        "ADMIN_GA",
        "VP_GA",
        "HEAD_GA",
        "OFFICE_VENDOR_MANAGEMENT",
      ].includes(localStorage.getItem("role_id")); //array
      const unitId = localStorage.getItem("downstream_unit_id").split(",");
      const unitRelated = unitId.includes(String(newField?.originUser?.unitId));
      const unitMovementBorrower = unitId.includes(
        String(newField?.user?.unitId)
      );
      const superAdmin = "SUPERADMIN" === localStorage.getItem("role_id");

      const tipe1 = unitRelated || allApproval || unitGA || superAdmin; //Unit terkait + Seluruh Approval + Unit GA + Super Admin
      const tipe2 = unitRelated || unitGA || superAdmin; //Unit terkait + Unit GA + Super Admin
      const tipe3 = unitRelated || unitGA || superAdmin || unitMovementBorrower; //Unit terkait + Unit GA + Super Admin + Unit peminjam ( hanya history movement yang berhubungan dengan dia )
      const tipe4 = unitRelated || superAdmin; //Unit terkait + Unit GA + Super Admin

      if (
        (newField?.used_in_movement === null ||
          newField?.used_in_movement === false) &&
        !newField.borrowing_movement_id
      ) {
        if (
          newField?.status === "ACTIVE" ||
          newField?.status === "NON_ACTIVE" ||
          newField?.status === "IDLE"
        ) {
          if (newField?.approvalStatus === "WAITING_APPROVAL") {
            if (tipe1) {
              setViewDokumen(true);
            }
            if (tipe2) {
              setViewHistoryAsset(true);
              setViewHistoryMovement(true);
              setViewHistoryDisposal(true);
              setViewHistoryApproval(true);
            }
            if (superAdmin) {
              setViewShortcut(false);
            }
          } else if (newField?.approvalStatus === "REJECTED") {
            if (tipe2) {
              setViewDokumen(true);
              setViewHistoryAsset(true);
              setViewHistoryMovement(true);
              setViewHistoryDisposal(true);
              setViewHistoryApproval(true);
            }
            if (superAdmin) {
              setViewShortcut(false);
            }
          } else if (
            newField?.approvalStatus === "APPROVED" ||
            newField?.approvalStatus === "APPROVE"
          ) {
            if (tipe2) {
              setViewDokumen(true);
              setViewHistoryAsset(true);
              setViewHistoryMovement(true);
              setViewHistoryDisposal(true);
            }
            if (tipe4) {
              setViewShortcut(true);
            }
          }
        } else if (newField?.status === "DISPOSE") {
          if (newField?.approvalStatus === "WAITING_APPROVAL") {
            if (tipe1) {
              setViewDokumen(true);
            }
            if (tipe2) {
              setViewHistoryAsset(true);
              setViewHistoryMovement(true);
              setViewHistoryDisposal(true);
              setViewHistoryApproval(true);
            }
            if (superAdmin) {
              setViewShortcut(false);
            }
          } else if (newField?.approvalStatus === "REJECTED") {
            if (tipe2) {
              setViewDokumen(true);
              setViewHistoryAsset(true);
              setViewHistoryMovement(true);
              setViewHistoryDisposal(true);
              setViewHistoryApproval(true);
            }
            if (superAdmin) {
              setViewShortcut(true);
            }
          } else if (
            newField?.approvalStatus === "APPROVED" ||
            newField?.approvalStatus === "APPROVE"
          ) {
            if (tipe2) {
              setViewDokumen(true);
              setViewHistoryAsset(true);
              setViewHistoryMovement(true);
              setViewHistoryDisposal(true);
            }
            if (superAdmin) {
              setViewShortcut(true);
            }
          }
        } else if (newField?.status === "TIDAK_DITEMUKAN") {
          if (newField?.approvalStatus === "WAITING_APPROVAL") {
            if (tipe1) {
              setViewDokumen(true);
            }
            if (tipe2) {
              setViewHistoryAsset(true);
              setViewHistoryMovement(true);
              setViewHistoryDisposal(true);
              setViewHistoryApproval(true);
            }
            if (superAdmin) {
              setViewShortcut(false);
            }
          } else if (newField?.approvalStatus === "REJECTED") {
            if (tipe2) {
              setViewDokumen(true);
              setViewHistoryAsset(true);
              setViewHistoryMovement(true);
              setViewHistoryDisposal(true);
              setViewHistoryApproval(true);
            }
            if (superAdmin) {
              setViewShortcut(true);
            }
          } else if (
            newField?.approvalStatus === "APPROVED" ||
            newField?.approvalStatus === "APPROVE"
          ) {
            if (tipe2) {
              setViewDokumen(true);
              setViewHistoryAsset(true);
              setViewHistoryMovement(true);
              setViewHistoryDisposal(true);
            }
            if (superAdmin) {
              setViewShortcut(true);
            }
          }
        } else if (newField?.status === "WAITING_APPROVAL") {
          if (newField?.approvalStatus === "WAITING_APPROVAL") {
            if (tipe1) {
              setViewDokumen(true);
              // setViewHistoryAsset(true);
              setViewHistoryApproval(true);
            }

            if (tipe2) {
              setViewHistoryAsset(true);
            }
          } else if (newField?.approvalStatus === "REJECTED") {
            if (tipe2) {
              setViewDokumen(true);
            }
            if (tipe1) {
              setViewHistoryAsset(true);
              setViewHistoryApproval(true);
            }
          } else if (
            newField?.approvalStatus === "APPROVED" ||
            newField?.approvalStatus === "APPROVE"
          ) {
          }
        } else if (newField?.status === "REJECTED") {
          if (newField?.approvalStatus === "WAITING_APPROVAL") {
            if (tipe1) {
              setViewDokumen(true);
              setViewHistoryApproval(true);
              setViewHistoryAsset(true);
            }
          } else if (newField?.approvalStatus === "REJECTED") {
            if (tipe2) {
              setViewDokumen(true);
            }
            if (tipe1) {
              setViewHistoryApproval(true);
              setViewHistoryAsset(true);
            }
            // } else if (
            //   newField?.approvalStatus === "APPROVED" ||
            //   newField?.approvalStatus === "APPROVE"
            // ) {
          }
        }
      } else {
        if (
          newField?.status === "ACTIVE" ||
          newField?.status === "NON_ACTIVE" ||
          newField?.status === "IDLE"
        ) {
          if (newField?.approvalStatus === "WAITING_APPROVAL") {
            if (tipe1) {
              setViewDokumen(true);
            }
            if (tipe2) {
              setViewHistoryAsset(true);
              setViewHistoryDisposal(true);
            }
            if (tipe3) {
              setViewHistoryMovement(true);
            }
            if (superAdmin) {
              setViewShortcut(true);
            }
          } else if (newField?.approvalStatus === "REJECTED") {
            if (tipe2) {
              setViewDokumen(true);
              setViewHistoryAsset(true);
              setViewHistoryDisposal(true);
            }
            if (tipe3) {
              setViewHistoryMovement(true);
            }
            if (superAdmin) {
              setViewShortcut(true);
            }
          } else if (
            newField?.approvalStatus === "APPROVED" ||
            newField?.approvalStatus === "APPROVE"
          ) {
            if (tipe2) {
              setViewDokumen(true);
              setViewHistoryAsset(true);
              setViewHistoryDisposal(true);
            }
            if (tipe3) {
              setViewHistoryMovement(true);
            }
            if (superAdmin) {
              setViewShortcut(true);
            }
          }
        } else if (newField?.status === "DISPOSE") {
          if (newField?.approvalStatus === "WAITING_APPROVAL") {
            if (tipe1) {
              setViewDokumen(true);
            }
            if (tipe2) {
              setViewHistoryAsset(true);
              setViewHistoryDisposal(true);
            }
            if (tipe3) {
              setViewHistoryMovement(true);
            }
            if (superAdmin) {
              setViewShortcut(true);
            }
          } else if (newField?.approvalStatus === "REJECTED") {
            if (tipe2) {
              setViewDokumen(true);
              setViewHistoryAsset(true);
              setViewHistoryMovement(true);
              setViewHistoryDisposal(true);
            }
            if (superAdmin) {
              setViewShortcut(true);
            }
          } else if (
            newField?.approvalStatus === "APPROVED" ||
            newField?.approvalStatus === "APPROVE"
          ) {
            if (tipe2) {
              setViewDokumen(true);
              setViewHistoryAsset(true);
              setViewHistoryMovement(true);
              setViewHistoryDisposal(true);
            }
            if (superAdmin) {
              setViewShortcut(true);
            }
          }
        } else if (newField?.status === "TIDAK_DITEMUKAN") {
          if (newField?.approvalStatus === "WAITING_APPROVAL") {
            if (tipe1) {
              setViewDokumen(true);
            }
            if (tipe2) {
              setViewHistoryAsset(true);
              setViewHistoryDisposal(true);
            }
            if (tipe3) {
              setViewHistoryMovement(true);
            }
            if (superAdmin) {
              setViewShortcut(true);
            }
          } else if (newField?.approvalStatus === "REJECTED") {
            if (tipe2) {
              setViewDokumen(true);
              setViewHistoryAsset(true);
              setViewHistoryMovement(true);
              setViewHistoryDisposal(true);
            }
            if (superAdmin) {
              setViewShortcut(true);
            }
          } else if (
            newField?.approvalStatus === "APPROVED" ||
            newField?.approvalStatus === "APPROVE"
          ) {
            if (tipe2) {
              setViewDokumen(true);
              setViewHistoryAsset(true);
              setViewHistoryMovement(true);
              setViewHistoryDisposal(true);
            }
            if (superAdmin) {
              setViewShortcut(true);
            }
          }
        } else if (newField?.status === "WAITING_APPROVAL") {
          if (newField?.approvalStatus === "WAITING_APPROVAL") {
          } else if (newField?.approvalStatus === "REJECTED") {
          } else if (
            newField?.approvalStatus === "APPROVED" ||
            newField?.approvalStatus === "APPROVE"
          ) {
          }
        }
      }
      if (
        [
          "VP_GA",
          "HEAD_GA",
          "OFFICE_VENDOR_MANAGEMENT",
          "HEAD_DEPT",
          "VP_DEPT",
          "VP_ACC_TAX",
          "VP_IT_SECURITY",
          "DEPT_ACC_TAX",
          "HEAD_IT_SECURITY",
        ].includes(localStorage.getItem("role_id"))
      ) {
        setViewShortcut(false);
        setViewHistoryAsset(true);
      }
    }
  };

  const CheckPermission = async () => {
    const newData = await checkPermission(id);
    if (newData?.data?.read === true) {
      getData();
    } else {
      navigate("/fordibben");
    }
  };

  useEffect(() => {
    CheckPermission();
  }, []);

  return (
    <AdminLayout>
      <Card
        title={"Detail Asset"}
        className={styles.cardTable}
        extra={
          <Space>
            {viewShortcut &&
              !window.location.href.includes("registered-asset") && (
                <ButtonMovementDisposal id={id} data={data} />
              )}
            <Button
              icon={<ArrowLeftOutlined />}
              type="primary"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </Space>
        }
      >
        <Form form={form}>
          <Row gutter={40}>
            <Col className={styles.detailRow} xs={24} sm={24} md={12} lg={12}>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Asset Name</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="name">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>CIP Number</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="cip_number">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Fixed Asset Number</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="asset_fixed_number">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>

              {!window.location.href.includes("registered-asset") && (
                <Row gutter={10} align={"middle"}>
                  <Col xs={24} sm={24} md={24} lg={6}>
                    <Text>Generate Code</Text>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={16}>
                    <Form.Item name="generatedCode">
                      <Input readOnly={true} />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Insurance</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="insurance">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Insurance Period Start</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="insurance_period_start">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Insurance Period End</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="insurance_period_end">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Location</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="location">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Location Notes</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="location_note">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col className={styles.detailRow} xs={24} sm={24} md={12} lg={12}>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>PO Number</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="po_number">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Serial Number</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="serialNumber">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>

              {!window.location.href.includes("registered-asset") && (
                <Row gutter={10} align={"middle"}>
                  <Col xs={24} sm={24} md={24} lg={6}>
                    <Text>Tanggal BAST</Text>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={16}>
                    <Form.Item name="bast_date">
                      <Input readOnly={true} />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Unit Name</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="unit_name">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>

              {!window.location.href.includes("registered-asset") && (
                <Row gutter={10} align={"middle"}>
                  <Col xs={24} sm={24} md={24} lg={6}>
                    <Text>Unit To</Text>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={16}>
                    <Form.Item name="unit_to">
                      <Input readOnly={true} />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Vendor Name</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="vendor_name">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              {data?.room?.has_rack && (
                <>
                  <Row gutter={10} align={"middle"}>
                    <Col xs={24} sm={24} md={24} lg={6}>
                      <Text>Rack ID</Text>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={16}>
                      <Form.Item name="rack_id">
                        <Input readOnly={true} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10} align={"middle"}>
                    <Col xs={24} sm={24} md={24} lg={6}>
                      <Text>Location U</Text>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={16}>
                      <Form.Item name="location_u">
                        <Input readOnly={true} />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              <Row gutter={10} align={"middle"}></Row>

              {!window.location.href.includes("registered-asset") ? (
                <Row gutter={10} align={"middle"}>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={6}
                    style={{ marginTop: "5px" }}
                  >
                    <Text>Borrowing Status</Text>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={16}>
                    <Tag
                      style={{
                        borderRadius: "20px",
                        minWidth: "120px",
                        textAlign: "center",
                      }}
                      color={
                        data.borrowing_movement_id
                          ? StatusApprovalList("APPROVED")
                          : ""
                      }
                    >
                      {data.borrowing_movement_id ? "BORROWED" : "-"}
                    </Tag>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={6}
                    style={{ marginTop: "5px" }}
                  >
                    <Text>Movement Status</Text>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={16}>
                    <Tag
                      style={{
                        borderRadius: "20px",
                        minWidth: "120px",
                        textAlign: "center",
                      }}
                      color={
                        data.used_in_movement
                          ? StatusApprovalList("APPROVED")
                          : ""
                      }
                    >
                      {data.used_in_movement ? "MOVEMENT" : "-"}
                    </Tag>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={6}>
                    <Text>Status Asset</Text>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={16}>
                    <Tag
                      style={{
                        borderRadius: "20px",
                        minWidth: "120px",
                        textAlign: "center",
                      }}
                      color={StatusList(data.status)}
                    >
                      {data.status}
                    </Tag>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={6}
                    style={{ marginTop: "5px" }}
                  >
                    <Text>Status Approval</Text>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={16}>
                    <Tag
                      style={{
                        borderRadius: "20px",
                        minWidth: "120px",
                        textAlign: "center",
                      }}
                      color={StatusApprovalList(data.approvalStatus)}
                    >
                      {data.approvalStatus}
                    </Tag>
                  </Col>
                </Row>
              ) : (
                <Row gutter={10} align={"middle"}>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={6}
                    style={{ marginTop: "5px" }}
                  >
                    <Text>Status Approval</Text>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={16}>
                    <Tag
                      style={{
                        borderRadius: "20px",
                        minWidth: "120px",
                        textAlign: "center",
                      }}
                      color={StatusApprovalList(data.approvalStatus)}
                    >
                      {data.approvalStatus}
                    </Tag>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Form>
      </Card>
      <Card title={"Gambar Asset"} className={styles.cardTable}>
        <Row gutter={40}>
          <Col className={styles.detailRow} span={24}>
            <Image.PreviewGroup>
              <Space size={"large"} wrap={true}>
                {data?.images &&
                  data?.images.map((item, index) => (
                    <Image
                      key={index}
                      width={200}
                      src={process.env.REACT_APP_IMAGE_URL + "/" + item.name}
                    />
                  ))}
              </Space>
            </Image.PreviewGroup>
          </Col>
        </Row>
      </Card>
      {viewDokumen && (
        <CardDokumenAsset
          id={id}
          data={data}
          getData={getData}
          action={deleteDocumentAsset}
        />
      )}
      <CardRiwayatApproval data={data} path={(window.location.href.includes("registered-asset") ? "registered-asset" : "daftar-asset")} />
      <CardRiwayatRegistration data={data} />
      <CardRiwayatMovement id={id} data={data} />
      <CardRiwayatDisposal id={id} data={data} />
    </AdminLayout>
  );
}

export default DetailAsset;
