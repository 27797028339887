import React from "react";
import { Col, Form, Row, Input, Select } from "antd";

function AddUpdate(props) {

  return (
    <Form
      form={props.form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      labelAlign={"left"}
    >
      <Row gutter={[0, 15]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="code"
            label="Kode"
            rules={[
              {
                required: true,
                message: "Please input code!",
              },
            ]}
          >
            <Input placeholder="Kode" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="name"
            label="Nama"
            rules={[
              {
                required: true,
                message: "Please input name!",
              },
            ]}
          >
            <Input placeholder="Nama" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="root_id"
            label="Root ID"
          >
            <Input placeholder="Root ID" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="level"
            label="Level"
          >
            <Input placeholder="Level" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="is_active_flag"
            label="Status"
            rules={[
              {
                required: true,
                message: "Please select status!",
              }
            ]}
          >
            <Select
              placeholder="Select status"
            >
              <Select.Option value={true}>Aktif</Select.Option>
              <Select.Option value={false}>Non Aktif</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default AddUpdate;
