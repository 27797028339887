import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AssetTable from "../../Components/Global/AssetTable";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import MasterContext from "../../Services/Master";

function RegisteredAsset(props) {
  const { getAllMaster } = useContext(MasterContext);
  const [params, setParams] = useState({
    // cross_approval: false,
    asset_from_another_unit: true,
  });
  const [checkSo, setCheckSo] = useState(false);
  let navigate = useNavigate();

  const checkSO = async () => {
    const newData = await getAllMaster("stock-opname/check");
    if (newData?.data?.is_so_active) {
      navigate("/stock-opname/stock-opname");
    } else {
      setCheckSo(true);
    }
  };

  useEffect(() => {
    checkSO();
  }, []);

  return (
    <AdminLayout>
      {checkSo && (
        <AssetTable
          type={"registered-asset"}
          params={params}
          setParams={setParams}
        />
      )}
    </AdminLayout>
  );
}

export default RegisteredAsset;
