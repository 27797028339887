import { Button, Form, Input, Popconfirm, Select, Table } from "antd";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import AssetContext from "../../../Services/Asset";
import MasterContext from "../../../Services/Master";

const EditableContext = createContext(null);

function TableMovement(props) {  
  let { id } = useParams();
  const [count, setCount] = useState(0);
  const { getDetailAsset } = useContext(AssetContext);
  const { getMaster } = useContext(MasterContext);
  const [dataDetail, setDataDetail] = useState([]);

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };
  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);
    const toggleEdit = async () => {
      setEditing(!editing);
      const typeInput =
        dataIndex === "name"
          ? "asset_id"
          : dataIndex === "location_to"
          ? "room_id"
          : dataIndex === "new_location_to"
          ? "new_room_id"
          : dataIndex === "new_asset_name"
          ? "new_asset_id"
          : dataIndex;
      form.setFieldsValue({
        [typeInput]:
          dataIndex === "name"
            ? record?.asset_id
            : dataIndex === "location_to"
            ? record?.room_id
            : dataIndex === "new_location_to"
            ? record?.new_room_id
            : dataIndex === "new_asset_name"
            ? record?.new_asset_id
            : record[dataIndex],
      });
    };
    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave(
          {
            ...record,
            ...values,
          },
          record
        );
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };
    let childNode = children;
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={
            dataIndex === "name"
              ? "asset_id"
              : dataIndex === "location_to"
              ? "room_id"
              : dataIndex === "new_location_to"
              ? "new_room_id"
              : dataIndex === "new_asset_name"
              ? "new_asset_id"
              : dataIndex
          }
          rules={[
            {
              required: dataIndex === "remark" ? false : true,
              message: `${title} is required.`,
            },
          ]}
        >
          {dataIndex === "remark" ||
          dataIndex === "rack_id" ||
          dataIndex === "location_u" ||
          dataIndex === "new_rack_id" ||
          dataIndex === "new_location_u" ? (
            <Input
              onBlur={save}
              type={
                (dataIndex === "rack_id" || dataIndex === "new_rack_id") &&
                "number"
              }
              min={
                (dataIndex === "rack_id" || dataIndex === "new_rack_id") && 0
              }
              onPressEnter={save}
              ref={inputRef}
            />
          ) : dataIndex === "name" ||
            dataIndex === "new_asset_name" ||
            dataIndex === "location_to" ||
            dataIndex === "new_location_to" ? (
            <Select
              showSearch
              placeholder={
                dataIndex === "name" || dataIndex === "new_asset_name"
                  ? "Select asset"
                  : "Select location"
              }
              options={
                dataIndex === "name" || dataIndex === "new_asset_name"
                  ? props?.dataAsset
                  : props?.dataLocation
              }
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              ref={inputRef}
              onBlur={save}
              onSelect={save}
            />
          ) : (
            <Select
              placeholder={"Select Plan"}
              options={[
                {
                  label: "IDLE",
                  value: "IDLE",
                },
                {
                  label: "NON ACTIVE",
                  value: "NON_ACTIVE",
                },
                {
                  label: "ACTIVE",
                  value: "ACTIVE",
                },
                {
                  label: "TIDAK DITEMUKAN",
                  value: "TIDAK_DITEMUKAN",
                },
              ]}
              ref={inputRef}
              onBlur={save}
              onSelect={save}
            />
          )}
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  };

  const replaceDefaultColumns = [
    {
      title: "Nama Asset",
      dataIndex: "name",
      width: "20%",
      editable: !id,
    },
    {
      title: "Fixed Asset Number",
      dataIndex: "fixed_asset_number",
    },
    {
      title: "Serial Number",
      dataIndex: "serial_number",
    },
    {
      title: "Location From",
      dataIndex: "location_from",
      width: "15%",
    },
    {
      title: "Rack ID From",
      dataIndex: "rack_id_from",
    },
    {
      title: "Location U From",
      dataIndex: "location_u_from",
    },
    {
      title: "Location To",
      dataIndex: "location_to",
      width: "15%",
      editable: true,
    },
    {
      title: "Rack ID To",
      dataIndex: "rack_id",
      editable: true,
    },
    {
      title: "Location U To",
      dataIndex: "location_u",
      editable: true,
    },
    {
      title: "Old Asset Plan",
      dataIndex: "old_asset_plan",
      editable: true,
    },
    {
      title: "New Asset",
      dataIndex: "new_asset_name",
      width: "20%",
      editable: true,
    },
    {
      title: "New Asset Location To",
      dataIndex: "new_location_to",
      width: "15%",
      editable: true,
    },
    {
      title: "New Asset Rack ID To",
      dataIndex: "new_rack_id",
      editable: true,
    },
    {
      title: "New Asset Location U To",
      dataIndex: "new_location_u",
      editable: true,
    },
    {
      title: "Remark",
      dataIndex: "remark",
      width: "10%",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) =>
        dataDetail.length >= 1 && !id ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <DeleteOutlined style={{ color: "#bd3f39", fontSize: "24px" }} />
          </Popconfirm>
        ) : null,
    },
  ];

  const borrowingDefaultColumns = [
    {
      title: "Nama Asset",
      dataIndex: "name",
      width: "20%",
      editable: !id,
    },
    {
      title: "Fixed Asset Number",
      dataIndex: "fixed_asset_number",
    },
    {
      title: "Serial Number",
      dataIndex: "serial_number",
    },
    {
      title: "Location From",
      dataIndex: "location_from",
      width: "15%",
    },
    {
      title: "Location To",
      dataIndex: "location_to",
      width: "15%",
      editable: true,
    },
    {
      title: "Rack ID",
      dataIndex: "rack_id",
      editable: true,
    },
    {
      title: "Location U",
      dataIndex: "location_u",
      editable: true,
    },
    {
      title: "Remark",
      dataIndex: "remark",
      width: "10%",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) =>
        dataDetail.length >= 1 && !id ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <DeleteOutlined style={{ color: "#bd3f39", fontSize: "24px" }} />
          </Popconfirm>
        ) : null,
    },
  ];

  const defaultColumns = [
    {
      title: "Nama Asset",
      dataIndex: "name",
      width: "20%",
      editable: !id,
    },
    {
      title: "Fixed Asset Number",
      dataIndex: "fixed_asset_number",
    },
    {
      title: "Serial Number",
      dataIndex: "serial_number",
    },
    {
      title: "Location From",
      dataIndex: "location_from",
      width: "15%",
    },
    {
      title: "Rack ID From",
      dataIndex: "rack_id_from",
    },
    {
      title: "Location U From",
      dataIndex: "location_u_from",
    },
    {
      title: "Location To",
      dataIndex: "location_to",
      width: "15%",
      editable: true,
    },
    {
      title: "Rack ID",
      dataIndex: "rack_id",
      editable: true,
    },
    {
      title: "Location U",
      dataIndex: "location_u",
      editable: true,
    },
    {
      title: "Remark",
      dataIndex: "remark",
      width: "10%",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) =>      
        dataDetail.length && !id >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <DeleteOutlined style={{ color: "#bd3f39", fontSize: "24px" }} />
          </Popconfirm>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData = {
      key: count,
      name: `Please Select`,
      asset_id: "",
      unit: "",
      fixed_asset_number: "",
      serial_number: "",
      po_number: "",
      location_from: "",
      remark: "",
      location_to: "Please Select ",
      new_location_to: "Please Select ",
      new_asset_name: `Please Select`,
      new_asset_id: "",
      room_from_id: "",
      rack_id_from: "",
      location_u_from: "",
      room_id: "",
      new_room_id: "",
      rack_id: "",
      location_u: "",
      new_rack_id: "",
      new_location_u: "",
    };
    setDataDetail([...dataDetail, newData]);
    setCount(count + 1);
  };

  const handleDelete = (key) => {
    const newData = dataDetail.filter((item) => item.key !== key);
    setDataDetail(newData);
  };

  const handleSave = async (row, editable) => {
    const newData = [...dataDetail];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];

    if (row.asset_id !== dataDetail[row.key]?.asset_id) {
      const getDetail = await getDetailAsset(row.asset_id);
      if (getDetail) {
        row.name = getDetail.data?.name || "Please Select";
        row.fixed_asset_number = getDetail.data?.fixed_asset_number;
        row.po_number = getDetail.data?.asset_header?.po_number;
        row.location_from =
          getDetail.data?.room?.name +
          " " +
          getDetail.data?.room?.floor?.name +
          " " +
          getDetail.data?.room?.floor?.building?.name;
        row.serial_number = getDetail.data?.serialNumber;
        row.location_u_from = getDetail.data?.location_u;
        row.rack_id_from = getDetail.data?.rack_id;
        row.unit = getDetail.data?.unit?.name;
        row.room_from_id = getDetail.data?.room_id;
        newData.splice(index, 1, { ...item, ...row });
        setDataDetail(newData);
      }
    } else if (row.new_asset_id !== dataDetail[row.key]?.new_asset_id) {
      const getDetail = await getDetailAsset(row.new_asset_id);
      if (getDetail) {
        row.new_asset_name =
          getDetail.data?.name + " - " + getDetail.data?.serialNumber ||
          "Please Select";
        row.location_u = getDetail.data?.location_u;
        row.rack_id = getDetail.data?.rack_id;
        newData.splice(index, 1, { ...item, ...row });
        setDataDetail(newData);
      }
    } else if (row.room_id !== dataDetail[row.key]?.room_id) {
      const getLocation = await getMaster("rooms", row.room_id);
      if (getLocation) {
        row.location_to = getLocation?.data?.name
          ? getLocation?.data?.name +
            " " +
            getLocation?.data?.floor?.name +
            " " +
            getLocation?.data?.floor?.building?.name
          : "Please Select";
        newData.splice(index, 1, { ...item, ...row });
        setDataDetail(newData);
      }
    } else if (row.new_room_id !== dataDetail[row.key]?.new_room_id) {
      const getLocation = await getMaster("rooms", row.new_room_id);
      if (getLocation) {
        row.new_location_to = getLocation?.data?.name
          ? getLocation?.data?.name +
            " " +
            getLocation?.data?.floor?.name +
            " " +
            getLocation?.data?.floor?.building?.name
          : "Please Select";
        newData.splice(index, 1, { ...item, ...row });
        setDataDetail(newData);
      }
    } else {
      newData.splice(index, 1, { ...item, ...row });
      setDataDetail(newData);
    }
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const borrowingColumns = borrowingDefaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const replaceColumns = replaceDefaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  useEffect(() => {
    props?.setDataSource && props?.setDataSource(dataDetail);
  }, [dataDetail]);

  const addFromAsset = async () => {
    const getDetail = await getDetailAsset(id);
    if (getDetail) {
      const newData = {
        key: count,
        name: getDetail.data?.name || "Please Select",
        asset_id: getDetail.data?.id,
        fixed_asset_number: getDetail.data?.fixed_asset_number,
        serial_number: getDetail.data?.serialNumber,
        po_number: getDetail.data?.asset_header?.po_number,
        location_from:
          getDetail.data?.room?.name +
          " " +
          getDetail.data?.room?.floor?.name +
          " " +
          getDetail.data?.room?.floor?.building?.name,
        remark: "",
        location_to: "Please Select ",
        new_location_to: "Please Select ",
        new_asset_name: `Please Select`,
        new_asset_id: "",
        room_from_id: "",
        rack_id_from: getDetail.data?.rack_id,
        location_u_from: getDetail.data?.location_u,
        room_id: "",
        new_room_id: "",
        rack_id: 0,
        location_u: "",
        new_rack_id: "",
        new_location_u: "",
      };
      setDataDetail([...dataDetail, newData]);
      setCount(count + 1);
    }
  };

  useEffect(() => {
    if (id) {
      addFromAsset();
    }
  }, [id]);

  return (
    <div style={{ minWidth: "2500px" }}>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={props?.dataSource}
        columns={
          props?.type?.type === "REPLACE"
            ? replaceColumns
            : props?.type?.type === "BORROWING"
            ? borrowingColumns
            : columns
        }
        pagination={false}
      />
      {
        !id && (
          <Button
            onClick={handleAdd}
            type="primary"
            style={{
              margin: "10px 0",
            }}
            icon={<PlusCircleOutlined />}
          >
            Add new asset
          </Button>
        )
      }
      
    </div>
  );
}

export default TableMovement;
