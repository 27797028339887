const StatusList = (data) => {
  if (data === "ACTIVE") {
    return "#3AD647";
  } else if (data === "NON_ACTIVE") {
    return "#FA3737";
  } else if (data === "WAITING_APPROVAL") {
    return "#FFCD27";
  } else if(data === "IDLE") {
    return "#3498DB";
  } else {
    return "#DED8D8";
  }
};

export default StatusList;