import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  EllipsisOutlined,
  DownloadOutlined,
  DeleteOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "./AssetMovement.module.css";
import AssetContext from "../../Services/Asset";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal from "../../Components/Global/Modal";
import UploadDokumen from "./uploadDokumen";
moment.locale("id");

const { Text } = Typography;

const statusApproval = (data) => {
  if (data === "APPROVE") {
    return "#3AD647";
  } else if (data === "REJECTED") {
    return "#FA3737";
  } else if (data === "WAITING_APPROVAL") {
    return "#FFCD27";
  } else {
    return "#DED8D8";
  }
};

function DetailAssetMovement(props) {
  let { id } = useParams();
  let navigate = useNavigate();
  const { getAssetMovement, deleteDocumentMovement } = useContext(AssetContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewDokumen, setViewDokumen] = useState(false);
  const [viewDokumenDiterima, setViewDokumenDiterima] = useState(false);
  const [uploadDokumen, setUploadDokumen] = useState(false);
  const [form] = Form.useForm();
  const [formModal] = Form.useForm();
  const [modal, setModal] = useState({
    title: "",
    open: false,
    loading: false,
    onOk: () => {},
    body: "",
  });
  const [modalUploadDokumen, setModalUploadDokumen] = useState({
    open: false,
    id: "",
    po_number: "",
    document: [],
  });

  const columns = [
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
      width: 800,
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
    },
    {
      key: "action",
      width: 100,
      render: (data) =>
        uploadDokumen && (
          <Button
            onClick={() =>
              setModalUploadDokumen({
                open: true,
                po_number: data.poNumber,
                id: id,
                documents:
                  data?.details &&
                  data?.details.length > 0 &&
                  data?.details[0]?.documents,
              })
            }
            type="primary"
          >
            Upload Dokumen
          </Button>
        ),
    },
  ];

  const childrenColumns = [
    {
      title: "Nama Asset",
      dataIndex: "asset",
      key: "name",
      width: 200,
      sorter: (a, b) => a.asset?.name.localeCompare(b.asset?.name),
      render: (data) => (
        <Link to={`/daftar-asset/detail/${data?.id}`}>{data?.name}</Link>
      ),
    },
    {
      title: "Unit From",
      dataIndex: "unitFrom",
      key: "unit",
      width: 150,
      sorter: (a, b) => a.unitFrom?.name.localeCompare(b.unitFrom?.name),
      render: (data) => data?.name,
    },
    {
      title: "Unit To",
      dataIndex: "unitTo",
      key: "unit",
      width: 150,
      sorter: (a, b) => a.unitTo?.name.localeCompare(b.unitTo?.name),
      render: (data) => data?.name,
    },
    {
      title: "Fixed Asset Number",
      dataIndex: "asset",
      key: "fixed_asset_number",
      width: 100,
      sorter: (a, b) =>
        a.asset?.fixed_asset_number.localeCompare(b.asset?.fixed_asset_number),
      render: (data) => data?.fixed_asset_number,
    },
    {
      title: "Serial Number",
      dataIndex: "asset",
      key: "serial_number",
      width: 100,
      sorter: (a, b) =>
        a.asset?.serialNumber.localeCompare(b.asset?.serialNumber),
      render: (data) => data?.serialNumber,
    },
    {
      title: "Location From",
      dataIndex: "roomFrom",
      key: "location_from",
      width: 200,
      sorter: (a, b) =>
        a.asset?.roomFrom?.name.localeCompare(b.asset?.roomFrom?.name),
      render: (data) =>
        `${data?.name} - ${data?.floor?.name} - ${data?.floor?.building?.name}`,
    },
    {
      title: "Rack ID From",
      dataIndex: "asset",
      key: "rack_id_from",
      width: 100,
      sorter: (a, b) => a.asset?.rack_id.localeCompare(b.asset?.rack_id),
      render: (data) => data?.rack_id,
    },
    {
      title: "Location U From",
      dataIndex: "locationU",
      key: "location_u_from",
      width: 100,
      sorter: (a, b) => a.asset?.location_u.localeCompare(b.asset?.location_u),
    },
    {
      title: "Location To",
      dataIndex: "room",
      key: "location_to",
      width: 200,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (data) =>
        `${data?.name} - ${data?.floor?.name} - ${data?.floor?.building?.name}`,
    },
    {
      title: "Rack ID To",
      dataIndex: "rackId",
      key: "rack_id",
      width: 100,
      sorter: (a, b) => a.rack_id.localeCompare(b.rack_id),
    },
    {
      title: "Location U To",
      dataIndex: "locationU",
      key: "location_u",
      width: 100,
      sorter: (a, b) => a.location_u.localeCompare(b.location_u),
    },
    {
      title: "Remark",
      dataIndex: "description",
      key: "remark",
      width: 150,
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      key: "action",
      width: 10,
      render: (newData) => actionList(newData),
    },
  ];

  const childrenBorrowingColumns = [
    {
      title: "Nama Asset",
      dataIndex: "asset",
      key: "name",
      width: 200,
      sorter: (a, b) => a.asset?.name.localeCompare(b.asset?.name),
      render: (data) => (
        <Link to={`/daftar-asset/detail/${data?.id}`}>{data?.name}</Link>
      ),
    },
    {
      title: "Unit From",
      dataIndex: "unitFrom",
      key: "unit",
      width: 150,
      sorter: (a, b) => a.unitFrom?.name.localeCompare(b.unitFrom?.name),
      render: (data) => data?.name,
    },
    {
      title: "Unit To",
      dataIndex: "unitTo",
      key: "unit",
      width: 150,
      sorter: (a, b) => a.unitTo?.name.localeCompare(b.unitTo?.name),
      render: (data) => data?.name,
    },
    {
      title: "Fixed Asset Number",
      dataIndex: "asset",
      key: "fixed_asset_number",
      width: 100,
      sorter: (a, b) =>
        a.asset?.fixed_asset_number.localeCompare(b.asset?.fixed_asset_number),
      render: (data) => data?.fixed_asset_number,
    },
    {
      title: "Serial Number",
      dataIndex: "asset",
      key: "serial_number",
      width: 100,
      sorter: (a, b) =>
        a.asset?.serialNumber.localeCompare(b.asset?.serialNumber),
      render: (data) => data?.serialNumber,
    },
    {
      title: "Location From",
      dataIndex: "roomFrom",
      key: "location_from",
      width: 200,
      sorter: (a, b) =>
        a.asset?.roomFrom?.name.localeCompare(b.asset?.roomFrom?.name),
      render: (data) =>
        `${data?.name} - ${data?.floor?.name} - ${data?.floor?.building?.name}`,
    },
    {
      title: "Rack ID From",
      dataIndex: "new_asset_rack_id",
      key: "new_asset_rack_id",
      width: 100,
      sorter: (a, b) => a.asset?.rack_id.localeCompare(b.asset?.rack_id),
    },
    {
      title: "Location U From",
      dataIndex: "location_u_from",
      key: "location_u_from",
      width: 100,
      sorter: (a, b) => a.location_u.localeCompare(b.location_u),
    },
    {
      title: "Location To",
      dataIndex: "room",
      key: "location_to",
      width: 200,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (data) =>
        `${data?.name} - ${data?.floor?.name} - ${data?.floor?.building?.name}`,
    },
    {
      title: "Rack ID To",
      dataIndex: "rackId",
      key: "rack_id",
      width: 100,
      sorter: (a, b) => (a.rackId || "").localeCompare(b.rackId || ""),
    },
    {
      title: "Location U To",
      dataIndex: "locationU",
      key: "location_u",
      width: 100,
      sorter: (a, b) => a.asset?.location_u.localeCompare(b.asset?.location_u),
    },
    {
      title: "Remark",
      dataIndex: "description",
      key: "remark",
      width: 150,
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Borrowing Return Date Old",
      dataIndex: "borrowingReturnDateOld",
      key: "borrowingReturnDateOld",
      width: 150,
      sorter: (a, b) =>
        a.borrowingReturnDateOld.localeCompare(b.borrowingReturnDateOld),
      render: (data) => data && moment(data).format("LL"),
    },
    {
      key: "action",
      width: 10,
      render: (newData) => actionList(newData),
    },
  ];

  const childrenBorrowingRenewalColumns = [
    {
      title: "Nama Asset",
      dataIndex: "asset",
      key: "name",
      width: 200,
      sorter: (a, b) => a.asset?.name.localeCompare(b.asset?.name),
      render: (data) => (
        <Link to={`/daftar-asset/detail/${data?.id}`}>{data?.name}</Link>
      ),
    },
    {
      title: "Fixed Asset Number",
      dataIndex: "asset",
      key: "fixed_asset_number",
      width: 100,
      sorter: (a, b) =>
        a.asset?.fixed_asset_number.localeCompare(b.asset?.fixed_asset_number),
      render: (data) => data?.fixed_asset_number,
    },
    {
      title: "Location From",
      dataIndex: "roomFrom",
      key: "location_from",
      width: 200,
      sorter: (a, b) =>
        a.asset?.roomFrom?.name.localeCompare(b.asset?.roomFrom?.name),
      render: (data) =>
        `${data?.name} - ${data?.floor?.name} - ${data?.floor?.building?.name}`,
    },
    {
      title: "Rack ID From",
      dataIndex: "new_asset_rack_id",
      key: "new_asset_rack_id",
      width: 100,
      sorter: (a, b) => a.asset?.rack_id.localeCompare(b.asset?.rack_id),
    },
    {
      title: "Location U From",
      dataIndex: "location_u_from",
      key: "location_u_from",
      width: 100,
      sorter: (a, b) => a.location_u.localeCompare(b.location_u),
    },
    {
      title: "Location To",
      dataIndex: "room",
      key: "location_to",
      width: 200,
      sorter: (a, b) => a?.room?.name.localeCompare(b?.room?.name),
      render: (data) =>
        `${data?.name} - ${data?.floor?.name} - ${data?.floor?.building?.name}`,
    },
    {
      title: "Rack ID To",
      dataIndex: "rackId",
      key: "rack_id",
      width: 100,
      sorter: (a, b) => (a.rackId || "").localeCompare(b.rackId || ""),
    },
    {
      title: "Location U To",
      dataIndex: "locationU",
      key: "location_u",
      width: 100,
      sorter: (a, b) => a.asset?.location_u.localeCompare(b.asset?.location_u),
    },
    {
      title: "Unit From",
      dataIndex: "unitFrom",
      key: "unit",
      width: 150,
      sorter: (a, b) => a.unitFrom?.name.localeCompare(b.unitFrom?.name),
      render: (data) => data?.name,
    },
    {
      title: "Unit To",
      dataIndex: "unitTo",
      key: "unit",
      width: 150,
      sorter: (a, b) => a.unitTo?.name.localeCompare(b.unitTo?.name),
      render: (data) => data?.name,
    },
    {
      title: "Serial Number",
      dataIndex: "asset",
      key: "serial_number",
      width: 100,
      sorter: (a, b) =>
        a.asset?.serialNumber.localeCompare(b.asset?.serialNumber),
      render: (data) => data?.serialNumber,
    },
    {
      title: "Remark",
      dataIndex: "description",
      key: "remark",
      width: 150,
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Borrowing Renewal Date",
      dataIndex: "borrowingRenewalDate",
      key: "borrowingRenewalDate",
      width: 150,
      sorter: (a, b) =>
        a.borrowingRenewalDate.localeCompare(b.borrowingRenewalDate),
      render: (data) => data && moment(data).format("LL"),
    },
    {
      title: "Borrowing Return Date Old",
      dataIndex: "borrowingReturnDateOld",
      key: "borrowingReturnDateOld",
      width: 150,
      sorter: (a, b) =>
        a.borrowingReturnDateOld.localeCompare(b.borrowingReturnDateOld),
      render: (data) => data && moment(data).format("LL"),
    },
    {
      key: "action",
      width: 10,
      render: (newData) => actionList(newData),
    },
  ];

  const childrenBorrowingReturnColumns = [
    {
      title: "Nama Asset",
      dataIndex: "asset",
      key: "name",
      width: 200,
      sorter: (a, b) => a.asset?.name.localeCompare(b.asset?.name),
      render: (data) => (
        <Link to={`/daftar-asset/detail/${data?.id}`}>{data?.name}</Link>
      ),
    },
    {
      title: "Fixed Asset Number",
      dataIndex: "asset",
      key: "fixed_asset_number",
      width: 100,
      sorter: (a, b) =>
        a.asset?.fixed_asset_number.localeCompare(b.asset?.fixed_asset_number),
      render: (data) => data?.fixed_asset_number,
    },
    {
      title: "Unit From",
      dataIndex: "unitFrom",
      key: "unit",
      width: 150,
      sorter: (a, b) => a.unitFrom?.name.localeCompare(b.unitFrom?.name),
      render: (data) => data?.name,
    },
    {
      title: "Unit To",
      dataIndex: "unitTo",
      key: "unit",
      width: 150,
      sorter: (a, b) => a.unitTo?.name.localeCompare(b.unitTo?.name),
      render: (data) => data?.name,
    },
    {
      title: "Location From",
      dataIndex: "room",
      key: "location_to",
      width: 200,
      sorter: (a, b) =>
        a.asset?.roomFrom?.name.localeCompare(b.asset?.roomFrom?.name),
      render: (data) =>
        `${data?.name} - ${data?.floor?.name} - ${data?.floor?.building?.name}`,
    },
    {
      title: "Rack ID From",
      dataIndex: "rackId",
      key: "rack_id",
      width: 100,
      sorter: (a, b) => a.new_asset_rack_id - b.new_asset_rack_id,
    },
    {
      title: "Location U From",
      dataIndex: "locationU",
      key: "location_u",
      width: 100,
      sorter: (a, b) => a.asset?.location_u.localeCompare(b.asset?.location_u),
    },
    {
      title: "Location To",
      dataIndex: "roomFrom",
      key: "location_from",
      width: 200,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (data) =>
        `${data?.name} - ${data?.floor?.name} - ${data?.floor?.building?.name}`,
    },
    {
      title: "Rack ID To",
      dataIndex: "new_asset_rack_id",
      key: "new_asset_rack_id",
      width: 100,
      sorter: (a, b) => a.rack_id.localeCompare(b.rack_id),
    },
    {
      title: "Location U To",
      dataIndex: "locationU",
      key: "location_u_from",
      width: 100,
      sorter: (a, b) => a.location_u.localeCompare(b.location_u),
    },
    {
      title: "Serial Number",
      dataIndex: "asset",
      key: "serial_number",
      width: 100,
      sorter: (a, b) =>
        a.asset?.serialNumber.localeCompare(b.asset?.serialNumber),
      render: (data) => data?.serialNumber,
    },
    {
      title: "Remark",
      dataIndex: "description",
      key: "remark",
      width: 150,
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Borrowing Return Date Old",
      dataIndex: "borrowingReturnDateOld",
      key: "borrowingReturnDateOld",
      width: 150,
      sorter: (a, b) =>
        a.borrowingReturnDateOld.localeCompare(b.borrowingReturnDateOld),
      render: (data) => data && moment(data).format("LL"),
    },
    {
      key: "action",
      width: 10,
      render: (newData) => actionList(newData),
    },
  ];

  const childrenMutationColumns = [
    {
      title: "Nama Asset",
      dataIndex: "asset",
      key: "name",
      width: 200,
      sorter: (a, b) => a.asset?.name.localeCompare(b.asset?.name),
      render: (data) => (
        <Link to={`/daftar-asset/detail/${data?.id}`}>{data?.name}</Link>
      ),
    },
    {
      title: "Unit From",
      dataIndex: "unitFrom",
      key: "unit",
      width: 150,
      sorter: (a, b) => a.unitFrom?.name.localeCompare(b.unitFrom?.name),
      render: (data) => data?.name,
    },
    {
      title: "Unit To",
      dataIndex: "unitTo",
      key: "unit",
      width: 150,
      sorter: (a, b) => a.unitTo?.name.localeCompare(b.unitTo?.name),
      render: (data) => data?.name,
    },
    {
      title: "Fixed Asset Number",
      dataIndex: "asset",
      key: "fixed_asset_number",
      width: 100,
      sorter: (a, b) =>
        a.asset?.fixed_asset_number.localeCompare(b.asset?.fixed_asset_number),
      render: (data) => data?.fixed_asset_number,
    },
    {
      title: "Serial Number",
      dataIndex: "asset",
      key: "serial_number",
      width: 100,
      sorter: (a, b) =>
        a.asset?.serialNumber.localeCompare(b.asset?.serialNumber),
      render: (data) => data?.serialNumber,
    },
    {
      title: "Location From",
      dataIndex: "roomFrom",
      key: "location_from",
      width: 200,
      sorter: (a, b) =>
        a.asset?.roomFrom?.name.localeCompare(b.asset?.roomFrom?.name),
      render: (data) =>
        `${data?.name} - ${data?.floor?.name} - ${data?.floor?.building?.name}`,
    },
    {
      title: "Rack ID From",
      dataIndex: "asset",
      key: "rack_id_from",
      width: 100,
      sorter: (a, b) => a.asset?.rack_id.localeCompare(b.asset?.rack_id),
      render: (data) => data?.rack_id,
    },
    {
      title: "Location U From",
      dataIndex: "locationU",
      key: "location_u_from",
      width: 100,
      sorter: (a, b) => a.asset?.location_u.localeCompare(b.asset?.location_u),
    },
    {
      title: "Location To",
      dataIndex: "room",
      key: "location_to",
      width: 200,
      sorter: (a, b) => a?.room?.name.localeCompare(b?.room?.name),
      render: (data) =>
        `${data?.name} - ${data?.floor?.name} - ${data?.floor?.building?.name}`,
    },
    {
      title: "Rack ID To",
      dataIndex: "rackId",
      key: "rack_id",
      width: 100,
      sorter: (a, b) => (a.rackId || "").localeCompare(b.rackId || ""),
    },
    {
      title: "Location U To",
      dataIndex: "locationU",
      key: "location_u",
      width: 100,
      sorter: (a, b) => (a.locationU || "").localeCompare(b.locationU || ""),
    },
    {
      title: "Remark",
      dataIndex: "description",
      key: "remark",
      width: 150,
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      key: "action",
      width: 10,
      render: (newData) => actionList(newData),
    },
  ];

  const childrenReplaceColumns = [
    {
      title: "Nama Asset",
      dataIndex: "asset",
      key: "name",
      width: 200,
      sorter: (a, b) => a.asset?.name.localeCompare(b.asset?.name),
      render: (data) => (
        <Link to={`/daftar-asset/detail/${data?.id}`}>{data?.name}</Link>
      ),
    },
    {
      title: "Fixed Asset Number",
      dataIndex: "asset",
      key: "fixed_asset_number",
      width: 100,
      sorter: (a, b) =>
        a.asset?.fixed_asset_number.localeCompare(b.asset?.fixed_asset_number),
      render: (data) => data?.fixed_asset_number,
    },
    {
      title: "Serial Number",
      dataIndex: "asset",
      key: "serial_number",
      width: 150,
      sorter: (a, b) => a.serialNumber.localeCompare(b.serialNumber),
      render: (data) => data?.serialNumber,
    },
    {
      title: "Unit From",
      dataIndex: "unitFrom",
      key: "unit",
      width: 150,
      sorter: (a, b) => a.unitFrom?.name.localeCompare(b.unitFrom?.name),
      render: (data) => data?.name,
    },
    {
      title: "Unit To",
      dataIndex: "unitTo",
      key: "unit",
      width: 150,
      sorter: (a, b) => a.unitTo?.name.localeCompare(b.unitTo?.name),
      render: (data) => data?.name,
    },
    {
      title: "Serial Number",
      dataIndex: "asset",
      key: "serial_number",
      width: 100,
      sorter: (a, b) =>
        a.asset?.serialNumber.localeCompare(b.asset?.serialNumber),
      render: (data) => data?.serialNumber,
    },
    {
      title: "Location From",
      dataIndex: "roomFrom",
      key: "location_from",
      width: 200,
      sorter: (a, b) =>
        a.asset?.roomFrom?.name.localeCompare(b.asset?.roomFrom?.name),
      render: (data) =>
        `${data?.name} - ${data?.floor?.name} - ${data?.floor?.building?.name}`,
    },
    {
      title: "Rack ID From",
      dataIndex: "asset",
      key: "rack_id_from",
      width: 100,
      sorter: (a, b) => a.asset?.rack_id.localeCompare(b.asset?.rack_id),
      render: (data) => data?.rack_id,
    },
    {
      title: "Location U From",
      dataIndex: "asset",
      key: "location_u_from",
      width: 100,
      sorter: (a, b) => a.asset?.location_u.localeCompare(b.asset?.location_u),
      render: (data) => data?.location_u,
    },
    {
      title: "Location To",
      dataIndex: "room",
      key: "location_to",
      width: 200,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (data) =>
        `${data?.name} - ${data?.floor?.name} - ${data?.floor?.building?.name}`,
    },
    {
      title: "Rack ID To",
      dataIndex: "rackId",
      key: "rack_id",
      width: 100,
      sorter: (a, b) => a.rack_id.localeCompare(b.rack_id),
    },
    {
      title: "Location U To",
      dataIndex: "locationU",
      key: "location_u",
      width: 100,
      sorter: (a, b) => a.location_u.localeCompare(b.location_u),
    },
    {
      title: "Old Asset Plan",
      dataIndex: "oldAssetPlan",
      key: "oldAssetPlan",
      width: 100,
      sorter: (a, b) => a.oldAssetPlan.localeCompare(b.oldAssetPlan),
    },
    {
      title: "New Asset",
      dataIndex: "newAsset",
      key: "newAsset",
      width: 200,
      sorter: (a, b) => a.newAsset?.name.localeCompare(b.newAsset?.name),
      render: (data) => (
        <Link to={`/daftar-asset/detail/${data?.id}`}>
          {data?.name + " - " + data?.serialNumber}
        </Link>
      ),
    },
    {
      title: "New Location",
      dataIndex: "newAssetRoom",
      key: "newAssetRoom",
      width: 200,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (data) =>
        `${data?.name} - ${data?.floor?.name} - ${data?.floor?.building?.name}`,
    },
    {
      title: "New Rack ID",
      dataIndex: "new_asset_rack_id",
      key: "new_asset_rack_id",
      width: 100,
      sorter: (a, b) => a.new_asset_rack_id - b.new_asset_rack_id,
    },
    {
      title: "New Location U",
      dataIndex: "new_asset_location_u",
      key: "new_asset_location_u",
      width: 100,
      sorter: (a, b) => a.new_asset_location_u - b.new_asset_location_u,
    },
    {
      title: "Remark",
      dataIndex: "description",
      key: "remark",
      width: 150,
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      key: "action",
      width: 10,
      render: (newData) => actionList(newData),
    },
  ];

  const columnsHistory = [
    {
      title: "Tanggal",
      key: "created_at",
      dataIndex: "created_at",
      width: 250,
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      render: (data, record) => {
        return (
          record?.created_at && (
            <Link
              to={`/asset-movement/detail/approval/` + id + "/" + record.id}
            >
              {moment(record?.created_at).format("LL HH:mm")}
            </Link>
          )
        );
      },
    },
    {
      title: "Status",
      key: "status",
      width: 250,
      render: (data) => (
        <Tag
          style={{
            borderRadius: "20px",
            minWidth: "120px",
            textAlign: "center",
          }}
          color={statusApproval(
            !data?.is_done_flag && !data?.is_rejected_flag
              ? "WAITING_APPROVAL"
              : data?.is_done_flag && !data?.is_rejected_flag
              ? "APPROVE"
              : "REJECTED"
          )}
        >
          {!data?.is_done_flag && !data?.is_rejected_flag
            ? "Proses"
            : data?.is_done_flag && !data?.is_rejected_flag
            ? "Full Approve"
            : "Reject"}
        </Tag>
      ),
    },
    {
      title: "Aksi",
      key: "aksi",
      width: 10,
      render: (data) => actionApprovalList(data),
    },
  ];

  const actionApprovalList = (data) => {
    return (
      <Dropdown overlay={menuActionApproval(data)} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <EllipsisOutlined rotate={90} />
        </a>
      </Dropdown>
    );
  };

  const menuActionApproval = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link to={`/asset-movement/detail/approval/` + id + "/" + data.id}>
              Lihat Detail
            </Link>
          ),
          key: "1",
        },
      ]}
    />
  );

  const handleDelete = async (asset_id, id) => {
    const newData = await deleteDocumentMovement(asset_id, id);
    if (newData?.error === false || !newData.error) {
      notification.success({
        message: "Success",
        description: "Delete Document Moevement Success",
      });
      getData();
    }
  };

  const expandedRowRender = (row) => {
    return (
      <>
        <Table
          columns={
            data?.type === "REPLACE "
              ? childrenReplaceColumns
              : data?.type === "MUTATION LOCATION"
              ? childrenMutationColumns
              : data?.type === "BORROWING USER"
              ? childrenBorrowingColumns
              : data?.type === "BORROWING RENEWAL"
              ? childrenBorrowingRenewalColumns
              : data?.type === "BORROWING RETURN"
              ? childrenBorrowingReturnColumns
              : childrenColumns
          }
          dataSource={row.details}
          pagination={false}
        />
        {viewDokumen && (
          <div style={{ marginTop: "20px" }}>
            <div>Dokumen: </div>
            <Space size={"large"} wrap={true} direction="vertical">
              {row?.details &&
                row?.details.length > 0 &&
                row?.details[0]?.documents?.map((item, index) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <a
                        key={index}
                        rel="noreferrer"
                        target="_blank"
                        href={process.env.REACT_APP_IMAGE_URL + "/" + item}
                      >
                        <Button icon={<DownloadOutlined />}>Download</Button>
                      </a>
                      <div style={{ marginLeft: "10px" }}>{item}</div>
                    </div>
                    {localStorage.getItem("role_id") === "SUPERADMIN" && (
                      <div style={{ marginLeft: "50px" }}>
                        <Popconfirm
                          title="Sure to delete?"
                          onConfirm={() =>
                            handleDelete(row?.details[0]?.id, item)
                          }
                        >
                          <DeleteOutlined
                            style={{ color: "#bd3f39", fontSize: "24px" }}
                          />
                        </Popconfirm>
                      </div>
                    )}
                  </div>
                ))}
            </Space>
          </div>
        )}
      </>
    );
  };

  const actionList = (data) => {
    return (
      <Dropdown overlay={menuAction(data)} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <EllipsisOutlined rotate={90} />
        </a>
      </Dropdown>
    );
  };

  const menuAction = (data) => {
    return (
      <Menu
        items={[
          {
            label: (
              <Link to={"/daftar-asset/detail/" + data?.asset?.id}>
                Lihat Detail
              </Link>
            ),
          },
        ]}
      />
    );
  };

  const getData = async () => {
    setLoading(true);
    const newData = await getAssetMovement(id);
    if (newData) {
      setData(newData.data);

      const newField = newData.data;
      newField.type = newField?.type + " " + (newField?.deliveredType || "");
      newField.transaction_date =
        (newField?.transactionDate &&
          moment(newField?.transactionDate).format("LL")) ||
        "-";
      newField.return_date =
        (newField?.returnDate && moment(newField?.returnDate).format("LL")) ||
        "-";
      newField.deliveredTo = newField?.deliveredToUser?.name;
      form.setFieldsValue(newField);
      setLoading(false);

      const unitId = localStorage.getItem("downstream_unit_id").split(",");
      const unitRelated = unitId.includes(
        String(newData.data?.created_by?.unitId)
      );
      const superAdmin = "SUPERADMIN" === localStorage.getItem("role_id");
      const unitGA = [
        "ADMIN_GA",
        "VP_GA",
        "HEAD_GA",
        "OFFICE_VENDOR_MANAGEMENT",
      ].includes(localStorage.getItem("role_id"));
      const lastApproval =
        newField?.assetMovementApprovals?.length > 0
          ? newField?.assetMovementApprovals[
              newField?.assetMovementApprovals?.length - 1
            ]
          : [];
      const allApproval =
        lastApproval?.asset_movement_approval_details?.length > 0 &&
        lastApproval?.asset_movement_approval_details
          .map(({ role_id }) => role_id)
          .includes(localStorage.getItem("role_id"));
      const unitMovementBorrower = unitId.includes(
        String(newField?.deliveredToUser?.unitId)
      );

      const tipe1 = unitRelated || superAdmin;
      const tipe2 =
        unitRelated ||
        allApproval ||
        unitGA ||
        superAdmin ||
        unitMovementBorrower;
      const tipe3 = unitRelated || unitGA || superAdmin || unitMovementBorrower;
      const tipe4 = unitRelated || allApproval || unitGA || superAdmin;
      const tipe5 =
        (newField?.deliveredType === "RETURN" ||
          newField?.deliveredType === "RENEWAL") &&
        unitMovementBorrower;

      if (newField?.status === "WAITING_APPROVAL") {
        if (tipe1 || tipe2 || tipe3 || tipe4 || tipe5) {
          setUploadDokumen(true);
        }
        if (tipe2 || tipe5) {
          setViewDokumen(true);
        }
        if (tipe4 || tipe5) {
          setViewDokumenDiterima(true);
        }
      } else {
        if (tipe1 || tipe5) {
          setUploadDokumen(true);
        }
        if (tipe3 || tipe5) {
          setViewDokumen(true);
        }
        if (tipe4 || tipe5) {
          setViewDokumenDiterima(true);
        }
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <AdminLayout>
      <Card
        title={"Detail Movement"}
        className={styles.cardTable}
        extra={
          <Button
            icon={<ArrowLeftOutlined />}
            type="primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        }
      >
        <Row gutter={10} align={"middle"}>
          <Col span={24} lg={12}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Movement Type</Text>
              </Col>
              <Col span={16}>: {data?.type}</Col>
            </Row>
          </Col>
          <Col span={24} lg={12}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Unit</Text>
              </Col>
              <Col span={16}>: {data?.created_by?.unit?.name}</Col>
            </Row>
          </Col>
          <Col span={24} lg={12}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Transaction Date</Text>
              </Col>
              <Col span={16}>: {data?.transaction_date}</Col>
            </Row>
          </Col>
          <Col span={24} lg={12}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Status</Text>
              </Col>
              <Col span={16}>
                :{" "}
                <Tag
                  style={{
                    borderRadius: "20px",
                    minWidth: "120px",
                    textAlign: "center",
                  }}
                  color={statusApproval(data.status)}
                >
                  {data.status}
                </Tag>
              </Col>
            </Row>
          </Col>
          <Col span={24} lg={12}>
            <Row gutter={10} align={"middle"}>
              <Col span={8}>
                <Text>Delivered To</Text>
              </Col>
              <Col span={16}>: {data?.deliveredToUser?.unit?.name}</Col>
            </Row>
          </Col>
          {data.deliveredType === "USER" && (
            <Col span={24} lg={12}>
              <Row gutter={10} align={"middle"}>
                <Col span={8}>
                  <Text>Delivered To</Text>
                </Col>
                <Col span={16}>: {data?.deliveredTo}</Col>
              </Row>
            </Col>
          )}
          {(data.type === "BORROWING RETURN" ||
            data.type === "BORROWING USER") && (
            <Col span={24} lg={12}>
              <Row gutter={10} align={"middle"}>
                <Col span={8}>
                  <Text>Return Date</Text>
                </Col>
                <Col span={16}>: {data?.return_date}</Col>
              </Row>
            </Col>
          )}
        </Row>
      </Card>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card className={styles.cardTable} style={{ marginTop: "20px" }}>
            <div
              style={{
                overflowX: "auto",
                maxWidth: "100%",
                minWidth: "2500px",
              }}
            >
              <Table
                columns={columns}
                dataSource={data?.assetMovementDetails}
                rowKey="poNumber"
                loading={loading}
                expandedRowRender={expandedRowRender}
                expandIcon={({ expanded, onExpand, record }) =>
                  expanded ? (
                    <Button
                      type="primary"
                      onClick={(e) => onExpand(record, e)}
                      icon={<EyeInvisibleOutlined />}
                    >
                      Sembunyikan Detail
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      onClick={(e) => onExpand(record, e)}
                      icon={<EyeOutlined />}
                    >
                      Tampilkan Detail
                    </Button>
                  )
                }
                columnWidth={100}
                pagination
              />
            </div>
          </Card>
        </Col>

        {viewDokumenDiterima && (
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card
              title={"Dokumen Asset"}
              className={styles.cardTable}
              style={{ marginTop: "50px" }}
            >
              <Row gutter={40}>
                <Col className={styles.detailRow} span={24}>
                  <Image.PreviewGroup>
                    <Space size={"large"} wrap={true} direction="vertical">
                      {data?.deliveredDocuments &&
                        data?.deliveredDocuments.map((item, index) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <a
                                key={index}
                                rel="noreferrer"
                                target="_blank"
                                href={
                                  process.env.REACT_APP_IMAGE_URL +
                                  "/" +
                                  item.name
                                }
                              >
                                <Button icon={<DownloadOutlined />}>
                                  Download
                                </Button>
                              </a>
                              <div style={{ marginLeft: "10px" }}>
                                {item.name}
                              </div>
                            </div>
                          </div>
                        ))}
                    </Space>
                  </Image.PreviewGroup>
                </Col>
              </Row>
            </Card>
          </Col>
        )}

        <Col xs={24} sm={24} md={24} lg={24}>
          <Card
            title={"Riwayat Approval Asset Movement"}
            className={styles.cardTable}
            style={{ marginTop: "50px" }}
          >
            <Row gutter={40}>
              <Col className={styles.detailRow} span={24}>
                <Table
                  columns={columnsHistory}
                  dataSource={data?.assetMovementApprovals}
                  rowKey={"id"}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        title={modal.title}
        open={modal.open}
        onOk={modal.onOk}
        loading={modal.loading}
        okText={modal.okText}
        onCancel={() => {
          formModal.resetFields();
          setModal({
            open: false,
            loading: false,
            title: "",
            body: "",
            okText: "",
            onOk: () => {},
          });
        }}
        body={modal.body}
      />
      <UploadDokumen
        open={modalUploadDokumen}
        setOpen={setModalUploadDokumen}
        getData={getData}
      />
    </AdminLayout>
  );
}

export default DetailAssetMovement;
