import { Button, Card, Input, Select, Table, Tag, notification } from "antd";
import { useContext, useEffect, useRef, useState, useMemo } from "react";
import styles from "./Global.module.css";
import AssetContext from "../../Services/Asset";
import MasterContext from "../../Services/Master";
import moment from "moment";
import locationList from "./View/locationList";
import assetList from "./View/AssetList";
import ApprovalList from "./View/ApprovalList";
import StatusApprovalList from "./View/StatusApprovalList";
import StatusList from "./View/StatusList";
import ActionAssetList from "./View/ActionAssetList";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";

function AssetTable(props) {
  const {
    getAllAsset,
    getAllDaftarAsset,
    approvalRegisteredAsset,
    deleteRegisteredAsset,
    cancelUpdateAsset,
  } = useContext(AssetContext);
  const { getAllMaster } = useContext(MasterContext);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dataUnit, setDataUnit] = useState([]);
  const [dataRoom, setDataRoom] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const tableRef = useRef(null);

  let columns = [
    {
      title: "Asset",
      key: "asset",
      width: 400,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (data) => assetList(data, props.type),
      filteredValue: [searchText],
      onFilter: (value, record) => {
        if (
          (value.toLowerCase() === "approve" ||
            value.toLowerCase() === "reject") &&
          record.approvalRole === localStorage.getItem("role_id")
        ) {
          return !record ? null : record;
        } else {
          return (
            String(record?.originUser?.unit?.name)
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase()) ||
            String(record?.name)
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase()) ||
            String(record?.borrowing_movement_id ? record?.unit?.name : null)
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase()) ||
            String(moment(record?.created_at).format("LL"))
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase()) ||
            String(record?.serialNumber)
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase()) ||
            String(record?.fixed_asset_number)
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase()) ||
            String(record?.room?.name)
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase()) ||
            String(record?.room?.floor?.name)
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase()) ||
            String(record?.room?.floor?.building?.name)
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase()) ||
            String(
              props.type === "daftar-asset"
                ? record.approvalStatus === "WAITING_APPROVAL"
                  ? "Sedang dalam pengajuan"
                  : "-"
                : record?.approvalStatus
            )
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase()) ||
            String(record?.status)
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase()) ||
            String(
              Array.isArray(record?.assetMovementStatus) &&
                record?.assetMovementStatus.length > 0
                ? getTagText(
                    record?.assetMovementStatus[0].type,
                    record?.assetMovementStatus[0].status,
                    record?.assetMovementStatus[0].deliveredType
                  )
                : null
            )
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase())
          );
        }
      },
    },
    {
      title: "Unit",
      key: "user",
      width: 250,
      sorter: (a, b) =>
        a.originUser?.unit?.name.localeCompare(b.originUser?.unit?.name),
      render: (data) => data?.originUser?.unit?.name,
    },
    {
      title: "Location",
      key: "location",
      width: 250,
      sorter: (a, b) =>
        (a.room ? a.room?.name : "").localeCompare(b.room ? b.room?.name : ""),
      render: (data) => locationList(data),
    },
    {
      title: "Fixed Asset Number",
      dataIndex: "fixed_asset_number",
      key: "fixed_asset_number",
      width: 250,
      sorter: (a, b) =>
        (a.fixed_asset_number ? a.fixed_asset_number : "").localeCompare(
          b.fixed_asset_number ? b.fixed_asset_number : ""
        ),
    },
    {
      title: "Submit Date",
      dataIndex: "created_at",
      key: "submit_date",
      width: 250,
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      render: (data) => {
        return data && moment(data).format("LL");
      },
    },
    {
      title: "Approval",
      key: "approval",
      width: 250,
      sorter: (a, b) => {
        const roleId = localStorage.getItem("role_id");
        const getOrderValue = (item) => {
          if (item.approvalRole === roleId) {
            if (
              item.approvalStatus === "WAITING_APPROVAL" &&
              (props.type !== "registered-asset"
                ? item.status !== "WAITING_APPROVAL"
                : item.status === "WAITING_APPROVAL")
            ) {
              return 1;
            }
          } else if (
            (String(item.created_by?.unitId) ===
              localStorage.getItem("unit_id") ||
              localStorage
                .getItem("downstream_unit_id")
                .split(",")
                .includes(String(item.created_by?.unitId))) &&
            item.approvalStatus === "REJECTED" &&
            (props.type !== "registered-asset"
              ? item.status !== "WAITING_APPROVAL"
              : item.status === "WAITING_APPROVAL")
          ) {
            if (item.approvalStatus === "REJECTED") {
              return 2;
            }
          }
          return 10;
        };

        return getOrderValue(a) - getOrderValue(b);
      },

      render: (data) => (
        <ApprovalList
          data={data}
          type={props.type}
          getData={getData}
          action={approvalRegisteredAsset}
          actionDelete={deleteRegisteredAsset}
          actionCancel={cancelUpdateAsset}
        />
      ),
    },
    {
      title:
        props.type === "daftar-asset"
          ? "Pengajuan Perubahan Asset"
          : "Status Approval",
      dataIndex: "approvalStatus",
      key: "approvalStatus",
      width: 200,
      sorter: (a, b) =>
        (a.approvalStatus || "").localeCompare(b.approvalStatus || ""),
      render: (data) => {
        if (props.type === "daftar-asset") {
          return (
            <Tag
              style={{
                borderRadius: "20px",
                minWidth: "120px",
                textAlign: "center",
              }}
              color={
                data === "WAITING_APPROVAL"
                  ? StatusList("WAITING_APPROVAL")
                  : ""
              }
            >
              {data === "WAITING_APPROVAL" ? "Sedang dalam Pengajuan" : "-"}
            </Tag>
          );
        } else {
          return (
            <Tag
              style={{
                borderRadius: "20px",
                minWidth: "120px",
                textAlign: "center",
              }}
              color={StatusApprovalList(data)}
            >
              {data === 'APPROVE' || data === 'APPROVED' ? 'APRROVED' : data}
            </Tag>
          );
        }
      },
    },
    {
      title: "Status Asset",
      dataIndex: "status",
      key: "status",
      width: 200,
      sorter: (a, b) => a.status?.localeCompare(b.status),
      render: (data) => (
        <Tag
          style={{
            borderRadius: "20px",
            minWidth: "120px",
            textAlign: "center",
          }}
          color={StatusList(data)}
        >
          {data}
        </Tag>
      ),
    },
    {
      key: "action",
      width: 10,
      render: (data) => {
        return (
          <ActionAssetList data={data} type={props.type} getData={getData} />
        );
      },
    },
  ];

  const unitToColumn = {
    title: "Unit To",
    // key: "name",
    width: 250,
    sorter: (a, b) => {
      const nameA = a?.borrowing_movement_id ? a.unit?.name : "";
      const nameB = b?.borrowing_movement_id ? b.unit?.name : "";
      return nameA.localeCompare(nameB);
    },

    render: (data) => (data?.borrowing_movement_id ? data?.unit?.name : "-"),
  };

  const currentPath = window.location.pathname;
  const unitToColIndex = columns.findIndex((column) => column.key === "user");
  if (currentPath !== "/registered-asset") {
    if (unitToColIndex !== -1) {
      columns.splice(unitToColIndex + 1, 0, unitToColumn);
    } else {
      columns.push(unitToColumn);
    }
  }

  const borrowingColumn = {
    title: "Borrowing Status",
    width: 150,
    sorter: (a, b) => {
      const getOrderValue = (item) => {
        const type = item?.assetMovementStatus?.[0]?.type;
        const status = item?.assetMovementStatus?.[0]?.status;
        const deliveredType = item?.assetMovementStatus?.[0]?.deliveredType;
        if (type === "BORROWING") {
          if (status === "APPROVE") {
            if (deliveredType === "USER") {
              return 1;
            } else if (deliveredType === "RENEWAL") {
              return 4;
            } else if (deliveredType === "RETURN") {
              return 0;
            }
          } else if (status === "WAITING_APPROVAL") {
            if (deliveredType === "USER") {
              return 3;
            } else if (deliveredType === "RENEWAL") {
              return 6;
            } else if (deliveredType === "RETURN") {
              return 8;
            }
          } else if (status === "REJECTED") {
            if (deliveredType === "USER") {
              return 2;
            } else if (deliveredType === "RENEWAL") {
              return 5;
            } else if (deliveredType === "RETURN") {
              return 7;
            }
          }
        }

        return 0;
      };

      return getOrderValue(a) - getOrderValue(b);
    },

    render: (data) => {
      const getTagProps = (type, status, deliveredType) => {
        let tagText = "-";
        let tagColor = "";

        if (type === "BORROWING") {
          if (status === "APPROVE") {
            if (deliveredType === "USER") {
              tagText = "Borrowing: Approve";
              tagColor = StatusList("ACTIVE");
            } else if (deliveredType === "RENEWAL") {
              tagText = "Borrowing Renewal: Approve";
              tagColor = StatusList("ACTIVE");
            } else if (deliveredType === "RETURN") {
              tagText = "-";
            }
          } else if (status === "REJECTED") {
            if (deliveredType === "USER") {
              tagText = "Borrowing: Reject";
              tagColor = StatusList("NON_ACTIVE");
            } else if (deliveredType === "RENEWAL") {
              tagText = "Borrowing Renewal: Reject";
              tagColor = StatusList("NON_ACTIVE");
            } else if (deliveredType === "RETURN") {
              tagText = "Borrowing Return: Reject";
              tagColor = StatusList("NON_ACTIVE");
            }
          } else if (status === "WAITING_APPROVAL") {
            if (deliveredType === "USER") {
              tagText = "Borrowing: Waiting for Approval";
              tagColor = StatusList("WAITING_APPROVAL");
            } else if (deliveredType === "RENEWAL") {
              tagText = "Borrowing Renewal: Waiting for Approval";
              tagColor = StatusList("WAITING_APPROVAL");
            } else if (deliveredType === "RETURN") {
              tagText = "Borrowing Return: Waitin    for Approval";
              tagColor = StatusList("WAITING_APPROVAL");
            }
          }
        }
        return { tagText, tagColor };
      };

      const { tagText, tagColor } = getTagProps(
        data?.assetMovementStatus?.[0]?.type,
        data?.assetMovementStatus?.[0]?.status,
        data?.assetMovementStatus?.[0]?.deliveredType
      );

      return (
        <Tag
          style={{
            borderRadius: "20px",
            minWidth: "238px",
            textAlign: "center",
          }}
          color={tagColor}
        >
          {tagText}
        </Tag>
      );
    },
  };

  if (
    (props.type === "daftar-asset" ||
      props.type === "daftar-asset-perusahaan") &&
    columns[8].key !== "borrowing_movement_id"
  ) {
    columns.splice(8, 0, borrowingColumn);
  }

  const getData = async () => {
    console.log(props.params);
    
    setLoading(true);
    const newData =
      props.type === "registered-asset"
        ? await getAllAsset(props.params)
        : await getAllDaftarAsset(props.params);
    if (newData) {
      setData(newData.data);
      setLoading(false);
    }
  };

  const getTagText = (type, status, deliveredType) => {
    let tagText = "";
    if (type === "BORROWING") {
      if (status === "APPROVE") {
        if (deliveredType === "USER") {
          tagText = "Borrowing: Approve";
        } else if (deliveredType === "RENEWAL") {
          tagText = "Borrowing Renewal: Approve";
        } else if (deliveredType === "RETURN") {
          tagText = "-";
        }
      } else if (status === "REJECTED") {
        if (deliveredType === "USER") {
          tagText = "Borrowing: Reject";
        } else if (deliveredType === "RENEWAL") {
          tagText = "Borrowing Renewal: Reject";
        } else if (deliveredType === "RETURN") {
          tagText = "Borrowing Return: Reject";
        }
      } else if (status === "WAITING_APPROVAL") {
        if (deliveredType === "USER") {
          tagText = "Borrowing: Waiting for Approval";
        } else if (deliveredType === "RENEWAL") {
          tagText = "Borrowing Renewal: Waiting for Approval";
        } else if (deliveredType === "RETURN") {
          tagText = "Borrowing Return: Waiting for Approval";
        }
      }
    }

    return tagText;
  };

  const getDataUnit = async () => {
    const newData = await getAllMaster("units", { migrated_asset: false });
    if (newData) {
      const newDataUnit = [
        {
          value: "",
          label: "SEMUA UNIT",
        },
      ];
      if (newData.data) {
        // const downstreamUnitIds = localStorage.downstream_unit_id
        //   .split(",")
        //   .map((id) => parseInt(id));
        // if (
        //   localStorage.getItem("role_id") === "ADMIN_GA" ||
        //   (localStorage.getItem("role_id") === "VP_GA" &&
        //     (props.type === "daftar-asset-perusahaan" ||
        //       props.type === "registered-asset")) ||
        //   localStorage.getItem("role_id") === "HEAD_GA" ||
        //   localStorage.getItem("role_id") === "OFFICE_VENDOR_MANAGEMENT" ||
        //   localStorage.getItem("role_id") === "DEPT_ACC_TAX" ||
        //   (localStorage.getItem("role_id") === "VP_ACC_TAX" &&
        //     (props.type === "daftar-asset-perusahaan" ||
        //       props.type === "registered-asset")) ||
        //   localStorage.getItem("role_id") === "SUPERADMIN"
        // ) {
          const newDataUnits = newData.data.map((unit) => ({
            value: unit.id,
            label: unit.name,
          }));
          setDataUnit(newDataUnit.concat(newDataUnits));
        // } else {
        //   const newDataUnits = newData.data
        //     .filter((unit) => downstreamUnitIds.includes(unit.id))
        //     .map((unit) => ({ value: unit.id, label: unit.name }));
        //   setDataUnit(newDataUnit.concat(newDataUnits));
        // }
      }
    }

    const newDataRoom = await getAllMaster("rooms");
    if (newDataRoom) {
      const newDataRooms = [
        {
          value: "",
          label: "Semua Ruang",
        },
      ];
      const newDataRoomss =
        newDataRoom.data &&
        newDataRoom.data.map((item) => ({
          value: item.id,
          label:
            item.name +
            " " +
            item?.floor?.name +
            " " +
            item?.floor?.building?.name,
        }));
      setDataRoom(newDataRooms.concat(newDataRoomss));
    }
  };

  useEffect(() => {
    getDataUnit();
  }, []);

  useEffect(() => {
    getData();
  }, [props.params]);

  useEffect(() => {
    const filtered = data.filter((record) => {
      return (
        String(record?.originUser?.unit?.name)
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        String(record?.name)
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        String(record?.borrowing_movement_id ? record?.unit?.name : null)
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        String(moment(record?.created_at).format("LL"))
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        String(record?.serialNumber)
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        String(record?.fixed_asset_number)
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        String(record?.room?.name)
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        String(record?.room?.floor?.name)
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        String(record?.room?.floor?.building?.name)
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        String(record?.approvalStatus)
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        String(record?.status)
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase())
      );
    });
    setFilteredData(filtered);
  }, [searchText, data]);

  const printPDF = async (url) => {
    try {
      const pdfData = filteredData.map((x) => {
        const toPdfData = {
          Asset: x.name ? x.name : "",
          Unit: x.originUser.unit.name ? x.originUser.unit.name : "",
          Location: x.room
            ? x.room.name +
              " - " +
              x.room.floor.name +
              " - " +
              x.room.floor.building.name
            : "",
          "Fixed Asset Number": x.fixed_asset_number
            ? x.fixed_asset_number
            : "",
          "Submit Date": x.created_at
            ? moment(x.created_at).format("DD-MM-YYYY")
            : "",
          "Status Approval": x.approvalStatus ? x.approvalStatus : "",
          "Status Asset": x.status ? x.status : "",
        };

        if (props.type !== "registered-asset") {
          toPdfData["Unit To"] =
            x.unit.name && x.unit.name !== x.originUser.unit.name
              ? x.unit.name
              : "";
        }

        return toPdfData;
      });

      const doc = new jsPDF({
        orientation: "landscape",
      });

      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("PT Jalin Pembayaran Nusantara", 10, 10);

      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      const pageWidth = doc.internal.pageSize.getWidth();
      const companyName = "Asset Report";
      const textWidth = doc.getTextWidth(companyName);
      const textX = (pageWidth - textWidth) / 2;
      doc.text(companyName, textX, 20);

      const columns = [
        { header: "Asset", dataKey: "Asset" },
        { header: "Unit", dataKey: "Unit" },
        { header: "Location", dataKey: "Location" },
        { header: "Fixed Asset Number", dataKey: "Fixed Asset Number" },
        { header: "Submit Date", dataKey: "Submit Date" },
        { header: "Status Approval", dataKey: "Status Approval" },
        { header: "Status Asset", dataKey: "Status Asset" },
      ];

      if (props.type !== "registered-asset") {
        const unitIndex = columns.findIndex((col) => col.dataKey === "Unit");
        columns.splice(unitIndex + 1, 0, {
          header: "Unit To",
          dataKey: "Unit To",
        });
      }

      doc.autoTable({
        startY: 30,
        head: [columns.map((col) => col.header)],
        body: pdfData.map((item) => columns.map((col) => item[col.dataKey])),
        styles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
          fontStyle: "normal",
        },
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontStyle: "bold",
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
        },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.1,
        theme: "grid",
      });

      doc.save(`asset-${moment().format("YYYYMMDDHHmmss")}.pdf`);
    } catch (error) {
      console.error("Error printing PDF:", error);
      notification.error({
        message: "Error",
        description: "Failed to generate PDF file. Please try again later.",
      });
    }
  };

  const printExcel = async (url) => {
    try {
      try {
        const excelData = filteredData.map((x) => {
          const toExcelData = {
            Asset: x.name ? x.name : "",
            Unit: x.originUser.unit.name ? x.originUser.unit.name : "",
            Location: x.room
              ? x.room.name +
                " - " +
                x.room.floor.name +
                " - " +
                x.room.floor.building.name
              : "",
            "Fixed Asset Number": x.fixed_asset_number
              ? x.fixed_asset_number
              : "",
            "Submit Date": x.created_at
              ? moment(x.created_at).format("DD-MM-YYYY")
              : "",
            "Status Approval": x.approvalStatus ? x.approvalStatus : "",
            "Status Asset": x.status ? x.status : "",
          };

          if (props.type !== "registered-asset") {
            toExcelData["Unit To"] =
              x.unit.name && x.unit.name !== x.originUser.unit.name
                ? x.unit.name
                : "";
          }

          return toExcelData;
        });

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet1");

        const columns = [
          { header: "Asset", key: "Asset", width: 20 },
          { header: "Unit", key: "Unit", width: 15 },
          { header: "Location", key: "Location", width: 30 },
          {
            header: "Fixed Asset Number",
            key: "Fixed Asset Number",
            width: 20,
          },
          { header: "Submit Date", key: "Submit Date", width: 20 },
          { header: "Status Approval", key: "Status Approval", width: 15 },
          { header: "Status Asset", key: "Status Asset", width: 15 },
        ];

        if (props.type !== "registered-asset") {
          const unitIndex = columns.findIndex((col) => col.key === "Unit");
          columns.splice(unitIndex + 1, 0, {
            header: "Unit To",
            key: "Unit To",
            width: 15,
          });
        }

        worksheet.columns = columns;

        excelData.forEach((item) => {
          worksheet.addRow(item);
        });

        worksheet.getRow(1).eachCell((cell) => {
          cell.font = { bold: true };
        });

        worksheet.getRow(1).eachCell((cell) => {
          cell.font = { bold: true };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFFFF" },
          };
          cell.border = {
            top: { style: "thin", color: { argb: "FF000000" } },
            left: { style: "thin", color: { argb: "FF000000" } },
            bottom: { style: "thin", color: { argb: "FF000000" } },
            right: { style: "thin", color: { argb: "FF000000" } },
          };
        });

        worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
          row.eachCell({ includeEmpty: false }, (cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFFFFF" },
            };
            cell.border = {
              top: { style: "medium", color: { argb: "FF000000" } },
              left: { style: "medium", color: { argb: "FF000000" } },
              bottom: { style: "medium", color: { argb: "FF000000" } },
              right: { style: "medium", color: { argb: "FF000000" } },
            };
          });
        });

        workbook.xlsx
          .writeBuffer()
          .then((buffer) => {
            const blob = new Blob([buffer], {
              type: "application/octet-stream",
            });
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;
            a.download = `asset-${moment().format("YYYYMMDDHHmmss")}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error("Error printing Excel:", error);
            notification.error({
              message: "Error",
              description:
                "Failed to generate Excel file. Please try again later.",
            });
          });
      } catch (error) {
        console.error("Error printing Excel:", error);
        notification.error({
          message: "Error",
          description: "Failed to generate Excel file. Please try again later.",
        });
      }
      if (data?.data?.error === false || !data?.data?.error) {
        console.log("Download sukses");
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <Card className={styles.cardTable}>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "10px" }}>Cari</div>
            <Input
              onChange={(text) => {
                setSearchText(text?.target?.value);
              }}
              placeholder="Cari..."
              style={{ maxWidth: "300px", marginRight: "30px" }}
            />
          </div>
          {/* <DownloadTableExcel
            filename={`${props.type}_${moment().unix()}`}
            currentTableRef={tableRef.current}
          >
            <Button type="primary" style={{ marginRight: "10px" }}>
              Export Excel
            </Button>
          </DownloadTableExcel> */}
          <Button
            type="primary"
            style={{ marginRight: "10px" }}
            onClick={printExcel.bind(null, window.location.href)}
          >
            Export Excel
          </Button>
          <Button
            onClick={printPDF.bind(null, window.location.href)}
            type="primary"
            danger
          >
            Print PDF
          </Button>
        </div>
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "10px" }}>Filter:</div>

          {(props.type === "daftar-asset-perusahaan" ||
            props.type === "daftar-asset") && (
            <div>
              <Select
                showSearch
                placeholder="Select Unit From"
                style={{ marginRight: "10px", minWidth: "200px" }}
                options={dataUnit}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(evt) =>
                  props.setParams((params) => ({
                    ...params,
                    unit_id: evt,
                  }))
                }
              />
              <Select
                showSearch
                placeholder="Select Unit To"
                style={{ marginRight: "10px", minWidth: "200px" }}
                options={dataUnit}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(evt) =>
                  props.setParams((params) => ({ ...params, unit_id_to: evt }))
                }
              />
            </div>
          )}

          <Select
            showSearch
            placeholder="Select Room"
            style={{ marginRight: "10px", minWidth: "200px" }}
            options={dataRoom}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(evt) => {
              props.setParams((params) => ({ ...params, room_id: evt }));
            }}
          />
        </div>
      </div>
      <Table
        ref={tableRef}
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="id"
        pagination
        onChange={(pagination, filters, sorter, extra) => {
          setFilteredData(extra.currentDataSource);
        }}
      />
    </Card>
  );
}

export default AssetTable;
