import {
  Button,
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  notification,
  Row,
  Table,
  Tag,
  Typography,
} from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "./LabelPrinting.module.css";
import { Link, useNavigate } from "react-router-dom";
import AssetContext from "../../Services/Asset";
import moment from "moment";
import Modal from "../../Components/Global/Modal";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";

moment.locale("id");

const { Text } = Typography;

const statusLabel = (data) => {
  if (data === true) {
    return (
      <Tag
        style={{
          borderRadius: "20px",
          minWidth: "120px",
          textAlign: "center",
        }}
        color={"#3AD647"}
      >
        Sudah Diterima
      </Tag>
    );
  } else {
    return (
      <Tag
        style={{
          borderRadius: "20px",
          minWidth: "120px",
          textAlign: "center",
        }}
        color={"#FA3737"}
      >
        Belum Diterima
      </Tag>
    );
  }
};

function LabelPrinting(props) {
  const { getLabelPrinting, generateLabelPrinting } = useContext(AssetContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [modal, setModal] = useState({
    title: "",
    open: false,
    loading: false,
    onOk: () => {},
    body: "",
  });
  let navigate = useNavigate();
  const tableRef = useRef(null);

  const printPDF = async (url) => {
    try {
      const pdfData = filteredData.map((x) => {
        return {
          "Transaction Date": x.created_at
            ? moment(x.created_at).format("DD-MM-YYYY")
            : "",
          User: x.user?.name ? x.user.name : "",
          Unit: x.unit?.name ? x.unit.name : "",
          Total: x.total ? x.total : "",
          Location: x.building?.name ? x.building.name : "",
          "Arrival Date": x.date_arrived
            ? moment(x.date_arrived).format("DD-MM-YYYY")
            : "",
          Description: x.description ? x.description : "",
          Status: x.alreadyReceived ? "Sudah Diterima" : "Belum Diterima",
        };
      });

      const doc = new jsPDF({
        orientation: "landscape",
      });

      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("PT Jalin Pembayaran Nusantara", 10, 10);

      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      const pageWidth = doc.internal.pageSize.getWidth();
      const companyName = "Penerimaan Report";
      const textWidth = doc.getTextWidth(companyName);
      const textX = (pageWidth - textWidth) / 2;
      doc.text(companyName, textX, 20);

      const columns = [
        { header: "Transaction Date", dataKey: "Transaction Date" },
        { header: "User", dataKey: "User" },
        { header: "Unit", dataKey: "Unit" },
        { header: "Total", dataKey: "Total" },
        { header: "Location", dataKey: "Location" },
        { header: "Arrival Date", dataKey: "Arrival Date" },
        { header: "Description", dataKey: "Description" },
        { header: "Status", dataKey: "Status" },
      ];

      doc.autoTable({
        startY: 30,
        head: [columns.map((col) => col.header)],
        body: pdfData.map((item) => columns.map((col) => item[col.dataKey])),
        styles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
          fontStyle: "normal",
        },
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontStyle: "bold",
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
        },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.1,
        theme: "grid",
      });

      doc.save(`asset-${moment().format("YYYYMMDDHHmmss")}.pdf`);
    } catch (error) {
      console.error("Error printing PDF:", error);
      notification.error({
        message: "Error",
        description: "Failed to generate PDF file. Please try again later.",
      });
    }
  };

  const printExcel = async (url) => {
    try {
      try {
        const excelData = filteredData.map((x) => {
          return {
            "Transaction Date": x.created_at
              ? moment(x.created_at).format("DD-MM-YYYY")
              : "",
            User: x.user?.name ? x.user.name : "",
            Unit: x.unit?.name ? x.unit.name : "",
            Total: x.total ? x.total : "",
            Location: x.building?.name ? x.building.name : "",
            "Arrival Date": x.date_arrived
              ? moment(x.date_arrived).format("DD-MM-YYYY")
              : "",
            Description: x.description ? x.description : "",
            Status: x.alreadyReceived ? "Sudah Diterima" : "Belum Diterima",
          };
        });

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet1");

        const columns = [
          { header: "Transaction Date", key: "Transaction Date", width: 20 },
          { header: "User", key: "User", width: 15 },
          { header: "Unit", key: "Unit", width: 15 },
          { header: "Total", key: "Total", width: 15 },
          { header: "Location", key: "Location", width: 15 },
          { header: "Arrival Date", key: "Arrival Date", width: 30 },
          {
            header: "Description",
            key: "Description",
            width: 20,
          },
          { header: "Status", key: "Status", width: 20 },
        ];

        worksheet.columns = columns;

        excelData.forEach((item) => {
          worksheet.addRow(item);
        });

        worksheet.getRow(1).eachCell((cell) => {
          cell.font = { bold: true };
        });

        worksheet.getRow(1).eachCell((cell) => {
          cell.font = { bold: true };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFFFF" },
          };
          cell.border = {
            top: { style: "thin", color: { argb: "FF000000" } },
            left: { style: "thin", color: { argb: "FF000000" } },
            bottom: { style: "thin", color: { argb: "FF000000" } },
            right: { style: "thin", color: { argb: "FF000000" } },
          };
        });

        worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
          row.eachCell({ includeEmpty: false }, (cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFFFFF" },
            };
            cell.border = {
              top: { style: "medium", color: { argb: "FF000000" } },
              left: { style: "medium", color: { argb: "FF000000" } },
              bottom: { style: "medium", color: { argb: "FF000000" } },
              right: { style: "medium", color: { argb: "FF000000" } },
            };
          });
        });

        workbook.xlsx
          .writeBuffer()
          .then((buffer) => {
            const blob = new Blob([buffer], {
              type: "application/octet-stream",
            });
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;
            a.download = `asset-${moment().format("YYYYMMDDHHmmss")}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error("Error printing Excel:", error);
            notification.error({
              message: "Error",
              description:
                "Failed to generate Excel file. Please try again later.",
            });
          });
      } catch (error) {
        console.error("Error printing Excel:", error);
        notification.error({
          message: "Error",
          description: "Failed to generate Excel file. Please try again later.",
        });
      }
      if (data?.data?.error === false || !data?.data?.error) {
        console.log("Download sukses");
      } else {
        notification.error({
          message: `Error`,
          description: data?.data?.message,
          placement: "top",
        });
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const columns = [
    {
      title: "Transaction Date",
      key: "transaction_date",
      width: 200,
      sorter: (a, b) =>
        moment(a.created_at ? a.created_at : moment()).unix() -
        moment(b.created_at ? b.created_at : moment()).unix(),
      render: (data) =>
        (
          <Link to={`/penerimaan-asset/detail/${data.id}`}>
            {data?.created_at && moment(data?.created_at).format("LL")}
          </Link>
        ) || "-",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(record?.user?.name)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.unit?.name)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.description)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.user?.role?.name)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.total)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.building?.name)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(moment(record?.date_arrived).format("LL"))
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(moment(record?.created_at).format("LL"))
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.alreadyReceived ? "Sudah Diterima" : "Belum Diterima")
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          (localStorage.getItem("role_id") === "ADMIN_GA" ||
          localStorage.getItem("role_id") === "SUPERADMIN"
            ? String(record?.is_generated_flag ? "Print" : "Generate ID")
                .toLocaleLowerCase()
                .includes(value.toLocaleLowerCase())
            : null)
        );
      },
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      width: 400,
      sorter: (a, b) => a.user?.name.localeCompare(b.user?.name),
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(record?.user?.name)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.unit?.name)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.description)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.user?.role?.name)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.total)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.building?.name)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(moment(record?.date_arrived).format("LL"))
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(moment(record?.created_at).format("LL"))
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.alreadyReceived ? "Sudah Diterima" : "Belum Diterima")
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          (localStorage.getItem("role_id") === "ADMIN_GA" ||
          localStorage.getItem("role_id") === "SUPERADMIN"
            ? String(record?.is_generated_flag ? "Print" : "Generate ID")
                .toLocaleLowerCase()
                .includes(value.toLocaleLowerCase())
            : null)
        );
      },
      render: (data) => data?.name,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      width: 250,
      sorter: (a, b) => a.unit?.name.localeCompare(b.unit?.name),
      render: (data) => data?.name,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: 100,
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Location",
      dataIndex: "building",
      key: "location",
      width: 250,
      sorter: (a, b) =>
        (a.building ? a.building?.name : "").localeCompare(
          b.building ? b.building?.name : ""
        ),
      render: (data) => data?.name,
    },
    {
      title: "Arrival Date",
      dataIndex: "date_arrived",
      key: "date",
      width: 250,
      sorter: (a, b) =>
        moment(a.date_arrived ? a.date_arrived : moment()).unix() -
        moment(b.date_arrived ? b.date_arrived : moment()).unix(),
      render: (data) => (data && moment(data).format("LL")) || "-",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 300,
      sorter: (a, b) =>
        (a.description ? a.description : "").localeCompare(
          b.description ? b.description : ""
        ), //eBed
      render: (data) => data,
    },
    {
      title: "Status",
      dataIndex: "alreadyReceived",
      key: "alreadyReceived",
      width: 200,
      sorter: (a, b) => a.alreadyReceived - b.alreadyReceived,
      render: (data) => statusLabel(data),
    },
    {
      title: "Action",
      key: "action",
      width: 10,
      sorter: (a, b) => a.is_generated_flag - b.is_generated_flag,
      render: (data) => actionList(data),
    },
    {
      key: "action2",
      width: 50,
      sorter: false,
      render: (data) => (
        <Dropdown overlay={menuAction(data)} trigger={["click"]}>
          <a onClick={(e) => e.preventDefault()}>
            <EllipsisOutlined rotate={90} />
          </a>
        </Dropdown>
      ),
    },
  ];

  const menuAction = (data) => {
    let items = [
      {
        label: (
          <Link to={"/penerimaan-asset/detail/" + data.id}>Lihat Detail</Link>
        ),
        key: "1",
      },
    ];
    return <Menu items={items} />;
  };

  const actionList = (data) => {
    if (
      localStorage.getItem("role_id") === "ADMIN_GA" ||
      localStorage.getItem("role_id") === "SUPERADMIN"
    ) {
      if (data.is_generated_flag) {
        return (
          <Link to={"/penerimaan-asset/detail/" + data.id}>
            <div className={styles.buttonGroupSuccess}>
              <Button className={styles.buttonSuccess}>Print</Button>
            </div>
          </Link>
        );
      } else {
        return (
          <div
            className={styles.buttonGroupWarning}
            onClick={() => showModalGenerate(data.id)}
          >
            <Button className={styles.buttonWarning}>Generate ID</Button>
          </div>
        );
      }
    }
  };

  const showModalGenerate = (id) => {
    setModal({
      title: "Generate Label ID",
      open: true,
      onOk: async () => {
        setModal((prev) => ({
          ...prev,
          loading: true,
        }));
        const res = await generateLabelPrinting(id);
        if (res?.error === false) {
          setModal((prev) => ({
            ...prev,
            loading: false,
            open: false,
          }));
          notification.success({
            message: "Success",
            description: "Label ID Generated",
          });
          getData();
        } else {
          setModal((prev) => ({
            ...prev,
            loading: false,
          }));
        }
      },
      body: "Apakah Anda yakin generate Label ID?",
    });
  };

  const getData = async () => {
    setLoading(true);
    const newData = await getLabelPrinting({ migrated_asset: false });
    if (newData) {
      setData(newData.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const filtered = data.filter((record) => {
      return (
        String(record?.user?.name)
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        String(record?.unit?.name)
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        String(record?.description)
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        String(record?.user?.role?.name)
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        String(record?.total)
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        String(record?.building?.name)
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        String(moment(record?.date_arrived).format("LL"))
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        String(moment(record?.created_at).format("LL"))
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        String(record?.alreadyReceived ? "Sudah Diterima" : "Belum Diterima")
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        (localStorage.getItem("role_id") === "ADMIN_GA" ||
        localStorage.getItem("role_id") === "SUPERADMIN"
          ? String(record?.is_generated_flag ? "Print" : "Generate ID")
              .toLocaleLowerCase()
              .includes(searchText.toLocaleLowerCase())
          : null)
      );
    });
    setFilteredData(filtered);
  }, [searchText, data]);

  return (
    <AdminLayout>
      <Card className={styles.cardTable}>
        <Row gutter={16}>
          <Col
            style={{ margin: "auto 0", display: "flex", alignItems: "center" }}
            span={24}
            xl={18}
          >
            <div
              style={{
                margin: "auto 0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ marginRight: "10px" }}>Cari</div>
              <Input
                onChange={(text) => {
                  setSearchText(text?.target?.value);
                }}
                placeholder="Cari..."
                style={{ maxWidth: "300px", marginRight: "30px" }}
              />
            </div>
            <Button
              type="primary"
              style={{ marginRight: "10px" }}
              onClick={printExcel}
            >
              Export Excel
            </Button>
            <Button onClick={printPDF} type="primary" danger>
              Print PDF
            </Button>
          </Col>
          {(localStorage.getItem("role_id") === "ADMIN_GA" ||
            localStorage.getItem("role_id") === "USER" ||
            localStorage.getItem("role_id") === "SUPERADMIN") && (
            <Col span={24} xl={6}>
              <div className={styles.buttonCardTable}>
                <Button
                  size="large"
                  type="default"
                  className={styles.buttonSuccess}
                  onClick={() => navigate("add")}
                >
                  Tambah Penerimaan Asset
                </Button>
              </div>
            </Col>
          )}
        </Row>
        <Table
          ref={tableRef}
          columns={columns}
          dataSource={data}
          loading={loading}
          rowKey="id"
          pagination
          onChange={(pagination, filters, sorter, extra) => {
            setFilteredData(extra.currentDataSource);
          }}
        />
      </Card>
      <Modal
        title={modal.title}
        open={modal.open}
        onOk={modal.onOk}
        loading={modal.loading}
        onCancel={() => {
          setModal({
            open: false,
            loading: false,
            title: "",
            body: "",
            onOk: () => {},
          });
        }}
        body={modal.body}
      />
    </AdminLayout>
  );
}

export default LabelPrinting;
