import { useState } from "react";
import { Col, Row } from "antd";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "./Dashboard.module.css";
import CardDashboard from "./Component/CardDashboard";

function Dashboard(props) {
  const [data, setData] = useState({});

  return (
    <AdminLayout>
      <Row gutter={[10, 10]} className={styles.dashboard}>
        <Col xs={24} sm={24} md={12} lg={6}>
					<CardDashboard title="Total Asset" total="10"/>
				</Col>
        <Col xs={24} sm={24} md={12} lg={6}>
					<CardDashboard title="Total Asset Aktif" total="10"/>
				</Col>
        <Col xs={24} sm={24} md={12} lg={6}>
					<CardDashboard title="Total Asset Non Aktif" total="10"/>
				</Col>
        <Col xs={24} sm={24} md={12} lg={6}>
					<CardDashboard title="Total Asset Idle" total="10"/>
				</Col>
      </Row>
    </AdminLayout>
  );
}

export default Dashboard;
