import { Col, DatePicker, Form, Input, Select } from "antd";
import moment from "moment";
import React from "react";

function TypeBorrowingReceiving(props) {
  const disabledDate = (current) => {
    return props?.data?.return_date
      ? current < moment(props?.data?.return_date).add(1, "days")
      : current < moment().subtract(1, "days");
  };

  return (
    <>
      <Col xs={24} sm={24} md={8} lg={8}>
        <Form.Item
          name="deliveredTo"
          label="Delivered To"
          rules={[
            {
              required: true,
              message: "Please select delivered to!",
            },
          ]}
        >
          {props?.type === "resubmit" ? (
            <Input readOnly placeholder="Delivered To" />
          ) : (
            <Select
              showSearch
              placeholder="Select delivered to"
              options={props.dataUser}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          )}
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8}>
        <Form.Item
          name="return_date"
          label={
            (props?.deliveredType === "RENEWAL" ? "Renewal" : "Return") +
            " Date"
          }
          rules={[
            {
              required: true,
              message: "Please select return date!",
            },
          ]}
        >
          {props?.deliveredType === "RETURN" ? (
            <Input readOnly placeholder="Return Date" />
          ) : (
            <DatePicker
              placeholder={
                (props?.deliveredType === "RENEWAL" ? "Renewal" : "Return") +
                " Date"
              }
              disabledDate={disabledDate}
            />
          )}
        </Form.Item>
      </Col>
    </>
  );
}

export default TypeBorrowingReceiving;
