import {
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Row,
  Table,
  Tag,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "./BorrowingMonitoring.module.css";
import AssetContext from "../../Services/Asset";
import moment from "moment";
import Modal from "../../Components/Global/Modal";
import { Link } from "react-router-dom";
moment.locale("id");

const statusList = (data) => {
  if (data === "APPROVE") {
    return "#3AD647";
  } else if (data === "REJECTED") {
    return "#FA3737";
  } else if (data === "WAITING_APPROVAL") {
    return "#FFCD27";
  } else {
    return "#DED8D8";
  }
};

function BorrowingMonitoring(props) {
  const { getAllAssetMovement, renewalAction, returnAction } =
    useContext(AssetContext);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [formModal] = Form.useForm();
  const [modal, setModal] = useState({
    title: "",
    open: false,
    loading: false,
    onOk: () => {},
    body: "",
  });

  const columns = [
    {
      title: "Transaction Date",
      key: "transaction_date",
      width: 200,
      sorter: (a, b) => moment(a.transactionDate).unix() - moment(b.transactionDate).unix(),
      render: (data) =>
        (
          <Link to={`/borrowing-monitoring/detail/${data.id}`}>
            {data?.transactionDate &&
              moment(data?.transactionDate).format("LL")}
          </Link>
        ) || "-",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(moment(record?.transactionDate).format("LL"))
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(moment(record?.returnDate).format("LL"))
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.created_by?.unit?.name)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.deliveredType)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.type)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.status)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase())
        );
      },
    },
    {
      title: "Unit",
      dataIndex: "created_by",
      key: "unit",
      width: 100,
      sorter: (a, b) => a.created_by?.unit?.name.localeCompare(b.created_by?.unit?.name),
      render: (data) => data?.unit?.name,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (data) => (
        <Tag
          style={{
            borderRadius: "20px",
            minWidth: "120px",
            textAlign: "center",
          }}
          color={statusList(data)}
        >
          {data}
        </Tag>
      ),
    },
    {
      key: "action2",
      width: 10,
      render: (data) => action2List(data),
    },
  ];

  const action2List = (data) => {
    return (
      <Dropdown overlay={menuAction(data)} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <EllipsisOutlined rotate={90} />
        </a>
      </Dropdown>
    );
  };

  const menuAction = (data) => {
    let items = [
      {
        label: (
          <Link to={"/borrowing-monitoring/detail/" + data.id}>
            Lihat Detail
          </Link>
        ),
        key: "1",
      },
    ];
    return <Menu items={items} />;
  };

  const getData = async () => {
    const newData = await getAllAssetMovement({type:"BORROWING",delivered_type:"USER",status:"APPROVE"});
    if (newData) {
      setData(newData.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <AdminLayout>
      <Card className={styles.cardTable}>
        <Row gutter={16} style={{marginBottom:"20px"}}>
          <Col
            style={{ margin: "auto 0", display: "flex", alignItems: "center" }}
            span={24}
            xl={12}
          >
            <div
              style={{
                margin: "auto 0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ marginRight: "10px" }}>Cari</div>
              <Input
                onChange={(text) => {
                  setSearchText(text?.target?.value);
                }}
                placeholder="Cari..."
                style={{ maxWidth: "300px", marginRight: "30px" }}
              />
            </div>
          </Col>
        </Row>
        <Table columns={columns} dataSource={data} rowKey="id" pagination />
      </Card>
      <Modal
        title={modal.title}
        open={modal.open}
        onOk={modal.onOk}
        loading={modal.loading}
        onCancel={() => {
          setModal({
            open: false,
            loading: false,
            title: "",
            body: "",
            onOk: () => {},
          });
        }}
        body={modal.body}
      />
    </AdminLayout>
  );
}

export default BorrowingMonitoring;
