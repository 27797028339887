import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  notification,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "./StockOpname.module.css";
import { useNavigate, useParams } from "react-router-dom";
import AssetContext from "../../Services/Asset";
import moment from "moment";
import Modal from "../../Components/Global/Modal";
import StatusApprovalList from "../../Components/Global/View/StatusApprovalList";
import StatusList from "../../Components/Global/View/StatusList";
moment.locale("id");

const { Text } = Typography;

const statusAsset = (data) => {
  if (data === "BELUM_OPNAME") {
    return "#FEC400";
  } else if (data === "SUDAH_OPNAME") {
    return "#18B84E";
  } else if (data === "ASSET_TIDAK_DITEMUKAN") {
    return "#b81818";
  }
};

function DetailOpname(props) {
  const { getListStockOpname } = useContext(AssetContext);
  const [data, setData] = useState([]);
  let { id } = useParams();
  const [form] = Form.useForm();
  let navigate = useNavigate();

  const columnsHistory = [
    {
      title: "Tanggal",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 250,
      render: (data) => moment(data).format("LL HH:mm"),
    },
    {
      title: "Notes",
      dataIndex: "description",
      key: "description",
      width: 200,
    },
    {
      title: "Rejected By",
      dataIndex: "created_by",
      key: "created_by",
      width: 200,
      render: (data) => `${data?.name} - ${data?.position?.name}`,
    },
  ];

  const getData = async () => {
    const newData = await getListStockOpname(id);
    if (newData) {
      setData(newData.data);
      const newField = newData.data?.asset;
      newField.location =
        newField?.room?.name +
        " - " +
        newField?.room?.floor?.name +
        " - " +
        newField?.room?.floor?.building?.name;
      newField.asset_fixed_number = newField?.fixed_asset_number;
      newField.cip_number = newField?.assetHeader?.cip_number;
      newField.po_number = newField?.assetHeader?.po_number;
      newField.insurance_period_start =
        newField?.insurance_period_start &&
        moment(newField?.insurance_period_start).format("LL");
      newField.insurance_period_end =
        newField?.insurance_period_end &&
        moment(newField?.insurance_period_end).format("LL");
      newField.bast_date =
        newField?.bast_date && moment(newField?.bast_date).format("LL");
      newField.unit_name = newField?.originUser?.unit?.name;
      newField.unit_to = newField?.borrowing_movement_id
        ? newField?.unit?.name
        : "-";
      form.setFieldsValue(newField);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <AdminLayout>
      <Card className={styles.cardTable}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontSize: "1.5rem" }}>{data?.asset?.name}</div>
          <Button
            icon={<ArrowLeftOutlined />}
            type="primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </Card>
      <Card title={"Detail Asset"} className={styles.cardTable}>
        <Form form={form}>
          <Row gutter={40}>
            <Col className={styles.detailRow} xs={24} sm={24} md={12} lg={12}>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Asset Name</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="name">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Unit Name</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="unit_name">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Unit To</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="unit_to">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Location</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="location">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Vendor Name</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="vendor_name">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              {data?.asset?.room?.has_rack && (
                <>
                  <Row gutter={10} align={"middle"}>
                    <Col xs={24} sm={24} md={24} lg={6}>
                      <Text>Rack ID</Text>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={16}>
                      <Form.Item name="rack_id">
                        <Input readOnly={true} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10} align={"middle"}>
                    <Col xs={24} sm={24} md={24} lg={6}>
                      <Text>Location U</Text>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={16}>
                      <Form.Item name="location_u">
                        <Input readOnly={true} />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Tanggal BAST</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="bast_date">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Generate Code</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="generatedCode">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Status Asset</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Tag
                    style={{
                      borderRadius: "20px",
                      minWidth: "120px",
                      textAlign: "center",
                    }}
                    color={StatusList(data?.asset?.status)}
                  >
                    {data?.asset?.status}
                  </Tag>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={6}
                  style={{ marginTop: "5px" }}
                >
                  <Text>Status Approval</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Tag
                    style={{
                      borderRadius: "20px",
                      minWidth: "120px",
                      textAlign: "center",
                    }}
                    color={StatusApprovalList(data?.asset?.approvalStatus)}
                  >
                    {data?.asset?.approvalStatus}
                  </Tag>
                </Col>
              </Row>
            </Col>
            <Col className={styles.detailRow} xs={24} sm={24} md={12} lg={12}>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Serial Number</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="serialNumber">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Fixed Asset Number</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="asset_fixed_number">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>CIP Number</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="cip_number">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>PO Number</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="po_number">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Location Notes</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="location_note">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Insurance</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="insurance">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Insurance Period Start</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="insurance_period_start">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col xs={24} sm={24} md={24} lg={6}>
                  <Text>Insurance Period End</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Form.Item name="insurance_period_end">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} align={"middle"}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={6}
                  style={{ marginTop: "5px" }}
                >
                  <Text>Borrowing Status</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Tag
                    style={{
                      borderRadius: "20px",
                      minWidth: "120px",
                      textAlign: "center",
                    }}
                    color={
                      data?.asset?.borrowing_movement_id
                        ? StatusApprovalList("APPROVED")
                        : ""
                    }
                  >
                    {data?.asset?.borrowing_movement_id ? "BORROWED" : "-"}
                  </Tag>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={6}
                  style={{ marginTop: "5px" }}
                >
                  <Text>Movement Status</Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Tag
                    style={{
                      borderRadius: "20px",
                      minWidth: "120px",
                      textAlign: "center",
                    }}
                    color={
                      data?.asset?.used_in_movement
                        ? StatusApprovalList("APPROVED")
                        : ""
                    }
                  >
                    {data?.asset?.used_in_movement ? "MOVEMENT" : "-"}
                  </Tag>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card title={"Gambar Asset"} className={styles.cardTable}>
        <Row gutter={40}>
          <Col className={styles.detailRow} span={24}>
            <Image.PreviewGroup>
              <Space size={"large"} wrap={true}>
                {data?.asset?.images &&
                  data?.asset?.images.map((item, index) => (
                    <Image
                      key={index}
                      width={200}
                      src={process.env.REACT_APP_IMAGE_URL + "/" + item.name}
                    />
                  ))}
              </Space>
            </Image.PreviewGroup>
          </Col>
        </Row>
      </Card>

      <Card title={"Hasil Opname"} className={styles.cardTable}>
        <Form form={form} className={styles.detailRow}>
          <Row gutter={10} align={"middle"}>
            <Col xs={24} sm={24} md={24} lg={6}>
              <Text>Asset Name</Text>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16}>
              <Form.Item>
                <Input value={data?.asset_name} readOnly={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} align={"middle"}>
            <Col xs={24} sm={24} md={24} lg={6}>
              <Text>Serial Number</Text>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16}>
              <Form.Item>
                <Input value={data?.serial_number} readOnly={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} align={"middle"}>
            <Col xs={24} sm={24} md={24} lg={6}>
              <Text>Fixed Asset Number:</Text>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16}>
              <Form.Item>
                <Input value={data?.fixed_asset_number} readOnly={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} align={"middle"}>
            <Col xs={24} sm={24} md={24} lg={6}>
              <Text>Location</Text>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16}>
              <Form.Item>
                <Input
                  value={
                    data?.room?.name +
                    " - " +
                    data?.room?.floor?.name +
                    " - " +
                    data?.room?.floor?.building?.name
                  }
                  readOnly={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} align={"middle"}>
            <Col xs={24} sm={24} md={24} lg={6}>
              <Text>Rack ID</Text>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16}>
              <Form.Item>
                <Input value={data?.rack_id} readOnly={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} align={"middle"}>
            <Col xs={24} sm={24} md={24} lg={6}>
              <Text>Location U</Text>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16}>
              <Form.Item>
                <Input value={data?.location_u} readOnly={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} align={"middle"}>
            <Col xs={24} sm={24} md={24} lg={6}>
              <Text>Description</Text>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16}>
              <Form.Item>
                <Input value={data?.description} readOnly={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} align={"middle"}>
            <Col xs={24} sm={24} md={24} lg={6}>
              <Text>Status</Text>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16}>
              <Tag
                style={{
                  borderRadius: "20px",
                  minWidth: "120px",
                  textAlign: "center",
                }}
                color={statusAsset(data?.status)}
              >
                {data?.status}
              </Tag>
            </Col>
          </Row>
          <Row gutter={10} align={"middle"}>
            <Col xs={24} sm={24} md={24} lg={6}>
              <Text>Status Asset</Text>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16}>
              <Tag
                style={{
                  borderRadius: "20px",
                  minWidth: "120px",
                  textAlign: "center",
                }}
                color={StatusList(data?.asset?.status)}
              >
                {data?.asset?.status}
              </Tag>
            </Col>
          </Row>
          <Row gutter={10} align={"middle"}>
            <Col xs={24} sm={24} md={24} lg={6} style={{ marginTop: "5px" }}>
              <Text>Status Rejected</Text>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16}>
              <Tag
                style={{
                  borderRadius: "20px",
                  minWidth: "120px",
                  textAlign: "center",
                }}
                color={StatusApprovalList(
                  data?.is_rejected_flag ? "REJECTED" : ""
                )}
              >
                {data?.is_rejected_flag ? "DITOLAK" : "-"}
              </Tag>
            </Col>
          </Row>
          <Row gutter={10} align={"middle"}>
            <Col xs={24} sm={24} md={24} lg={6}>
              <Text>Status Pending</Text>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16}>
              <Form.Item>
                <Input value={data?.notes_pending} readOnly={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} align={"middle"}>
            <Col xs={24} sm={24} md={24} lg={6}>
              <Text>Stock Opname Images</Text>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16}>
              <Image.PreviewGroup>
                <Space size={"large"} wrap={true}>
                  {data?.stock_opname_asset_files &&
                    data?.stock_opname_asset_files?.map((item, index) => (
                      <Image
                        key={index}
                        width={200}
                        src={process.env.REACT_APP_IMAGE_URL + "/" + item.name}
                      />
                    ))}
                </Space>
              </Image.PreviewGroup>
            </Col>
          </Row>
          <Row gutter={[10, 20]} align={"middle"} style={{ marginTop: "30px" }}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Text>Riwayat Reject</Text>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Table
                columns={columnsHistory}
                dataSource={data?.rejectHistories}
                rowKey={"id"}
              />
            </Col>
          </Row>
        </Form>
      </Card>
    </AdminLayout>
  );
}

export default DetailOpname;
