import React, { useContext } from "react";
import {
  DashboardOutlined,
  PrinterOutlined,
  InboxOutlined,
  RetweetOutlined,
  DeleteOutlined,
  MedicineBoxOutlined,
  BarChartOutlined,
  SettingOutlined,
  MonitorOutlined,
} from "@ant-design/icons";

const menu = [
  {
    key: 0,
    icon: React.createElement(DashboardOutlined),
    label: "Dashboard",
    path: "/dashboard",
    expect: ["SUPERADMIN"],
  },
  {
    key: 1,
    icon: React.createElement(PrinterOutlined),
    label: "Penerimaan Asset",
    path: "/penerimaan-asset",
    expect: ["SUPERADMIN"],
  },
  {
    key: 2,
    icon: React.createElement(InboxOutlined),
    label: "Registered Asset",
    path: "/registered-asset",
    expect: ["SUPERADMIN"],
  },
  {
    key: 3,
    icon: React.createElement(InboxOutlined),
    label: "Daftar Asset",
    path: "/daftar-asset",
    expect: ["SUPERADMIN"],
  },
  {
    key: 4,
    icon: React.createElement(InboxOutlined),
    label: "Daftar Asset Perusahaan",
    path: "/daftar-asset-perusahaan",
    access: ["ADMIN_GA", "VP_GA", "HEAD_GA", "OFFICE_VENDOR_MANAGEMENT"],
  },
  {
    key: 5,
    icon: React.createElement(RetweetOutlined),
    label: "Asset Movement",
    path: "/asset-movement",
    expect: ["SUPERADMIN"],
  },
  {
    key: 6,
    icon: React.createElement(MonitorOutlined),
    label: "Borrowing Monitoring",
    path: "/borrowing-monitoring",
    expect: ["SUPERADMIN"],
  },
  {
    key: 7,
    icon: React.createElement(DeleteOutlined),
    label: "Disposal",
    path: "/disposal",
    expect: ["SUPERADMIN"],
  },
  {
    key: 8,
    icon: React.createElement(MedicineBoxOutlined),
    label: "Stock Opname",
    group: "/stock-opname",
    expect: ["SUPERADMIN"],
    children: [
      {
        key: "S81",
        label: "Stock Opname",
        path: "/stock-opname",
      },
      {
        key: "S82",
        label: "Stock Opname Pending",
        path: "/pending",
      },
    ],
  },
  {
    key: 9,
    icon: React.createElement(BarChartOutlined),
    label: "Report",
    group: "/report",
    expect: ["SUPERADMIN"],
    children: [
      {
        key: "S91",
        label: "Report Stock Opname",
        path: "/report-stock-opname",
      },
      {
        key: "S92",
        label: "Report Asset",
        path: "/report-asset",
      },
    ],
  },
  {
    type: "divider",
  },
  {
    key: 11,
    icon: React.createElement(SettingOutlined),
    label: "Settings",
    group: "/settings",
    access: ["SUPERADMIN"],
    children: [
      {
        key: "S111",
        label: "Users",
        path: "/users",
      },
      {
        key: "S112",
        label: "Unit",
        path: "/units",
      },
      {
        key: "S113",
        label: "Room",
        path: "/rooms",
      },
      {
        key: "S114",
        label: "Floor",
        path: "/floors",
      },
      {
        key: "S115",
        label: "Building",
        path: "/buildings",
      },
    ],
  },
];

export default menu;
