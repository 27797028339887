import React from "react";
import { Card, Col, Row, Typography } from "antd";
import styles from "../AssetMovement.module.css";
import {
  BuildOutlined,
  UserSwitchOutlined,
  ShopOutlined,
  SplitCellsOutlined,
} from "@ant-design/icons";

const { Title } = Typography;

function FirstStep(props) {
  return (
    <div className={styles.firstStep}>
      <Row gutter={20}>
        <Col xs={6} sm={6} md={6} lg={6}>
          <Card onClick={() => props.next("BORROWING")}>
            <BuildOutlined style={{ fontSize: "100px" }} />
            <Title level={3}>BORROWING</Title>
          </Card>
        </Col>
        <Col xs={6} sm={6} md={6} lg={6}>
          <Card onClick={() => props.next("MUTATION", "USER")}>
            <UserSwitchOutlined style={{ fontSize: "100px" }} />
            <Title level={3}>MUTATION USER</Title>
          </Card>
        </Col>
        <Col xs={6} sm={6} md={6} lg={6}>
          <Card onClick={() => props.next("MUTATION", "LOCATION")}>
            <ShopOutlined style={{ fontSize: "100px" }} />
            <Title level={3}>MUTATION LOCATION</Title>
          </Card>
        </Col>
        <Col xs={6} sm={6} md={6} lg={6}>
          <Card onClick={() => props.next("REPLACE")}>
            <SplitCellsOutlined style={{ fontSize: "100px" }} />
            <Title level={3}>REPLACE</Title>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default FirstStep;
