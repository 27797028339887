const reducer = (state, { type, payload }) => {

  return {
    ...state,
    loading: false,
    data: payload,
  };
};

export default reducer;
