import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  notification,
  Row,
  Select,
  Space,
  Upload,
  Modal,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "../../Components/Global/Global.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AssetContext from "../../Services/Asset";
import MasterContext from "../../Services/Master";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";

const { TextArea } = Input;
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

function UpdateAsset(props) {
  let { id, idSOAsset } = useParams();
  const {
    getDetailAsset,
    updateAsset,
    upload,
    resubmitAsset,
    uploadDokumenAction,
  } = useContext(AssetContext);
  const { getAllMaster, getMaster } = useContext(MasterContext);
  const [dataRoom, setDataRoom] = useState([]);
  const [dataDetail, setDataDetail] = useState();
  const [dataDetailRoom, setDataDetailRoom] = useState();
  const [dataBuildings, setDataBuildings] = useState([]);
  const [formSubmitData, setFormSubmitData] = useState([]);
  const [dataFloor, setDataFloor] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [dokumen, setDokumen] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const [image, setImage] = useState([]);
  let navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const floor = Form.useWatch("floor_id", form);
  const room = Form.useWatch("room_id", form);
  const location_building_id = Form.useWatch("location_building_id", form);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const showConfirmationModal = () => {
    setConfirmModalVisible(true);
  };

  const handleFormSubmit = () => {
    uploadFiles();
    setConfirmModalVisible(false);
    onFinish(formSubmitData);
  };

  const uploadFiles = async () => {
    if (fileList.length > 0) {
      const res = await upload(fileList, "registrasi");
      if (res) {
        const res2 = await uploadDokumenAction(id, {
          documents: res.data,
        });
        if (res2?.error === false || !res2?.error) {
          message.success("Upload Berhasil");
        } else {
          message.error(res2?.message || "Terjadi kesalahan saat mengunggah.");
        }
        setFileList([]);
      } else {
        message.error("Tidak dapat mengunggah file. Coba lagi.");
      }
    }
  };

  const uploadProps = {
    multiple: true,
    onRemove: (file) => {
      setFileList((prevList) => {
        const index = prevList.indexOf(file);
        const newFileList = prevList.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    beforeUpload: (file) => {
      const fileTypeAllowed = [
        // "image/jpeg",
        // "image/png",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      ];
      const isAllowedFileType = fileTypeAllowed.includes(file.type);
      if (!isAllowedFileType) {
        message.error(
          "Anda hanya dapat mengunggah file PDF/DOC/DOCX/XLS/XLSX/PPT/PPTX!"
        );
        return false;
      }

      let file_size = 16;
      const isLt2M = file.size / 1024 / 1024 < file_size;
      if (!isLt2M) {
        message.error("Image must smaller than " + file_size + "MB!");
        return isLt2M;
      }

      setFileList((prevList) => [...prevList, file]);
      return false;
    },
    fileList,
  };

  const imageUploadProps = {
    beforeUpload: (file) => {
      const fileTypeAllowed = ["image/jpeg", "image/png"];
      const isJpgOrPng = fileTypeAllowed.includes(file.type);
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
        return false;
      }
      let file_size = 16;
      const isLt2M = file.size / 1024 / 1024 < file_size;
      if (!isLt2M) {
        message.error("Image must smaller than " + file_size + "MB!");
        return isLt2M;
      }
      return false;
    },
    onChange: (info) => {
      let imageList = [...info.fileList];
      imageList.forEach((file) => {
        if (file.status === "done" || file.status === "error") {
          getBase64(file.originFileObj, (url) => {
            setImageUrl((prev) => [...prev, url]);
          });
        }
        return false;
      });

      setImageList(imageList);
      return false;
    },
    multiple: true,
    showUploadList: true,
    listType: "picture-card",
  };

  const uploadData = async (data) => {
    const res = await upload(data, "registrasi");
    if (res) {
      setImage((prev) => [...prev, res.data[0]]);
    }
  };

  const inputDokumen = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const fileTypeAllowed = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      const isJpgOrPng = fileTypeAllowed.includes(file.type);
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG/PDF/DOC/DOCX file!");
        return isJpgOrPng;
      }
      let file_size = 16;
      const isLt2M = file.size / 1024 / 1024 < file_size;
      if (!isLt2M) {
        message.error("Image must smaller than " + file_size + "MB!");
        return isLt2M;
      }
      setFileList((data) => [...data, file]);
    },
    action: (file) => uploadDokumen(file),
    fileList,
    showUploadList: { showRemoveIcon: false },
    multiple: true,
  };

  const uploadDokumen = async (data) => {
    const res = await upload(data, "registrasi");
    if (res) {
      setDokumen((prev) => [...prev, res.data[0]]);
    }
  };

  const onFinish = async (data) => {
    console.log("masuk sini");
    
    if (imageList.length > 0) {
      const uploadImages = await upload(
        imageList.map((item) => item.originFileObj),
        "registrasi"
      );
      data.images = uploadImages.data;
    }

    data.stock_opname_asset_id = idSOAsset || null;
    const res =
      location?.pathname?.split("/")[2] === "resubmit"
        ? await resubmitAsset(id, data)
        : await updateAsset(id, data);
    if (res && (res?.error === false || !res.error)) {
      if (dokumen) {
        await uploadDokumenAction(id, {
          documents: dokumen,
        });
      }
      notification.success({
        message: "Success",
        description: "Update Asset Success",
      });
      navigate("/registered-asset");
    }
  };

  const getBuilding = async () => {
    const getBuildingsData = await getAllMaster("buildings");
    if (getBuildingsData) {
      const newDataBuildings =
        getBuildingsData.data &&
        getBuildingsData.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      setDataBuildings(newDataBuildings);
    }
  };

  const getFloor = async (buildingId) => {
    const getData = await getMaster("floors/building", buildingId);
    if (getData) {
      const newDataFloor =
        getData.data &&
        getData.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      setDataFloor(newDataFloor);
    }
  };

  const getRoom = async (id) => {
    const getData = await getMaster("rooms/floor", id);
    if (getData) {
      const newDataRoom =
        getData.data &&
        getData.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      setDataRoom(newDataRoom);
    }
  };

  const getRoomDetail = async (id) => {
    const getData = await getMaster("rooms", id);
    if (getData) {
      setDataDetailRoom(getData?.data);
    }
  };

  const getData = async () => {
    const newData = await getDetailAsset(id);
    if (newData) {
      setDataDetail(newData.data);
      const newField = newData.data;
      newField.location_building_id = newField?.room?.floor?.building?.id;
      newField.room_id = newField?.room?.id;
      newField.floor_id = newField?.room?.floor?.id;
      newField.asset_fixed_number = newField?.fixed_asset_number;
      newField.cip_number = newField?.assetHeader?.cip_number;
      newField.po_number = newField?.assetHeader?.po_number;
      newField.serial_number = newField?.serialNumber;
      newField.images = null;

      newField.insurance_period_start =
        newField.insurance_period_start &&
        moment(newField.insurance_period_start);
      newField.insurance_period_end =
        newField.insurance_period_end && moment(newField.insurance_period_end);
      form.setFieldsValue(newField);
    }
  };

  useEffect(() => {
    if (location_building_id) {
      getFloor(location_building_id);
      form.setFieldsValue({ floor_id: null, room_id: null });
      setDataFloor([]);
      setDataRoom([]);
    }
  }, [location_building_id]);

  useEffect(() => {
    if (location_building_id) {
      getFloor(location_building_id);
    }
    if (
      location_building_id !== dataDetail?.room?.floor?.building_id ||
      floor !== dataDetail?.floor_id
    ) {
      form.setFieldsValue({ floor_id: "" });
    }
  }, [location_building_id]);

  useEffect(() => {
    if (floor) {
      getRoom(floor);
    }
    if (floor !== dataDetail?.room?.floor?.id || room !== dataDetail?.room_id) {
      form.setFieldsValue({ room_id: "" });
    }
  }, [floor]);

  useEffect(() => {
    if (room) {
      getRoomDetail(room);
    }
    if (room !== dataDetail?.room?.id) {
      form.setFieldsValue({ location_u: "", rack_id: "" });
    }
  }, [room]);

  useEffect(() => {
    if (dataDetail) {
      getFloor(dataDetail?.room?.floor?.building?.id);
    }
  }, [dataDetail]);

  useEffect(() => {
    getBuilding();
    getData();
  }, []);

  return (
    <AdminLayout>
      <Card
        title={
          location?.pathname?.split("/")[2] === "resubmit"
            ? "Registrasi - Resubmit"
            : "Update Asset"
        }
        className={styles.cardTable}
        extra={
          <Space>
            <Button
              icon={<ArrowLeftOutlined />}
              type="primary"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          labelAlign={"left"}
          onFinish={(data) => {
            showConfirmationModal();
            setFormSubmitData(data);
          }}
        >
          <Row gutter={[0, 15]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item name="cip_number" label="CIP Number">
                <Input readOnly disabled placeholder="CIP Number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item name="fixed_asset_number" label="Fixed Asset Number">
                <Input readOnly disabled placeholder="Fixed Asset Number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item name="po_number" label="PO Number">
                <Input readOnly disabled placeholder="PO Number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item name="qr_code_number" label="QR Code Number">
                <Input readOnly disabled placeholder="QR Code Number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item label="Building">
                <Input
                  readOnly
                  disabled
                  value={dataDetail?.room?.floor?.building?.name || ""}
                  placeholder="Building Location Name"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                name="floor_id"
                label="Floor"
                rules={[{ required: true, message: "Please select a floor!" }]}
              >
                <Select
                  placeholder="Select Floor"
                  options={dataFloor}
                  disabled={!dataFloor.length}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                name="room_id"
                label="Room"
                rules={[{ required: true, message: "Please select a room!" }]}
              >
                <Select
                  placeholder="Select Room"
                  options={dataRoom}
                  disabled={!dataRoom.length}
                ></Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please input name!",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                name="serial_number"
                label="Serial Number"
                rules={[
                  {
                    required: true,
                    message: "Please input serial number!",
                  },
                ]}
              >
                <Input placeholder="Serial Number" />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{
                display:
                  (form.getFieldValue("vendor_name") == null ||
                    form.getFieldValue("vendor_name") === "") &&
                  location?.pathname?.split("/")[2] === "update"
                    ? "none"
                    : "",
              }}
            >
              <Form.Item name="vendor_name" label="Vendor Name">
                <Input placeholder="Vendor Name" />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{
                display:
                  ((form.getFieldValue("rack_id") == null ||
                    form.getFieldValue("rack_id") == 0) &&
                    location?.pathname?.split("/")[2] === "update") ||
                  (location?.pathname?.split("/")[2] === "resubmit" &&
                    dataDetail?.status !== "WAITING_APPROVAL") ||
                  !dataDetailRoom?.has_rack
                    ? "none"
                    : "",
              }}
            >
              <Form.Item
                name="rack_id"
                label="Rack ID"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input rack id!",
                //   },
                // ]}
              >
                <Input
                  placeholder="Rack ID"
                  readOnly={
                    dataDetail?.status !== "WAITING_APPROVAL" ? true : false
                  }
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{
                display:
                  ((form.getFieldValue("location_u") == null ||
                    form.getFieldValue("location_u") == 0) &&
                    location?.pathname?.split("/")[2] === "update") ||
                  (location?.pathname?.split("/")[2] === "resubmit" &&
                    dataDetail?.status !== "WAITING_APPROVAL") ||
                  !dataDetailRoom?.has_rack
                    ? "none"
                    : "",
              }}
            >
              <Form.Item
                name="location_u"
                label="Location U"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input location u!",
                //   },
                // ]}
              >
                <Input
                  placeholder="Location U"
                  readOnly={
                    dataDetail?.status !== "WAITING_APPROVAL" ? true : false
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item name="location_note" label="Location Note">
                <TextArea
                  autoSize={{
                    minRows: 3,
                    maxRows: 3,
                  }}
                  placeholder="Location Note"
                />
              </Form.Item>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{
                display:
                  (form.getFieldValue("insurance") == null ||
                    form.getFieldValue("insurance") === "") &&
                  location?.pathname?.split("/")[2] === "update"
                    ? "none"
                    : "",
              }}
            >
              <Form.Item name="insurance" label="Insurance">
                <Input placeholder="Insurance" />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{
                display:
                  (form.getFieldValue("insurance_period_start") == null ||
                    form.getFieldValue("insurance_period_start") === "") &&
                  location?.pathname?.split("/")[2] === "update"
                    ? "none"
                    : "",
              }}
            >
              <Form.Item
                name="insurance_period_start"
                label="Insurance Period Start"
              >
                <DatePicker
                  disabledDate={(cur) => {
                    const createdAt = form.getFieldValue("created_at");
                    const createdAtDate = createdAt
                      ? new Date(createdAt)
                      : null;
                    return createdAtDate && cur < createdAtDate;
                  }}
                  placeholder="Insurance Period Start"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{
                display:
                  (form.getFieldValue("insurance_period_end") == null ||
                    form.getFieldValue("insurance_period_end") === "") &&
                  location?.pathname?.split("/")[2] === "update"
                    ? "none"
                    : "",
              }}
            >
              <Form.Item
                name="insurance_period_end"
                label="Insurance Period End"
              >
                <DatePicker
                  placeholder="Insurance Period End"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item name="upload_dokumen" label="Upload Dokumen">
                <Upload {...uploadProps}>
                  <Button icon={<UploadOutlined />}>Pilih File</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item label="Upload Image" valuePropName="fileList">
                <Upload {...imageUploadProps} accept="image/png, image/jpeg">
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "right" }}>
              <Form.Item wrapperCol={24} style={{ width: "100%" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    minWidth: "200px",
                    minHeight: "40px",
                    borderRadius: "10px",
                  }}
                >
                  {location?.pathname?.split("/")[2] === "resubmit"
                    ? "Resubmit"
                    : "Update"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>

      <Modal
        title="Update Asset"
        visible={confirmModalVisible}
        onOk={handleFormSubmit}
        onCancel={() => setConfirmModalVisible(false)}
      >
        Apakah Anda yakin ingin merubah data asset ini?
      </Modal>
    </AdminLayout>
  );
}

export default UpdateAsset;
