import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate  } from "react-router-dom";
import AssetState from "./Services/Asset/state.js";
import AuthState from "./Services/Auth/state.js";
import ProtectedRoute from "./Config/ProtectedRoute.js";
import MasterState from "./Services/Master/state.js";
import NotFound from "./Pages/NotFound/index.js";
import Login from "./Pages/Login/index.js";
import PrivateRoute from "./Config/PrivateRoute.js";
import "./App.css";
import Dashboard from "./Pages/Dashboard/index.js";
import StockOpname from "./Pages/StockOpname/index.js";
import AssetMovement from "./Pages/AssetMovement/index.js";
import AddAssetMovement from "./Pages/AssetMovement/add.js";
import RegisteredAsset from "./Pages/RegisteredAsset/index.js";
import DetailAsset from "./Pages/RegisteredAsset/detail.js";
import LabelPrinting from "./Pages/LabelPrinting/index.js";
import DetailLabelPrinting from "./Pages/LabelPrinting/detail.js";
import AddLabelPrinting from "./Pages/LabelPrinting/add.js";
import DetailStockOpname from "./Pages/StockOpname/detail.js";
import DetailOpname from "./Pages/StockOpname/detailOpname.js";
import Users from "./Pages/Master/Users/index.js";
import Units from "./Pages/Master/Units/index.js";
import Rooms from "./Pages/Master/Rooms/index.js";
import Buildings from "./Pages/Master/Buildings/index.js";
import Floors from "./Pages/Master/Floors/index.js";
import UpdateAsset from "./Pages/RegisteredAsset/update.js";
import DaftarAsset from "./Pages/DaftarAsset/index.js";
import BorrowingMonitoring from "./Pages/BorrowingMonitoring/index.js";
import DetailAssetMovement from "./Pages/AssetMovement/detail.js";
import DetailPending from "./Pages/StockOpnamePending/detail.js";
import Disposal from "./Pages/Disposal/index.js";
import AddDisposal from "./Pages/Disposal/add.js";
import DetailDisposal from "./Pages/Disposal/detail.js";
import StockOpnameReport from "./Pages/Report/stockOpname.js";
import AssetReport from "./Pages/Report/asset.js";
import StockOpnamePending from "./Pages/StockOpnamePending/index.js";
import EditAssetMovement from "./Pages/AssetMovement/edit.js";
import DetailBorrowing from "./Pages/BorrowingMonitoring/detail.js";
import EditDisposal from "./Pages/Disposal/edit.js";
import DetailHistoryAsset from "./Pages/RegisteredAsset/detailHistory.js";
import DaftarAssetPerusahaan from "./Pages/DaftarAssetPerusahaan/index.js";
import Profile from "./Pages/Profile/index.js";
import DetailHistoryAssetMovement from "./Pages/AssetMovement/detailHistory.js";
import DetailHistoryDisposal from "./Pages/Disposal/detailHistory.js";
import AppLogout from "./Services/AppLogout.js";

const normal_user = [
  "DIR_KEUANGAN",
  "HEAD_DEPT",
  "DEPT_ACC_TAX",
  "USER",
  "VP_ACC_TAX",
  "HEAD_IT_SECURITY",
  "VP_IT_SECURITY",
  "VP_DEPT",
  "DIR_BID_DEPT",
  "VP_GA",
  "ADMIN_GA",
  "OFFICE_VENDOR_MANAGEMENT",
];

const super_user = ["SUPERADMIN"];

const general_affair_user = ["VP_GA", "ADMIN_GA", "OFFICE_VENDOR_MANAGEMENT"];

function App() {
  return (
    <AppLogout>
      <AuthState>
        <MasterState>
          <AssetState>
            <Router>
              <Routes>
                <Route exact element={<PrivateRoute />}>
                  <Route exact path="" element={<Navigate to="/profile" />} />
                  <Route exact path="/" element={<Navigate to="/profile" />} />
                  <Route exact path="/dashboard" element={<Dashboard />} />

                  {/* Normal Users Access */}
                  <Route
                    element={<ProtectedRoute allowedRoles={normal_user} />}
                  >
                    <Route
                      exact
                      path="/registered-asset"
                      element={<RegisteredAsset />}
                    />

                    <Route
                      exact
                      path="/registered-asset/detail/:id"
                      element={<DetailAsset />}
                    />
                    <Route
                      exact
                      path="/registered-asset/update/:id"
                      element={<UpdateAsset />}
                    />
                    <Route
                      exact
                      path="/registered-asset/resubmit/:id"
                      element={<UpdateAsset />}
                    />
                    <Route
                      exact
                      path="/registered-asset/detail/approval/:id/:idIndex"
                      element={<DetailHistoryAsset />}
                    />
                    <Route
                      exact
                      path="/registered-asset/update/:id/:idSOAsset"
                      element={<UpdateAsset />}
                    />
                    <Route
                      exact
                      path="/daftar-asset"
                      element={<DaftarAsset />}
                    />
                    <Route
                      exact
                      path="/daftar-asset/detail/:id"
                      element={<DetailAsset />}
                    />
                    <Route
                      exact
                      path="/daftar-asset/update/:id"
                      element={<UpdateAsset />}
                    />
                    <Route
                      exact
                      path="/daftar-asset/resubmit/:id"
                      element={<UpdateAsset />}
                    />
                    <Route
                      exact
                      path="/daftar-asset/detail/approval/:id/:idIndex"
                      element={<DetailHistoryAsset />}
                    />

                    <Route
                      exact
                      path="/asset-movement"
                      element={<AssetMovement />}
                    />
                    <Route
                      exact
                      path="/asset-movement/detail/:id"
                      element={<DetailAssetMovement />}
                    />
                    <Route
                      exact
                      path="/asset-movement/detail/approval/:id/:idIndex"
                      element={<DetailHistoryAssetMovement />}
                    />
                    <Route
                      exact
                      path="/asset-movement/edit/:id"
                      element={<EditAssetMovement />}
                    />
                    <Route
                      exact
                      path="/asset-movement/edit/:id/:type"
                      element={<EditAssetMovement />}
                    />
                    <Route
                      exact
                      path="/asset-movement/add"
                      element={<AddAssetMovement />}
                    />
                    <Route
                      exact
                      path="/asset-movement/add/:id"
                      element={<AddAssetMovement />}
                    />
                    <Route
                      exact
                      path="/asset-movement/add/:type/:id"
                      element={<AddAssetMovement />}
                    />
                    <Route
                      exact
                      path="/asset-movement/add-type/:type"
                      element={<AddAssetMovement />}
                    />
                    <Route
                      exact
                      path="/asset-movement/add/:type/:id/:idSOAsset"
                      element={<AddAssetMovement />}
                    />
                    <Route
                      exact
                      path="/borrowing-monitoring"
                      element={<BorrowingMonitoring />}
                    />
                    <Route
                      exact
                      path="/borrowing-monitoring/detail/:id"
                      element={<DetailBorrowing />}
                    />
                    <Route
                      exact
                      path="/stock-opname/stock-opname"
                      element={<StockOpname />}
                    />
                    <Route
                      exact
                      path="/stock-opname/stock-opname/detail/:id"
                      element={<DetailStockOpname />}
                    />
                    <Route
                      exact
                      path="/stock-opname/stock-opname/detail-opname/:id"
                      element={<DetailOpname />}
                    />
                    <Route
                      exact
                      path="/stock-opname/pending"
                      element={<StockOpnamePending />}
                    />
                    <Route
                      exact
                      path="/stock-opname/pending/detail/:id"
                      element={<DetailPending />}
                    />
                    <Route
                      exact
                      path="/stock-opname/pending/detail-opname/:id"
                      element={<DetailOpname />}
                    />
                    <Route
                      exact
                      path="/penerimaan-asset"
                      element={<LabelPrinting />}
                    />
                    <Route
                      exact
                      path="/penerimaan-asset/add"
                      element={<AddLabelPrinting />}
                    />
                    <Route
                      exact
                      path="/penerimaan-asset/detail/:id"
                      element={<DetailLabelPrinting />}
                    />
                    <Route exact path="/disposal" element={<Disposal />} />
                    <Route
                      exact
                      path="/disposal/add"
                      element={<AddDisposal />}
                    />
                    <Route
                      exact
                      path="/disposal/add/:id"
                      element={<AddDisposal />}
                    />
                    <Route
                      exact
                      path="/disposal/add/:id/:idSOAsset"
                      element={<AddDisposal />}
                    />
                    <Route
                      exact
                      path="/disposal/detail/:id"
                      element={<DetailDisposal />}
                    />
                    <Route
                      exact
                      path="/disposal/detail/approval/:id/:idIndex"
                      element={<DetailHistoryDisposal />}
                    />
                    <Route
                      exact
                      path="/disposal/edit/:id"
                      element={<EditDisposal />}
                    />
                    <Route
                      exact
                      path="/disposal/edit/:id/:type"
                      element={<EditDisposal />}
                    />
                    <Route path="/report">
                      <Route
                        path="/report/report-stock-opname"
                        element={<StockOpnameReport />}
                      />
                      <Route
                        path="/report/report-asset"
                        element={<AssetReport />}
                      />
                    </Route>
                  </Route>

                  {/* General Affair users */}
                  <Route
                    element={
                      <ProtectedRoute allowedRoles={general_affair_user} />
                    }
                  >
                    <Route
                      exact
                      path="/daftar-asset-perusahaan"
                      element={<DaftarAssetPerusahaan />}
                    />
                    <Route
                      exact
                      path="/daftar-asset-perusahaan/detail/:id"
                      element={<DetailAsset />}
                    />
                    <Route
                      exact
                      path="/daftar-asset-perusahaan/update/:id"
                      element={<UpdateAsset />}
                    />
                    <Route
                      exact
                      path="/daftar-asset-perusahaan/resubmit/:id"
                      element={<UpdateAsset />}
                    />
                    <Route
                      exact
                      path="/daftar-asset-perusahaan/detail/approval/:id/:idIndex"
                      element={<DetailHistoryAsset />}
                    />
                  </Route>

                  {/* Super Admin Access */}
                  <Route element={<ProtectedRoute allowedRoles={super_user} />}>
                    <Route path="/settings">
                      <Route path="/settings/users" element={<Users />} />
                      <Route path="/settings/units" element={<Units />} />
                      <Route path="/settings/rooms" element={<Rooms />} />
                      <Route path="/settings/floors" element={<Floors />} />
                      <Route
                        path="/settings/buildings"
                        element={<Buildings />}
                      />
                    </Route>
                  </Route>

                  <Route path="/profile" element={<Profile />} />
                </Route>
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/*" element={<NotFound />} />
                <Route exact path="/forbidden" element={<NotFound />} />
              </Routes>
            </Router>
          </AssetState>
        </MasterState>
      </AuthState>
    </AppLogout>
  );
}

export default App;
