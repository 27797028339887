import { Button, Card, Col, Row, Select, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import AssetContext from "../../Services/Asset";
import MasterContext from "../../Services/Master";
import styles from "./Report.module.css";

function AssetReport(props) {
  const { downloadReportAsset } = useContext(AssetContext);
  const { getAllMaster } = useContext(MasterContext);
  const [dataUnit, setDataUnit] = useState([]);
  const [dataRoom, setDataRoom] = useState([]);
  const [data, setData] = useState({
    unit_id: "",
    room_id: "",
    status: "",
  });

  const getDataUnit = async () => {
    const newData = await getAllMaster("units");
    if (newData) {
      const newDataUnit = [
        {
          value: "",
          label: "SEMUA UNIT",
        },
      ];
      const newDataUnits =
        newData.data &&
        newData.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      setDataUnit(newDataUnit.concat(newDataUnits));
    }
  };

  const getDataRoom = async () => {
    const newData = await getAllMaster("rooms");
    if (newData) {
      const newDataRoom = [
        {
          value: "",
          label: "Semua Ruang",
        },
      ];
      const newDataRooms =
        newData.data &&
        newData.data.map((item) => ({
          value: item.id,
          label:
            item.name +
            " " +
            item?.floor?.name +
            " " +
            item?.floor?.building?.name,
        }));
      setDataRoom(newDataRoom.concat(newDataRooms));
    }
  };

  const downloadReport = async (type) => {
    await downloadReportAsset(
      "Asset" + data.unit_id &&
        data.unit_id + " " + data.room_id &&
        " " + data.room_id,
      data,
      type
    );
  };

  useEffect(() => {
    getDataUnit();
    getDataRoom();
  }, []);

  return (
    <AdminLayout>
      <Card className={styles.cardTable}>
        <Row
          gutter={16}
          align={"middle"}
          justify={"center"}
          className={styles.report}
        >
          <Col span={24}>
            {(localStorage.getItem("role_id") === "ADMIN_GA" ||
              localStorage.getItem("role_id") === "SUPERADMIN" ||
              localStorage.getItem("role_id") ===
                "OFFICE_VENDOR_MANAGEMENT") && (
              <div className={styles.reportGroup}>
                <div className={styles.reportLabel}>Unit: </div>
                <Select
                  showSearch
                  placeholder="Select Unit"
                  style={{ marginRight: "10px", minWidth: "400px" }}
                  options={dataUnit}
                  onChange={(evt) => setData({ ...data, unit_id: evt })}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </div>
            )}
          </Col>
          <Col span={24}>
            <div className={styles.reportGroup}>
              <div className={styles.reportLabel}>Room: </div>
              <Select
                showSearch
                placeholder="Select Room"
                style={{ marginRight: "10px", minWidth: "400px" }}
                options={dataRoom}
                onChange={(evt) => setData({ ...data, room_id: evt })}
              />
            </div>
          </Col>
          <Col span={24}>
            <div className={styles.reportGroup}>
              <div className={styles.reportLabel}>Status: </div>
              <Select
                showSearch
                placeholder="Select Status"
                style={{ marginRight: "10px", minWidth: "400px" }}
                options={[
                  {
                    value: "ACTIVE",
                    label: "ACTIVE",
                  },
                  {
                    value: "NON_ACTIVE",
                    label: "NON ACTIVE",
                  },
                  {
                    value: "IDLE",
                    label: "IDLE",
                  },
                  {
                    value: "DISPOSE",
                    label: "DISPOSE",
                  },
                ]}
                onChange={(evt) => setData({ ...data, room_id: evt })}
              />
            </div>
          </Col>
          <Col span={24}>
            <Space>
              <div className={styles.buttonCardTable}>
                <Button
                  size="large"
                  type="default"
                  className={styles.buttonSuccess}
                  onClick={() => downloadReport("pdf")}
                >
                  Cetak PDF
                </Button>
              </div>
              <div className={styles.buttonCardTable}>
                <Button
                  size="large"
                  type="default"
                  className={styles.buttonSuccess}
                  onClick={() => downloadReport("excel")}
                >
                  Cetak Excel
                </Button>
              </div>
            </Space>
          </Col>
        </Row>
      </Card>
    </AdminLayout>
  );
}

export default AssetReport;
