import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  notification,
  Row,
  Table,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/Admin/AdminLayout";
import styles from "../Master.module.css";
import { EllipsisOutlined } from "@ant-design/icons";
import AssetContext from "../../../Services/Master";
import Modal from "../../../Components/Global/Modal";
import AddUpdate from "./addUpdate";

function Units(props) {
  const {
    getAllMaster,
    loading,
    getMaster,
    addMaster,
    updateMaster,
    deleteMaster,
  } = useContext(AssetContext);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState({
    title: "",
    open: false,
    loading: false,
    onOk: () => {},
    body: "",
  });
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const getParams = (params) => ({
    search: params.pagination?.search,
    pageIndex: params.pagination?.current - 1,
    pageSize: params.pagination?.pageSize,
    sort: params.sorter?.field,
    direction: params.sorter?.order === "descend" ? "DESC" : "ASC",
  });

  const columns = [
    {
      title: "Kode",
      dataIndex: "code",
      key: "code",
      width: 150,
      sorter: true,
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
      width: 200,
      sorter: true,
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      width: 100,
      sorter: true,
    },
    {
      title: "Root",
      dataIndex: "root_id",
      key: "root_id",
      width: 100,
      sorter: true,
    },
    {
      title: "Alamat",
      dataIndex: "address",
      key: "address",
      width: 300,
      sorter: true,
    },
    {
      title: "Aksi",
      key: "aksi",
      width: 10,
      render: (data) => actionList(data),
    },
  ];

  const actionList = (data) => {
    return (
      <Dropdown overlay={menuAction(data)} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <EllipsisOutlined rotate={90} />
        </a>
      </Dropdown>
    );
  };

  const menuAction = (data) => (
    <Menu
      items={[
        {
          label: "Ubah",
          key: "1",
          onClick: async () => {
            const getData = await getMaster("units", data.id);
            if (getData) {
              form.setFieldsValue(getData?.data);
              setModal({
                open: true,
                loading: false,
                title: "Ubah Unit",
                body: <AddUpdate id={data.id} form={form} />,
                onOk: async () => {
                  try {
                    const values = await form.validateFields();
                    if (values) {
                      const res = await updateMaster("units", data.id, values);
                      if (res?.error === false || !res?.error) {
                        notification.success({
                          message: "Success",
                          description: "Update Unit Success",
                        });
                        setModal({
                          open: false,
                          loading: false,
                          title: "",
                          body: "",
                          onOk: () => {},
                        });
                        getData();
                      }
                    }
                  } catch (errInfo) {}
                },
              });
            }
          },
        },
        {
          label: "Hapus",
          key: "2",
          onClick: async () => {
            setModal({
              open: true,
              loading: false,
              title: "Hapus Unit",
              body: "Apakah anda yakin ingin menghapus data unit ini?",
              onOk: async () => {
                const res = await deleteMaster("units", data.id);
                if (res?.error === false || !res?.error) {
                  notification.success({
                    message: "Success",
                    description: "Delete Unit Success",
                  });
                  setModal({
                    open: false,
                    loading: false,
                    title: "",
                    body: "",
                    onOk: () => {},
                  });
                  getData();
                }
              },
            });
          },
        },
      ]}
    />
  );

  const getData = async () => {
    const newData = await getAllMaster("units", getParams(tableParams));
    if (newData) {
      setData(newData.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: newData?.pagination?.total_elements,
        },
      });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    });
  };

  useEffect(() => {
    getData();
  }, [
    tableParams.sorter,
    tableParams.pagination.search,
    tableParams.pagination.pageSize,
    tableParams.pagination.pageIndex,
  ]);

  return (
    <AdminLayout>
      <Card className={styles.cardTable}>
        <Row gutter={16}>
          <Col
            style={{ margin: "auto 0", display: "flex", alignItems: "center" }}
            xs={18}
            sm={18}
            md={18}
            lg={18}
          >
            <div style={{ marginRight: "10px" }}>Cari</div>
            <Input
              onChange={(data) => {
                setTableParams({
                  ...tableParams,
                  pagination: {
                    ...tableParams.pagination,
                    search: data?.target?.value,
                  },
                });
              }}
              placeholder="Cari..."
              style={{ maxWidth: "300px" }}
            />
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <div className={styles.buttonCardTable}>
              <Button
                size="large"
                type="default"
                
                className={styles.buttonSuccess}
                onClick={() =>
                  setModal({
                    open: true,
                    loading: false,
                    title: "Tambah Unit",
                    body: <AddUpdate form={form} />,
                    onOk: async () => {
                      try {
                        const values = await form.validateFields();
                        if (values) {
                          const res = await addMaster("units", values);
                          if (res?.error === false || !res?.error) {
                            notification.success({
                              message: "Success",
                              description: "Add Unit Success",
                            });
                            setModal({
                              open: false,
                              loading: false,
                              title: "",
                              body: "",
                              onOk: () => {},
                            });
                            getData();
                          }
                        }
                      } catch (errInfo) {}
                    },
                  })
                }
              >
                Tambah Unit
              </Button>
            </div>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={data}
          rowKey="id"
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </Card>
      <Modal
        title={modal.title}
        open={modal.open}
        onOk={modal.onOk}
        loading={modal.loading}
        onCancel={() => {
          form.resetFields();
          setModal({
            open: false,
            loading: false,
            title: "",
            body: "",
            onOk: () => {},
          });
        }}
        body={modal.body}
      />
    </AdminLayout>
  );
}

export default Units;
