import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Row,
  Select,
  Space,
  Input,
  Table,
  Card,
  Typography,
  notification,
  Popconfirm,
} from "antd";
import {
  ArrowLeftOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import styles from "./AssetMovement.module.css";
import TypeBorrowingReceiving from "./component/typeBorrowingReceiving";
import MasterContext from "../../Services/Master";
import AssetContext from "../../Services/Asset";
import TableMovementEdit from "./component/editTable";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import UploadDokumen from "./uploadDokumen";

const { Title } = Typography;

function EditAssetMovement(props) {
  const columns = [
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
      width: 800,
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
    },
    {
      key: "action",
      width: 100,
      render: (data) => (
        <Button
          onClick={() =>
            setModalUploadDokumen({
              open: true,
              po_number: data.poNumber,
              id: id,
              documents:
                data?.details &&
                data?.details.length > 0 &&
                data?.details[0]?.documents,
            })
          }
          type="primary"
        >
          Upload Dokumen
        </Button>
      ),
    },
  ];

  let { id, type } = useParams();
  const { getAllMaster } = useContext(MasterContext);
  const {
    getAllDaftarAsset,
    getAssetMovement,
    updateAssetMovement,
    resubmitAssetMovement,
    deleteDocumentMovement,
  } = useContext(AssetContext);
  const [data, setData] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [dataLocation, setDataLocation] = useState([]);
  const [dataAsset, setDataAsset] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [form] = Form.useForm();
  const [modalUploadDokumen, setModalUploadDokumen] = useState({
    open: false,
    id: "",
    po_number: "",
    document: [],
  });

  let navigate = useNavigate();

  const getUsers = async () => {
    const newData = await getAllMaster("users", { position_id: "3" });
    if (newData) {
      const newDataUser =
        newData.data &&
        newData.data.map((item) => ({
          value: item.id,
          label: item?.unit?.name,
        }));
      setDataUser(newDataUser);
    }
  };

  const getLocation = async () => {
    const newData = await getAllMaster("rooms");
    if (newData) {
      const newDataLocation =
        newData.data &&
        newData.data.map((item) => ({
          value: item?.id,
          label:
            item?.name +
            " " +
            item?.floor?.name +
            " " +
            item?.floor?.building?.name,
        }));
      setDataLocation(newDataLocation);
    }
  };

  const getAsset = async () => {
    const newData = await getAllDaftarAsset({ status: "ACTIVE" });
    if (newData) {
      const newDataAsset =
        newData.data &&
        newData.data.map((item) => ({
          value: item.id,
          label: item.name + " - " + item.serialNumber,
        }));
      setDataAsset(newDataAsset);
    }
  };

  const getDetailMovement = async () => {
    const newData = await getAssetMovement(id);
    if (newData) {
      setData(newData.data);
      var newArr = [];
      for (var i = 0; i < newData.data?.assetMovementDetails.length; i++) {
        newArr = newArr.concat(newData.data?.assetMovementDetails[i].details);
      }
      const newArray =
        newArr &&
        newArr.map((item, index) => {
          return {
            key: index,
            fixed_asset_number: item?.asset?.fixed_asset_number,
            name: item?.asset?.name,
            serial_number: item?.asset?.serialNumber,
            location_from:
              item?.roomFrom?.name +
              " " +
              item?.roomFrom?.floor?.name +
              " " +
              item?.roomFrom?.floor?.building?.name,
            rack_id_from: item?.asset?.rack_id,
            location_u_from: item?.asset?.location_u,
            location_to:
              (item?.room?.name || "") +
              " " +
              (item?.room?.floor?.name || "") +
              " " +
              (item?.room?.floor?.building?.name || ""),
            rack_id: item?.rackId,
            location_u: item?.locationU,
            remark: item?.description,
            room_id: item?.roomId,
            room_from_id: item?.asset?.room_id,
            asset_id: item?.assetId,
            new_asset_id: item?.newAssetId,
            new_asset_name: item?.newAsset?.name,
            old_asset_plan: item?.oldAssetPlan,
            new_rack_id: item?.new_asset_rack_id,
            new_location_u: item?.new_asset_location_u,
            new_location_to:
              (item?.newAssetRoom?.name || "") +
              " " +
              (item?.newAssetRoom?.floor?.name || "") +
              " " +
              (item?.newAssetRoom?.floor?.building?.name || ""),
            new_room_id: item?.new_asset_room_id,
          };
        });
      setDataSource(newArray);
      const newField = newData.data;
      newField.transaction_date =
        (newField?.transactionDate &&
          moment(newField?.transactionDate).format("YYYY-MM-DD")) ||
        "-";
      newField.return_date =
        newField?.deliveredType === "RETURN"
          ? (newField?.returnDate &&
              moment(newField?.returnDate).format("YYYY-MM-DD")) ||
            "-"
          : (newField?.returnDate && moment(newField?.returnDate)) || "-";
      newField.deliveredTo = newField?.deliveredToUser?.unit?.name;
      form.setFieldsValue(newField);
    }
  };

  const defaultEditColumns = [
    {
      title: "Nama Asset",
      dataIndex: "asset",
      key: "name",
      width: 500,
      sorter: (a, b) => a.asset?.name.localeCompare(b.asset?.name),
      render: (data) => data?.name,
    },
    {
      title: "Fixed Asset Number",
      dataIndex: "asset",
      key: "fixed_asset_number",
      width: 500,
      sorter: (a, b) =>
        a.asset?.fixed_asset_number.localeCompare(b.asset?.fixed_asset_number),
      render: (data) => data?.fixed_asset_number,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (test) =>
        data.assetMovementDetails.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            // onConfirm={() => handleDelete(data)}
          >
            <DeleteOutlined style={{ color: "#bd3f39", fontSize: "24px" }} />
          </Popconfirm>
        ) : null,
    },
  ];

  const expandedRowRender = (row) => {
    return (
      <>
        <Table
          columns={defaultEditColumns}
          dataSource={row.details}
          pagination={false}
        />
        {
          <div style={{ marginTop: "20px" }}>
            <div>Dokumen: </div>
            <Space size={"large"} wrap={true} direction="vertical">
              {row?.details &&
                row?.details.length > 0 &&
                row?.details[0]?.documents?.map((item, index) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <a
                        key={index}
                        rel="noreferrer"
                        target="_blank"
                        href={process.env.REACT_APP_IMAGE_URL + "/" + item}
                      >
                        <Button icon={<DownloadOutlined />}>Download</Button>
                      </a>
                      <div style={{ marginLeft: "10px" }}>{item}</div>
                    </div>
                    {localStorage.getItem("role_id") === "SUPERADMIN" && (
                      <div style={{ marginLeft: "50px" }}>
                        <Popconfirm
                          title="Sure to delete?"
                          // onConfirm={() =>
                          //   handleDelete(row?.details[0]?.id, item)
                          // }
                        >
                          <DeleteOutlined
                            style={{ color: "#bd3f39", fontSize: "24px" }}
                          />
                        </Popconfirm>
                      </div>
                    )}
                  </div>
                ))}
            </Space>
          </div>
        }
      </>
    );
  };

  useEffect(() => {
    getDetailMovement();
    getUsers();
    getLocation();
    getAsset();
  }, []);

  const onFinish = async (values) => {
    try {
      if (dataSource?.length > 0) {
        const newDataSource = dataSource?.map((item) => ({
          asset_id: item?.asset_id,
          location_u: item?.location_u,
          rack_id: item?.rack_id,
          room_id: item?.room_id,
          room_from_id: item.room_from_id,
          description: item?.remark,
          old_asset_plan: item?.old_asset_plan,
          new_asset_id: item?.new_asset_id,
          new_asset_location_u: item.new_location_u,
          new_asset_rack_id: item.new_rack_id,
          new_asset_room_id: item.new_room_id,
        }));
        const sendedData = {
          type: data?.type,
          transaction_date:
            values?.transaction_date && values?.transaction_date,
          return_date:
            data?.deliveredType === "RETURN"
              ? values?.return_date && values?.return_date
              : (values?.return_date &&
                  values?.return_date?.format("YYYY-MM-DD")) ||
                data?.returnDate,
          delivered_type: data?.deliveredType,
          delivered_to: values?.deliveredTo,
          asset_movement_details: newDataSource,
        };

        let res =
          type === "resubmit"
            ? await resubmitAssetMovement(id, sendedData, data?.type)
            : updateAssetMovement(id, sendedData);
        if (res?.error === false || !res?.error) {
          notification.success({
            message: "Success",
            description:
              (type === "resubmit" ? "Resubmit" : "Update") +
              " Asset Movement Success",
          });
          navigate("/asset-movement");
        }
      } else {
        notification.error({
          message: "Error",
          description: "Data Asset is required",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AdminLayout>
      <Card
        className={styles.cardTable}
        extra={
          <Button
            icon={<ArrowLeftOutlined />}
            type="primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        }
      >
        <Title level={5}>
          Asset Movement Form{" "}
          {`${(data?.type && "-") || ""} ${data?.type || ""} ${
            (data?.type === "MUTATION" && data?.deliveredType) || ""
          }`}
        </Title>
      </Card>
      <Form
        form={form}
        layout="vertical"
        name="control-hooks"
        autoComplete="off"
        onFinish={onFinish}
      >
        <Card className={styles.cardBottom}>
          <div className={styles.secondStep}>
            <Row gutter={20}>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Form.Item
                  name="transaction_date"
                  label="Transaction Date"
                  rules={[
                    {
                      required: true,
                      message: "Please select transaction date!",
                    },
                  ]}
                >
                  <Input readOnly placeholder="Transaction Date" />
                </Form.Item>
              </Col>

              {data?.deliveredType === "USER" && data?.type === "MUTATION" ? (
                <Col xs={24} sm={24} md={8} lg={8}>
                  <Form.Item
                    name="deliveredTo"
                    label="Delivered To"
                    rules={[
                      {
                        required: true,
                        message: "Please select delivered to!",
                      },
                    ]}
                  >
                    {data.deliveredType === "USER" &&
                    data.type === "MUTATION" &&
                    type === "resubmit" ? (
                      <Input readOnly placeholder="Delivered To" />
                    ) : (
                      <Select
                        showSearch
                        placeholder="Select delivered to"
                        options={dataUser}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              ) : null}

              {data?.type === "BORROWING" && (
                <TypeBorrowingReceiving
                  dataUser={dataUser}
                  dataLocation={dataLocation}
                  data={data}
                  type={type}
                  deliveredType={data.deliveredType}
                  returnDate={data.returnDate}
                />
              )}
              <Col xs={24} sm={24} md={24} lg={24}>
                <div style={{ overflowX: "auto", maxWidth: "100%" }}>
                  {/* <Table
                    columns={columns}
                    dataSource={data?.assetMovementDetails}
                    rowKey="poNumber"
                    // loading={loading}
                    expandedRowRender={expandedRowRender}
                    expandIcon={({ expanded, onExpand, record }) =>
                      expanded ? (
                        <Button
                          type="primary"
                          onClick={(e) => onExpand(record, e)}
                          icon={<EyeInvisibleOutlined />}
                        >
                          Sembunyikan Detail
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          onClick={(e) => onExpand(record, e)}
                          icon={<EyeOutlined />}
                        >
                          Tampilkan Detail
                        </Button>
                      )
                    }
                    columnWidth={100}
                    pagination
                  />
                  <UploadDokumen
                    open={modalUploadDokumen}
                    setOpen={setModalUploadDokumen}
                    getData={getAssetMovement}
                  /> */}
                  <TableMovementEdit
                    dataSource={data}
                    // dataSource={dataSource}
                    // setDataSource={setDataSource}
                    dataAsset={dataAsset}
                    dataLocation={dataLocation}
                    type={{ type: data.type, subType: data.deliveredType }}
                  />
                </div>
              </Col>
              <Divider />
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                style={{ textAlign: "right" }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </Card>
      </Form>
    </AdminLayout>
  );
}

export default EditAssetMovement;
