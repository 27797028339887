import React, { useState } from "react";
import {
  Button,
  Col,
  message,
  Modal,
  notification,
  Row,
  Typography,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { Text } = Typography;

function ModalUploadDokumen(props) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [fileList, setFileList] = useState([]);

  const submit = async () => {
    setLoading(true);
    if (fileList.length > 0) {
      const res = await props.upload(fileList, "registrasi");
      if (res) {
        const res2 = await props.action(props.data?.id, {
          documents: res.data,
        });
        if (res2?.error === false || !res2?.error) {
          notification.success({
            message: "Success",
            description: "Upload Success",
          });
          props.getData();
          setOpen(false);
        } else {
          notification.error({
            message: "Upload Failed",
            description: res2?.message || "An error occurred during upload.",
          });
        }
        setFileList([]);
      } else {
        notification.error({
          message: "Upload Failed",
          description: "Unable to upload file. Please try again.",
        });
      }
    } else {
      message.error("Document File is Required!");
    }
    setLoading(false);
  };

  const input = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const fileTypeAllowed = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      ];
      const isAllowedFileType = fileTypeAllowed.includes(file.type);
      if (!isAllowedFileType) {
        message.error("You can only upload JPG/PNG/PDF/DOC/DOCX/XLS/XLSX/PPT/PPTX file!");
        return false;
      }
      
      let file_size = 16;
      const isLt2M = file.size / 1024 / 1024 < file_size;
      if (!isLt2M) {
        message.error("Image must smaller than " + file_size + "MB!");
        return isLt2M;
      }
      
      setFileList((data) => [...data, file]);
      return false;
    },
    fileList,
    multiple: true,
  };

  return (
    <>
      <Text onClick={() => setOpen(true)} style={{ display: "block" }}>
        Upload Dokumen
      </Text>
      <Modal
        title={"Upload Dokumen"}
        open={open}
        onOk={submit}
        confirmLoading={loading}
        onCancel={() => {
          setLoading(false);
          setOpen(false);
        }}
        okText={"Upload"}
      >
        <Row gutter={[0, 15]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Upload {...input}>
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default ModalUploadDokumen;
