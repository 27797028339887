const StatusApprovalList = (data) => {
  if (data === "APPROVED" || data === "APPROVE") {
    return "#3AD647";
  } else if (data === "REJECTED" || data === "REJECT") {
    return "#FA3737";
  } else if (data === "WAITING_APPROVAL") {
    return "#FFCD27";
  } else {
    return "#DED8D8";
  }
};

export default StatusApprovalList;