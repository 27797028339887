import { useState } from "react";
import AssetTable from "../../Components/Global/AssetTable";
import AdminLayout from "../../Layouts/Admin/AdminLayout";

function DaftarAsset(props) {
  const [params, setParams] = useState({
    status: "ACTIVE,DISPOSE,NON_ACTIVE,IDLE,TIDAK_DITEMUKAN",
    room_id: "",
    unit_id: localStorage.unit_id,
    asset_from_another_unit: true,
    cross_approval: true,
    // include_borrowed: true,
  });

  return (
    <AdminLayout>
      <AssetTable type={"daftar-asset"} params={params} setParams={setParams} />
    </AdminLayout>
  );
}

export default DaftarAsset;
