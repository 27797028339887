import {
  Card,
  Input,
  Table,
  Typography,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import AdminLayout from "../../Layouts/Admin/AdminLayout";
import styles from "../StockOpname/StockOpname.module.css";
import { Link } from "react-router-dom";
import AssetContext from "../../Services/Asset";
import moment from "moment";
// import { DownloadTableExcel } from "react-export-table-to-excel";
// import { useReactToPrint } from "react-to-print";

const { Text } = Typography;

function StockOpnamePending(props) {
  const [data, setData] = useState({});
  const [searchText, setSearchText] = useState("");
  const { getStockOpname } =
    useContext(AssetContext);
  const tableRef = useRef(null);

  // const handlePrint = useReactToPrint({
  //   content: () => tableRef.current,
  // });

  const columns = [
    {
      title: "Unit Kerja",
      key: "unit_kerja",
      width: 400,
      sorter: (a, b) => a.unit?.name.localeCompare(b.unit?.name),
      render: (data) => (
        <Link to={`/stock-opname/pending/detail/${data?.id}`}>
          {data?.unit?.name}
        </Link>
      ),
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(record?.unit?.name)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.total_pending)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase())
        );
      },
    },
    {
      title: "Total Pending",
      key: "total_pending",
      width: 200,
      sorter: (a, b) => a.total_pending - b.total_pending,
      render: (data) => `${data?.total_pending}`,
    },
    // {
    //   title: "Request Perpanjangan Stock Opname",
    //   key: "extend_date_request",
    //   width: 200,
    //   sorter: (a, b) => (a.extend_date_request || "").localeCompare((b.extend_date_request || "")),
    //   render: (data) => data?.extend_date_request && moment(data?.extend_date_request).format("LL"),
    // },
  ];

  const getData = async () => {
    const newData = await getStockOpname();
    if (newData) {
      setData(newData.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <AdminLayout>
      <Card className={styles.cardTable}>
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
          span={24}
          xl={18}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "10px" }}>Cari</div>
            <Input
              onChange={(text) => {
                setSearchText(text?.target?.value);
              }}
              placeholder="Cari..."
              style={{ maxWidth: "300px", marginRight: "30px" }}
            />
          </div>
          {/* <DownloadTableExcel
            filename={`Daftar Asset ${moment().unix()}`}
            currentTableRef={tableRef.current}
          >
            <Button type="primary" style={{ marginRight: "10px" }}>
              Export Excel
            </Button>
          </DownloadTableExcel>
          <Button onClick={handlePrint} type="primary" danger>
            Print PDF
          </Button> */}
        </div>
        <Table
          ref={tableRef}
          columns={columns}
          dataSource={data?.stock_opname_unit}
          rowKey="id"
          pagination
        />
      </Card>
    </AdminLayout>
  );
}

export default StockOpnamePending;
