import React from "react";
import { Col, Form, Row, Input, Select } from "antd";

const { TextArea } = Input;

function AddUpdate(props) {

  return (
    <Form
      form={props.form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      labelAlign={"left"}
    >
      <Row gutter={[0, 15]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="name"
            label="Nama"
            rules={[
              {
                required: true,
                message: "Please input name!",
              },
            ]}
          >
            <Input placeholder="Nama" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="latitude"
            label="Latitude"
          >
            <Input placeholder="Latitude" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="longitude"
            label="Longitude"
          >
            <Input placeholder="Longitude" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="address"
            label="Alamat"
          >
            <TextArea placeholder="Alamat" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="is_active_flag"
            label="Status"
            rules={[
              {
                required: true,
                message: "Please select status!",
              }
            ]}
          >
            <Select
              placeholder="Select status"
            >
              <Select.Option value={true}>Aktif</Select.Option>
              <Select.Option value={false}>Non Aktif</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default AddUpdate;
