import { Badge, Dropdown, Menu } from "antd";
import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { BellFilled } from "@ant-design/icons";
import MasterContext from "../../Services/Master";
import moment from "moment";

function NotificationHeader() {
  const [data, setData] = useState([]); // Data notifikasi yang akan dirender
  const [count, setCount] = useState(0); // Jumlah notifikasi yang belum dibaca
  const [open, setOpen] = useState(false); // State untuk membuka/menutup dropdown
  const dataRef = useRef([]); // Menggunakan useRef untuk menyimpan data polling tanpa menyebabkan re-render

  const { getAllMaster, addMaster } = useContext(MasterContext);

  // Fungsi untuk menampilkan menu dropdown
  const menu = (
    <div className="notification-header">
      {data &&
        data.map((item, index) => {
          return (
            <div
              key={index}
              className={`notification-list ${!item.isRead && "active"}`}
            >
              <div className="notification-title">{item.title}</div>
              <div className="notification-subtitle">{item.subTitle}</div>
              <div className="notification-date">
                {item.createdAt && moment(item.createdAt).format("LL HH:mm")}
              </div>
            </div>
          );
        })}
    </div>
  );

  // Fungsi untuk mengambil notifikasi dari API
  const getNotif = useCallback(async () => {
    try {
      const newData = await getAllMaster("notifications");
      if (newData) {
        const notifications = newData.data;

        // Cek apakah data baru berbeda dengan data lama yang ada di dataRef
        if (JSON.stringify(notifications) !== JSON.stringify(dataRef.current)) {
          dataRef.current = notifications;
          setData(notifications); // Update state data hanya jika berubah

          let newCount = 0;
          notifications.forEach((item) => {
            if (!item.isRead) {
              newCount++;
            }
          });
          setCount(newCount); // Update count jika data berubah
        }
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  }, [getAllMaster]);

  // Fungsi untuk menandai notifikasi sebagai sudah dibaca
  const readNotif = useCallback(async () => {
    try {
      const ids = dataRef.current.map((item) => item.id);
      await addMaster("notifications", { notification_ids: ids });
    } catch (error) {
      console.log(error);
    }
  }, [addMaster]);

  useEffect(() => {
    // Setup polling untuk mengambil notifikasi setiap 1 detik
    const interval = setInterval(() => {
      getNotif();
    }, 1000 * 20);

    // Bersihkan interval ketika komponen di-unmount
    return () => clearInterval(interval);
  }, [getNotif]);

  useEffect(() => {
    getNotif();
  }, []);

  useEffect(() => {
    if (open) {
      readNotif(); // Menandai notifikasi sudah dibaca saat dropdown dibuka
    }
  }, [open, readNotif]);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen); // Mengontrol buka/tutup dropdown berdasarkan interaksi pengguna
  };

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      onOpenChange={handleOpenChange}
      open={open} // Mengontrol buka/tutup dropdown
    >
      <a onClick={(e) => e.preventDefault()}>
        <Badge count={count}>
          <BellFilled style={{ color: "#C5C7CD", fontSize: "20px" }} />
        </Badge>
      </a>
    </Dropdown>
  );
}

// Memoize NotificationHeader untuk menghindari re-render berlebihan
export default React.memo(NotificationHeader);
