import { Col, Form, Row, Input, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import AssetContext from "../../../Services/Master";

function AddUpdate(props) {
  const { getAllMaster } = useContext(AssetContext);
  const [dataUnit, setDataUnit] = useState([]);
  const [dataPosition, setDataPosition] = useState([
    {
      value: 1,
      label: "Vice Precident",
    },
    {
      value: 2,
      label: "Head",
    },
    {
      value: 3,
      label: "Staff",
    },
    {
      value: 4,
      label: "Direktur",
    },
  ]);

  const getUnit = async () => {
    const newData = await getAllMaster("units");
    if (newData) {
      const newDataUnit =
        newData.data &&
        newData.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      setDataUnit(newDataUnit);
    }
  };

  useEffect(() => {
    getUnit();
  }, []);

  return (
    <Form
      form={props.form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      labelAlign={"left"}
    >
      <Row gutter={[0, 15]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="nip"
            label="NIP"
            rules={[
              {
                required: true,
                message: "Please input nip!",
              },
            ]}
          >
            <Input placeholder="NIP" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="name"
            label="Nama"
            rules={[
              {
                required: true,
                message: "Please input name!",
              },
            ]}
          >
            <Input placeholder="Nama" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="unit_id"
            label="Unit"
            rules={[
              {
                required: true,
                message: "Please select unit!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select unit"
              options={dataUnit}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="position_id"
            label="Position"
            rules={[
              {
                required: true,
                message: "Please select position!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select position"
              options={dataPosition}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please input email!",
              },
              {
                type: "email",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
        </Col>
        {/* <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: props.id ? false : true,
                message: "Please input password!",
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
        </Col> */}
        {props.id && (
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              name="is_active_flag"
              label="Status"
              rules={[
                {
                  required: props.id ? false : true,
                  message: "Please select status!",
                },
              ]}
            >
              <Select placeholder="Select status">
                <Select.Option value={true}>Aktif</Select.Option>
                <Select.Option value={false}>Non Aktif</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
}

export default AddUpdate;
