import React, { useEffect, useState } from "react";
import { Form, Modal, notification, Typography } from "antd";
import UpdateStatus from "../Form/UpdateStatus";

const { Text } = Typography;

function ModalUpdateStatus(props) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const submit = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      if (values) {
        const res = await props.action(props?.data?.id, values);
        if (res?.error === false || !res?.error) {
          notification.success({
            message: "Success",
            description: "Update Status Success",
          });
          setLoading(false);
          props.getData();
          setOpen(false);
        }
      }
    } catch (errInfo) {
      setLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ status: props?.data?.status });
  }, []);

  return (
    <>
      <Text onClick={() => setOpen(true)} style={{ display: "block" }}>
        Ubah Status
      </Text>
      <Modal
        title={"Ubah Status"}
        open={open}
        onOk={submit}
        confirmLoading={loading}
        onCancel={() => {
          setLoading(false);
          setOpen(false);
        }}
        okText={"Update"}
      >
        <UpdateStatus id={props.id} form={form} />
      </Modal>
    </>
  );
}

export default ModalUpdateStatus;
