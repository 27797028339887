import React from "react";
import { Space, Typography } from "antd";

const { Text } = Typography;

const locationList = (data) => {
  return (
    <Space size={0} direction="vertical">
      <Text>
        {data?.room?.name} - {data?.room?.floor?.name} -{" "}
        {data?.room?.floor?.building?.name}
      </Text>
      {/* <Text type="secondary">{data?.created_at}</Text> */}
    </Space>
  );
};

export default locationList;
